import {useState, useContext, useEffect} from "react"
import {useNavigate} from "react-router-dom"
import {Form, Field} from "react-final-form"
import {WrapperContainer, Radio, AlertDialog, Button} from "ui"
import {
  AccountType,
  ROUTES,
  SHELTER_PORTAL,
  SELECT_ACCOUNT_QUESTION,
  SELECT_ACCOUNT_ITEMS,
  BLOCKED_ACCOUNT_ITEMS,
  BLOCKED_ACCOUNT_ITEMS_ALERT,
} from "../../constants/select-account"
import {RoutePaths, FormButton} from "../../constants"
import {nacMachineContext} from "../../context"
import {NACEvents, NACStates} from "../../machine/nac-machine.types"

const SelectAccount = () => {
  const [open, setOpen] = useState(false)
  const [alertConfiguration, setAlertConfiguration] = useState({
    title: "",
    description: "",
    labelConfirm: undefined as string | undefined,
  })
  const navigate = useNavigate()
  const {send, state} = useContext(nacMachineContext) || {}

  const onSubmit = (values: {accountType: string}) => {
    if (BLOCKED_ACCOUNT_ITEMS.includes(parseInt(values.accountType))) {
      const {
        title = "",
        description = "",
        labelConfirm = undefined,
      } = {
        ...BLOCKED_ACCOUNT_ITEMS_ALERT[
          parseInt(values.accountType) as AccountType
        ],
      }

      setAlertConfiguration({
        title: title,
        description: description,
        labelConfirm: labelConfirm,
      })
      setOpen(true)
    } else {
      send(NACEvents.NEXT, {data: values.accountType})
      navigate(ROUTES[parseInt(values.accountType) as AccountType] as string)
    }
  }

  useEffect(() => {
    if (state?.value !== NACStates.selectAccountType) {
      send(NACEvents.INIT, {data: {route: location.pathname}})
    }
  }, [])

  const renderAlertBox = () => {
    return (
      <AlertDialog
        open={open}
        setOpen={setOpen}
        labelConfirm={alertConfiguration.labelConfirm}
        title={alertConfiguration.title}
        description={alertConfiguration.description}
        handleConfirm={() => {
          window.open(SHELTER_PORTAL, "_blank")
        }}
      />
    )
  }

  return (
    <div className="flex justify-center">
      <div className="w-full sm:w-[520px]">
        <WrapperContainer>
          <p className="mb-3 font-bold text-gray-700">
            {SELECT_ACCOUNT_QUESTION}
          </p>

          <Form
            onSubmit={onSubmit}
            initialValues={{
              accountType: state.context.accountType,
            }}
            render={({handleSubmit, invalid}) => (
              <form onSubmit={handleSubmit}>
                <div className=" flex flex-col gap-2">
                  {SELECT_ACCOUNT_ITEMS.map(accountType => {
                    return (
                      <Field
                        key={accountType.id}
                        name={accountType.name}
                        component={Radio}
                        type="radio"
                        value={`${accountType.value}`}
                        label={accountType.label}
                        helpertext={accountType.helperText}
                        validate={value => (value ? undefined : "Required")}
                      />
                    )
                  })}
                </div>
                <div className="mt-10 flex justify-end gap-8">
                  <Button
                    variant="text"
                    onClick={() => {
                      send(NACEvents.BACK)
                      navigate(RoutePaths.SELECT_LOCATION)
                    }}
                  >
                    Go Back
                  </Button>
                  <Button
                    disabled={invalid}
                    variant="primary"
                    mode="filled"
                    type="submit"
                  >
                    {FormButton.CONTINUE}
                  </Button>
                </div>
              </form>
            )}
          />

          {renderAlertBox()}
        </WrapperContainer>
      </div>
    </div>
  )
}

export default SelectAccount
