import { AccountType, RoutePaths } from "../constants";

export const getRoute = (accountType : AccountType) : RoutePaths | undefined =>{
    switch (Number(accountType)){
        case AccountType.VETERINARY:
        case AccountType.MOBILE_VETERINARY:
            return RoutePaths.ACCOUNT_VETERINARY
        case AccountType.PET_STORE:
            return RoutePaths.ACCOUNT_STORE_LOCATION
        case AccountType.NON_PROFIT:
            return RoutePaths.VALIDATE_SPECIALTY
        case AccountType.MILITARY:
            return RoutePaths.ACCOUNT_MILITARY
        case AccountType.LAW_ENFORCEMENT:
            return RoutePaths.ACCOUNT_LAW_ENFORCEMENT
        case AccountType.VET_TECH:
            return RoutePaths.ACCOUNT_VET_TECH   
        default: 
            return undefined                         
    }

}