import {useContext, useState, useEffect} from "react"
import {useNavigate} from "react-router-dom"
import {nacMachineContext} from "../../context"
import {NACEvents} from "../../machine/nac-machine.types"
import {Options, AccountType} from "../../constants"
import {OrderInformationType} from "../../types/review"
import {ORDER_TITLE, EDIT} from "../../constants/review"

const OrderInformation = () => {
  const navigate = useNavigate()
  const {state, send} = useContext(nacMachineContext) || {}
  const [orderInfo, setOrderInfo] = useState<OrderInformationType>(
    {} as OrderInformationType,
  )

  const isVetOrMobileVet = [
    AccountType.VETERINARY,
    AccountType.MOBILE_VETERINARY,
  ].includes(Number(state?.context?.accountType))

  const isMilitaryOrLawEnforcement = [
    AccountType.MILITARY,
    AccountType.LAW_ENFORCEMENT,
  ].includes(Number(state?.context?.accountType))

  useEffect(() => {
    if (Object.keys(state).length > 0) {
      const orderInfoState = state.context.financialInformation
      const orderDetails = {
        purchaseAgent: {
          title: "Purchasing Agent",
          testId: "purchasing-agent",
          data: [
            `${orderInfoState.agentFirstName} ${orderInfoState.agentLastName}`,
            orderInfoState.agentPhoneNumber,
            orderInfoState.agentEmail,
          ],
        },
        payableContact: {
          title: "Accounts Payable Contact",
          testId: "accounts-payable",
          data: [
            `${orderInfoState.billingContactFirstName} ${orderInfoState.billingContactLastName}`,
            orderInfoState.billingContactPhoneNumber,
            orderInfoState.billingContactEmail,
          ],
        },
        billingAddress: {
          title: "Billing Address",
          testId: "billing-address",
          data: [
            orderInfoState.streetAddress,
            orderInfoState.apartment,
            orderInfoState.city,
            orderInfoState.state,
            orderInfoState.zipcode,
          ],
        },
        invoiceDetails: {
          title: "Electronic Invoice Email",
          testId: "invoice-email",
          data: orderInfoState.invoiceConfirmation == Options.YES ? [orderInfoState.invoiceemail] : ["No"]
        },
        orderDetails: {
          title: "Electronic Order Confirmation Email",
          testId: "order-email",
          data: orderInfoState.orderConfirmation == Options.YES ? [orderInfoState.orderConfirmationEmail] : ["No"]
        }

      } as OrderInformationType

      if (Number(state.context.accountType == AccountType.MILITARY)) {
        delete orderDetails.purchaseAgent
      }

      if (isVetOrMobileVet) {
        orderDetails.primaryVet = {
          title: "Primary Veterinarian",
          testId: "primary-vet",
          data: [
            `${orderInfoState.vetfirstName} ${orderInfoState.vetlastName}`,
          ],
        }
        orderDetails.vetLicense = {
          title: "Primary Veterinarian’s License Number",
          testId: "vet-license",
          data: [orderInfoState.vetLicense],
        }
      }
      if (
        isMilitaryOrLawEnforcement &&
        Number(orderInfoState.isPONeeded) === 0
      ) {
        orderDetails.purchaseOrder = {
          title: "Blanket Purchase Order (BPO) Number",
          testId: "bpo-number",
          data: [orderInfoState.bpo],
        }
      } else if (
        isMilitaryOrLawEnforcement &&
        Number(orderInfoState.isPONeeded) === 1
      ) {
        orderDetails.purchaseOrder = {
          title: "Blanket Purchase Order (BPO) number required",
          testId: "bpo-required",
          data: ["No"],
        }
      }
      setOrderInfo(orderDetails)
    }
  }, [])

  const renderOrderInfo = (orderInfo: OrderInformationType) => {
    const keys = Object.keys(orderInfo) as Array<keyof OrderInformationType>
    return keys.map(key => {
      return (
        <div className="col-span-1" data-testid={orderInfo?.[key]?.testId}>
          <dt className="text-sm font-bold">{orderInfo?.[key]?.title}</dt>
          {orderInfo?.[key]?.data.map(data => {
            return (
              <>
                <dd className="text-sm font-light">{data}</dd>
              </>
            )
          })}
        </div>
      )
    })
  }

  return (
    <section className="mt-10 pt-3">
      <div className="flex justify-between pb-5">
        <h2 className="text-lg font-bold">{ORDER_TITLE}</h2>
        <button
          className="font-medium text-brand-primary"
          onClick={() => {
            send(NACEvents.EDIT, {data: "orderInfo"})
            navigate(`/financial-information`)
          }}
        >
          {EDIT}
        </button>
      </div>
      <div className="border-t border-gray-300">
        <div className="mt-6 grid grid-cols-2 gap-6 lg:grid-cols-3">
          {renderOrderInfo(orderInfo)}
        </div>
      </div>
    </section>
  )
}

export default OrderInformation
