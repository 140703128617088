import { RoutePaths } from "./index";
export interface SelectAccountType {
  label: string;
  helperText?: string;
  value: number;
  name: string;
  id: string;
}

export enum AccountType {
  VETERINARY,
  MOBILE_VETERINARY,
  ONLINE_VERTERINARY,
  PET_STORE,
  COUNTY_SHELTER,
  MILITARY,
  LAW_ENFORCEMENT,
  VET_TECH,
  HILLS_FOOD,
}

export type BlockedAccountItemsAlertType = {
  [key in AccountType]?: {
    title: string;
    description: string;
    labelConfirm?: string;
  };
};

export type ROUTES = {
  [key in AccountType]?: string;
};

export const SELECT_ACCOUNT_QUESTION =
  "For which type of account are you inquiring?";

export const SHELTER_PORTAL =
  "https://www.hillspet.com/food-shelter-love/my-shelter";

export const ROUTES: ROUTES = {
  [AccountType.VETERINARY]: RoutePaths.ACCOUNT_VETERINARY,
  [AccountType.MOBILE_VETERINARY]: RoutePaths.ACCOUNT_VETERINARY,
  [AccountType.PET_STORE]: RoutePaths.ACCOUNT_PET,
  [AccountType.COUNTY_SHELTER]: RoutePaths.ACCOUNT_NON_PROFIT,
  [AccountType.MILITARY]: RoutePaths.ACCOUNT_MILITARY,
  [AccountType.LAW_ENFORCEMENT]: RoutePaths.ACCOUNT_LAW_ENFORCEMENT,
  [AccountType.VET_TECH]: RoutePaths.ACCOUNT_VET_TECH,
};

export const BLOCKED_ACCOUNT_ITEMS: AccountType[] = [
  AccountType.ONLINE_VERTERINARY,
  AccountType.HILLS_FOOD,
];

export const BLOCKED_ACCOUNT_ITEMS_ALERT: BlockedAccountItemsAlertType = {
  [AccountType.ONLINE_VERTERINARY]: {
    title: "We’re sorry!",
    description:
      "At this time, we aren’t accepting Online Vet Store or Online Pet Store customers. Please contact us at a later date, as we may in the future.",
  },
  [AccountType.HILLS_FOOD]: {
    title: "We’re sorry!",
    description:
      "This site isn’t currently able to create or modify Hill’s Food, Shelter & Love accounts. Visit our shelter portal for further assistance.",
    labelConfirm: "Continue to Portal",
  },
};

export const SELECT_ACCOUNT_ITEMS: Array<SelectAccountType> = [
  {
    label: "Veterinary Hospital/Clinic",
    helperText: "(Occupying a Non-Residential Building with Signage)",
    value: AccountType.VETERINARY,
    name: "accountType",
    id: "vet",
  },
  {
    label: "Mobile Vet",
    value: AccountType.MOBILE_VETERINARY,
    name: "accountType",
    id: "mvet",
  },
  {
    label: "Online Vet or Pet Store",
    value: AccountType.ONLINE_VERTERINARY,
    name: "accountType",
    id: "onlinevet",
  },
  {
    label: "Pet Store",
    helperText: "(Occupying a Non-Residential Building with Signage)",
    value: AccountType.PET_STORE,
    name: "accountType",
    id: "petstore",
  },
  {
    label: "City/County Shelter/Humane Society or Non-Profit with a 501(c)(3)",
    helperText: "(Occupying a Non-Residential Building with Signage)",
    value: AccountType.COUNTY_SHELTER,
    name: "accountType",
    id: "nonprofit",
  },
  {
    label: "Military",
    value: AccountType.MILITARY,
    name: "accountType",
    id: "mililtary",
  },
  {
    label: "Law Enforcement",
    value: AccountType.LAW_ENFORCEMENT,
    name: "accountType",
    id: "law",
  },
  {
    label:
      "Veterinary Technology/Nursing School or College of Veterinary Medicine",
    value: AccountType.VET_TECH,
    name: "accountType",
    id: "vettech",
  },
  {
    label: "Hill’s Food, Shelter & Love Program",
    helperText: "(Exclusive Hill’s Pet Food Shelter Partnership)",
    value: AccountType.HILLS_FOOD,
    name: "accountType",
    id: "hillsfood",
  },
];
