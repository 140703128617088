export interface SelectTranscationType {
  label: string;
  value: number;
  name: string;
}

export const SELECT_TRANSACTION_HEADER = "What are you looking to do?";

export enum TransactionType {
  CreateNewCustomerAccount,
  AddNewShipToLocation,
  UpdateCustomerAccountProfile,
  TransferOwnership,
}

export const TRANSACTION_ITEMS: Array<SelectTranscationType> = [
  {
    label: "Create New Customer Account",
    value: TransactionType.CreateNewCustomerAccount,
    name: "selectedTransaction",
  },
  {
    label: "Add New Ship-To-Location",
    value: TransactionType.AddNewShipToLocation,
    name: "selectedTransaction",
  },
  {
    label: "Update Customer Account Profile",
    value: TransactionType.UpdateCustomerAccountProfile,
    name: "selectedTransaction",
  },
  {
    label: "Transfer Ownership of Customer Account",
    value: TransactionType.TransferOwnership,
    name: "selectedTransaction",
  },
];
