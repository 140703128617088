import {required} from "ui"
import {AccountType, Options, QuestionsInterface} from "../constants"
import { formatPhone } from "./../components/utils/format";
import {
  BusinessInfoQuestions,
  CORPORATE_GROUP_DISABLED,
  LegalBusinessForm,
  PhysicalLocation,
  UPDATE_FEDERAL_IDENTIFICATION_NUM,
  UPDATE_LEGAL_BUSINESS_FORM,
} from "../constants/buisness-information"
import {TransactionType} from "../constants/select-transaction"
import {NACMachineContext} from "../machine"
import {BusinessInformantion, TBusinessInformation} from "../types"
import {mustBeEmail, mustBePhone, maxLength} from "./validators"

export const getUpdatedBusinessInfoQuestions = (
  questions: QuestionsInterface<LegalBusinessForm | Options>[],
  selectedTransaction: TransactionType,
  accountType: AccountType,
  isNonProfit: Options,
) => {
  const displayQuestions =
    Number(selectedTransaction) === TransactionType.UpdateCustomerAccountProfile
      ? questions
          .map(question => {
            return question.id === BusinessInfoQuestions.CorporateGroupName
              ? CORPORATE_GROUP_DISABLED
              : question.id === BusinessInfoQuestions.LegalBusinessForm
              ? UPDATE_LEGAL_BUSINESS_FORM
              : question.id ===
                BusinessInfoQuestions.BusinessFedTaxIdentificationNumber
              ? UPDATE_FEDERAL_IDENTIFICATION_NUM
              : question
          })
          .filter(question => {
            if (question.id !== undefined)
              return ![
                BusinessInfoQuestions.ContactInfo,
                BusinessInfoQuestions.PhysicalLocation,
                BusinessInfoQuestions.BusinessEstablishmentDate,
              ].includes(question.id)
          })
      : questions
  switch (Number(accountType)) {
    case AccountType.NON_PROFIT: {
      const ignoreQuestionList = [BusinessInfoQuestions.CorporateGroupName]
      if (Number(isNonProfit) === Options.YES) {
        ignoreQuestionList.push(
          BusinessInfoQuestions.BusinessFedTaxIdentificationNumber,
        )
      }
      const updatedQuestions = displayQuestions.filter(
        question => !(question.id && ignoreQuestionList.includes(question.id)),
      )
      return updatedQuestions
    }

    case AccountType.PET_STORE: {
      const physicalLocationIndex = displayQuestions.findIndex(
        question => question.id === BusinessInfoQuestions.PhysicalLocation,
      )
      if (physicalLocationIndex !== -1) {
        displayQuestions[physicalLocationIndex].OPTIONS = displayQuestions[
          physicalLocationIndex
        ].OPTIONS.filter(option => option.id !== "mobileVet")
      }
      return displayQuestions
    }
    case AccountType.MILITARY:
    case AccountType.LAW_ENFORCEMENT:
      return displayQuestions.filter(question => {
        if (question.id !== undefined) {
          return [
            BusinessInfoQuestions.BuisnessContact,
            BusinessInfoQuestions.BusinessName,
            BusinessInfoQuestions.ContactInfo,
          ].includes(question.id)
        }
      })

    case AccountType.VET_TECH: {
      const businessContactInfo =
        selectedTransaction == TransactionType.UpdateCustomerAccountProfile
          ? displayQuestions[1]
          : displayQuestions[0]
      const universityQuestions: QuestionsInterface<
        LegalBusinessForm | Options
      >[] = [
        {
          QUESTION_TEXT: "What is your university/school name?",
          OPTIONS: [
            {
              componentType: "Input",
              label: "University/School Name",
              name: "universityDetails.universityName",
              id: "universityDetails.universityName",
              type: "text",
              dataTestId: "universityname",
              helperText: "Maximum 40 charecters",
            },
          ],
          VALIDATION: [required, maxLength(40)],
        },
        {
          QUESTION_TEXT:
            "Who is the primary contact for your university/school?",
          OPTIONS: [
            {
              componentType: "Input",
              label: "Primary Contact First Name",
              name: "universityDetails.firstName",
              id: "universityDetails.firstName",
              type: "text",
              VALIDATION: [required, maxLength(50)],
            },
            {
              componentType: "Input",
              label: "Primary Contact Last Name",
              name: "universityDetails.lastName",
              id: "universityDetails.lastName",
              type: "text",
              VALIDATION: [required, maxLength(50)],
            },
            {
              componentType: "Input",
              label: "Primary Contact Phone Number",
              name: "universityDetails.phoneNumber",
              id: "universityDetails.phoneNumber",
              type: "text",
              VALIDATION: [required, mustBePhone],
              format: formatPhone,
              dataTestId: "phoneNumber",
            },
            {
              componentType: "Input",
              label: "Primary Contact Email Address",
              name: "universityDetails.email",
              id: "universityDetails.email",
              type: "text",
              VALIDATION: [required, mustBeEmail],
              dataTestId: "emailAddress",
            },
          ],
        },
        {
          QUESTION_TEXT: "What is the name of the staff veterinarian?",
          VALIDATION: [required, maxLength(50)],
          OPTIONS: [
            {
              label: "Veterinarian First Name",
              name: "universityDetails.vetDetails.firstName",
              id: "universityDetails.vetDetails.firstName",
              dataTestId: "vet-first-name",
              type: "text",
              componentType: "Input",
            },
            {
              label: "Veterinarian Last Name and Degree",
              name: "universityDetails.vetDetails.lastName",
              id: "universityDetails.vetDetails.lastName",
              dataTestId: "vet-last-name",
              type: "text",
              componentType: "Input",
            },
          ],
        },
      ]
      return [businessContactInfo, ...universityQuestions]
    }
    default:
      return displayQuestions
  }
}

export const TPhysicalLocation = {
  [PhysicalLocation.InsideMallorAnotherBusiness]: "INSIDE_MALL",
  [PhysicalLocation.MobileVet]: "MOBILE_VET",
  [PhysicalLocation.PersonalResidence]: "PERSONAL_RESIDENCE",
  [PhysicalLocation.SingleBusinessBuilding]: "SINGLE_BUSINESS",
}

export const TLegalBusinessForm = {
  [LegalBusinessForm.Incorporated]: "INCORPORATED",
  [LegalBusinessForm.LLC]: "LLC",
  [LegalBusinessForm.LLP]: "LLP",
  [LegalBusinessForm.Other]: "OTHER",
  [LegalBusinessForm.Partnership]: "PARTNERSHIP",
  [LegalBusinessForm.SoleProprietorship]: "SOLE_PROPRIETORSHIP",
}

export function transformBusinessInfoPayload(
  accountType: AccountType,
  selectedTransaction: TransactionType,
  businessInformation: BusinessInformantion | undefined,
): TBusinessInformation | undefined {
  if (accountType == AccountType.VET_TECH) {
    return
  }

  const identificationInformation =
    Number(businessInformation?.hasEIN) === Options.YES
      ? {
          employerIdentificationNo: businessInformation?.ein,
        }
      : {
          socialSecurityNo: businessInformation?.ssn,
        }
  const legalFormDetails =
    Number(businessInformation?.legalBusinessform) === LegalBusinessForm.Other
      ? {
          dateOfFormation: businessInformation?.formationDate,
          legalFormOfBusinessOtherType: businessInformation?.other,
        }
      : {
          dateOfFormation: businessInformation?.formationDate,
          stateOfFormation: businessInformation?.state,
        }

  const legalBusinessDetails =
    businessInformation?.legalBusinessform ==
    LegalBusinessForm.SoleProprietorship
      ? {
          legalFormOfBusiness:
            TLegalBusinessForm[businessInformation?.legalBusinessform],
        }
      : {
          legalFormOfBusiness:
            businessInformation?.legalBusinessform &&
            TLegalBusinessForm[businessInformation?.legalBusinessform],
          legalFormDetails,
        }

  const businessInfoMilitaryLaw = {
    businessName: businessInformation?.businessName,
    businessPhoneNo: businessInformation?.businessPhoneNum,
    businessEmail: businessInformation?.businessEmail,
    displayRetailInfoOnWebsite: Boolean(businessInformation?.terms),
  }

  const businessInfoUpdateFlow = {
    ...businessInfoMilitaryLaw,
    ...legalBusinessDetails,
    identificationInformation,
  }

  const businessInfoNonProfit = {
    ...businessInfoMilitaryLaw,
    corporateGroupName: businessInformation?.corporateGroupName,
    businessEstablishedDate: businessInformation?.businessDate,
    physicalBusinessLocation:
      businessInformation?.physicalLocation &&
      TPhysicalLocation[businessInformation?.physicalLocation],
    ...legalBusinessDetails,
  }

  const businessInfoVetPet = {
    ...businessInfoNonProfit,
    identificationInformation,
  }

  const businessInfoPayload: TBusinessInformation =
    selectedTransaction == TransactionType.UpdateCustomerAccountProfile
      ? businessInfoUpdateFlow
      : accountType == AccountType.NON_PROFIT
      ? businessInfoNonProfit
      : [AccountType.MILITARY, AccountType.LAW_ENFORCEMENT].includes(
          accountType,
        )
      ? businessInfoMilitaryLaw
      : businessInfoVetPet

  return businessInfoPayload
}

export function transformContactInformation(context: NACMachineContext) {
  const {
    selectedTransaction = TransactionType.CreateNewCustomerAccount,
    shipToQualifier,
    businessInformation,
  } = context
  const isAddNewShipToOrUpdateFlow = [
    TransactionType.UpdateCustomerAccountProfile,
    TransactionType.AddNewShipToLocation,
  ].includes(Number(selectedTransaction))
  const contactInformation = {
    firstName: isAddNewShipToOrUpdateFlow
      ? shipToQualifier?.firstName
      : businessInformation?.firstName,
    lastName: isAddNewShipToOrUpdateFlow
      ? shipToQualifier?.lastName
      : businessInformation?.lastName,
    phoneNo: isAddNewShipToOrUpdateFlow
      ? shipToQualifier?.phone
      : businessInformation?.phoneNumber,
    emailAddress: isAddNewShipToOrUpdateFlow
      ? shipToQualifier?.email
      : businessInformation?.email,
  }
  return contactInformation
}

export function transformUniversityDetails(context: NACMachineContext) {
  const {businessInformation, validateUniversity} = context

  const universityPrimaryContact = {
    firstName: businessInformation?.universityDetails?.firstName,
    lastName: businessInformation?.universityDetails?.lastName,
    emailAddress: businessInformation?.universityDetails?.email,
    phoneNo: businessInformation?.universityDetails?.phoneNumber,
  }

  const universitySpecificDetails = {
    universityName: businessInformation?.universityDetails?.universityName,
    universityPrimaryContact,
    universityType: validateUniversity?.program,
  }
  return universitySpecificDetails
}
