import {Radio, Input, FileUpload, SelectDropDown, TextArea, Checkbox} from "ui"
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const components: {[key: string]: any} = {
  Radio: Radio,
  Input: Input,
  FileUpload: FileUpload,
  SelectDropDown: SelectDropDown,
  TextArea: TextArea,
  Checkbox: Checkbox,
}
