import React from "react"
import { Button, IconWarning } from "ui"
import { ERROR } from "../../constants"
interface ErrorPageProps {
  handleTryAgain: () => void
}
const ErrorPage = ({ handleTryAgain }: ErrorPageProps) => {
  return (
    <div className="fixed inset-0 z-20 flex flex-col items-center justify-center bg-white">
      <div className="h-14 w-16">
        <IconWarning />
      </div>

      <div className="sm:w-[520px]">
        <h1 className="my-8 text-center text-[2.75rem] font-extrabold uppercase leading-10">
          {ERROR.header}
        </h1>
        <p className="mb-8 text-center text-lg font-normal text-brand-accent-gray-light">
          {ERROR.message}
        </p>
        <div className="flex justify-center">
          <Button variant="primary" mode="filled" onClick={handleTryAgain}>
            {ERROR.cta}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ErrorPage
