import {required} from "ui"
import {Options, QuestionsInterface} from ".."
import { mustBeShipTo } from "../../utils/validators"

export const SHIP_TO_ACCOUNT_QUESTION: QuestionsInterface<Options>[] = [
  {
    QUESTION_TEXT:
      "What is the ship-to account number you would like to update?",

    OPTIONS: [
      {
        componentType: "Input",
        label: "Ship-To Account Number",
        name: "accountNum",
        id: "accountNum",
        dataTestId: "accountNum",
        type: "text",
        VALIDATION: [required, mustBeShipTo],
      },
    ],
  },
]

export const SHIP_TO_TRANSFER_QUESTION: QuestionsInterface<Options>[] = [
  {
    QUESTION_TEXT:
      "What is the ship-to account number you would like to transfer?",

    OPTIONS: [
      {
        componentType: "Input",
        label: "Ship-To Account Number",
        name: "accountNum",
        id: "accountNum",
        dataTestId: "accountNum",
        type: "text",
        VALIDATION: [mustBeShipTo],
      },
    ],
  },
]
