import React, {useContext, useEffect} from "react"
import {Form} from "react-final-form"
import {useNavigate, useLocation} from "react-router-dom"
import {Button, WrapperContainer} from "ui"
import {FormButton, RoutePaths} from "../../../constants"
import {
  HOW_MANY_SHIP_TO_QUESTION,
  ShipToLocation,
} from "../../../constants/shipto/how-many-ship-to"
import {nacMachineContext} from "../../../context"
import {NACEvents, NACStates} from "../../../machine"
import Question from "../../utils/Question"

const HowManyShipTo = () => {
  const {state, send} = useContext(nacMachineContext) || {}
  const navigate = useNavigate()
  const location = useLocation()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (values: any) => {
    send(NACEvents.NEXT, {data: values.shipto})

    const nextRoute =
      values.shipto == ShipToLocation.SPECIFIC
        ? RoutePaths.SHIP_TO_ACCOUNT
        : RoutePaths.DATE_SIGN
    navigate(nextRoute)
  }

  useEffect(() => {
    if (state?.value !== NACStates.howManyShipTo) {
      send(NACEvents.INIT, {data: {route: location.pathname}})
    }
  }, [])

  return (
    <WrapperContainer classes="my-4 w-full sm:w-[520px]">
      <Form
        onSubmit={onSubmit}
        initialValues={{
          shipto: state?.context?.howManyShipTo,
        }}
        render={({handleSubmit, invalid, values, touched}) => {
          return (
            <form onSubmit={handleSubmit}>
              <Question
                questions={HOW_MANY_SHIP_TO_QUESTION}
                values={values}
                touched={touched}
              />
              <div className="mt-14 flex justify-end gap-8">
                <Button
                  variant="text"
                  onClick={() => {
                    send(NACEvents.BACK)
                    navigate(RoutePaths.PAYMENT_INFORMATION)
                  }}
                >
                  {FormButton.BACK}
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  mode="filled"
                  disabled={invalid}
                >
                  {FormButton.SAVE_AND_CONTINUE}
                </Button>
              </div>
            </form>
          )
        }}
      />
    </WrapperContainer>
  )
}

export default HowManyShipTo
