import {Field} from "react-final-form"
import {CheckMark, RowItem, EditIcon, Radio, TrashIcon} from "ui"
import {
  SMS,
  ACCESS_STATUS,
  Options,
  TableHeaders,
} from "../../constants/contacts"
import {AccountType} from "../../constants/select-account"
import {ContactDetails} from "../../types"

export function GetAccessStatus(dataItem: ContactDetails): JSX.Element {
  if (dataItem?.sendFeedProgramDetails && dataItem?.sendClinicDetails) {
    return (
      <>
        <p>{ACCESS_STATUS.HRO}</p>
        <p>{ACCESS_STATUS.VIP}</p>
      </>
    )
  } else if (dataItem?.sendFeedProgramDetails) {
    return <p>{ACCESS_STATUS.VIP}</p>
  } else if (dataItem?.sendClinicDetails) {
    return <p>{ACCESS_STATUS.HRO}</p>
  } else {
    return <></>
  }
}

export function ContactInformation(dataItem: ContactDetails): JSX.Element {
  return (
    <div className="md:pl-4" data-testid="contact-info">
      <p className="font-bold text-brand-primary">
        {dataItem.firstName} {dataItem.lastName}
      </p>
      <p>{dataItem.title}</p>
      <p>{dataItem.email}</p>
      <p>{dataItem.phone}</p>
      {dataItem?.notifications ? (
        <p className="mt-4">
          <span className="font-bold text-black">{SMS.question} </span>
          {dataItem.notifications ? Options.YES : Options.NO}
        </p>
      ) : (
        <></>
      )}

      {dataItem.notifications ? (
        <p>
          <span className="font-bold text-black">{SMS.mobile} </span>
          {dataItem.sms}
        </p>
      ) : (
        <></>
      )}
    </div>
  )
}

export function DeliveryStatus(
  dataItem: ContactDetails,
  primaryContactId: number,
): JSX.Element {
  return dataItem.id == primaryContactId || dataItem.viewDeliveryStatus ? (
    <div className="h-5 w-5">
      <CheckMark />
    </div>
  ) : (
    <></>
  )
}

export function NotifyRouteDelayIcon(dataItem: ContactDetails): JSX.Element {
  return dataItem.notifyRouteDelay ? (
    <div className="h-5 w-5">
      <CheckMark />
    </div>
  ) : (
    <></>
  )
}

export function MarketingCommunications(dataItem: ContactDetails): JSX.Element {
  return dataItem.marketingCommunications ? (
    <div className="h-5 w-5">
      <CheckMark />
    </div>
  ) : (
    <></>
  )
}

export function PrimaryContactButton(dataItem: ContactDetails): JSX.Element {
  return (
    <Field
      name={"primaryContact"}
      component={Radio}
      type="radio"
      label={""}
      value={`${dataItem.id}`}
      validate={value => (value ? undefined : "Required")}
    />
  )
}

export function ModifyContacts(
  dataItem: ContactDetails,
  primaryContactId: string,
  setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedContactId: React.Dispatch<React.SetStateAction<string>>,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
): JSX.Element {
  return (
    <div className="flex items-center">
      <button
        type="button"
        onClick={() => {
          setSelectedContactId(dataItem.id.toString())
          setOpen(true)
        }}
        className="inline-flex items-center border  border-transparent p-3  text-white hover:rounded-full hover:bg-white hover:shadow-[2px_2px_10px_rgba(0,0,0,0.3)] focus:rounded-full focus:outline-dashed focus:outline-2
         focus:outline-brand-primary active:bg-brand-primary/[0.12] active:shadow-none active:outline-none"
      >
        <div className="h-5 w-5">
          <EditIcon />
        </div>
      </button>
      <button
        onClick={() => {
          setSelectedContactId(dataItem.id.toString())
          setOpenDeleteModal(true)
        }}
        type="button"
        disabled={Number(primaryContactId) == dataItem.id}
        className={`inline-flex items-center border  border-transparent p-3  text-white hover:rounded-full hover:bg-white hover:shadow-[2px_2px_10px_rgba(0,0,0,0.3)] focus:rounded-full focus:outline-dashed focus:outline-2
         focus:outline-brand-primary  active:bg-brand-primary/[0.12] active:shadow-none active:outline-none ${
           Number(primaryContactId) == dataItem.id
             ? "cursor-not-allowed opacity-50"
             : "cursor-pointer"
         }`}
      >
        <div className="h-5 w-5">
          <TrashIcon />
        </div>
      </button>
    </div>
  )
}

export function getTableRows(
  dataItems: ContactDetails[],
  accountType: AccountType,
  primaryContactId: string,
  setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedContactId: React.Dispatch<React.SetStateAction<string>>,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
): RowItem[][] {
  const tableRow: RowItem[][] = []
  for (const dataItem of dataItems) {
    const columns = [
      ContactInformation(dataItem),
      DeliveryStatus(dataItem, Number(primaryContactId)),
      NotifyRouteDelayIcon(dataItem),
      MarketingCommunications(dataItem),
      PrimaryContactButton(dataItem),
      ModifyContacts(
        dataItem,
        primaryContactId,
        setOpenDeleteModal,
        setSelectedContactId,
        setOpen,
      ),
    ]
    if (accountType !== AccountType.LAW_ENFORCEMENT) {
      columns.splice(3, 0, GetAccessStatus(dataItem))
    }
    tableRow.push([...columns])
  }
  return tableRow
}

export function getTableHeaders(
  accountType: AccountType,
  headers: string[],
): string[] {
  return accountType === AccountType.LAW_ENFORCEMENT
    ? headers.filter(header => header !== TableHeaders.services)
    : headers
}
