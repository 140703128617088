/* eslint-disable @typescript-eslint/no-explicit-any */
const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/
const PHONE_REGEX = /^\d{10}$|^\(\d{3}\) \d{3}-\d{4}$/;
const SOLDTO_SHIPTO_REGEX = /^\d{6,10}$/
const NAME_REGEX = /^[A-Za-z0-9@#+&][a-zA-Z0-9#&+@ ]*$/
const ROUTING_NUMBER_REGEX = /^\d{9}$/
const ACCOUNT_NUMBER_REGEX = /^\d{1,17}$/

export const required = (value: any) =>
  value && value.trim() ? undefined : "Required"
  
export const listNotEmpty = (value: any) =>
  value && value.length > 0 ? undefined : "Required"

export const mustBeNumber = (value: number) =>
  isNaN(value) ? "Must be a number" : undefined

export const minValue = (min: number) => (value: number) =>
  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`

export const minLength = (min: number) => (value: string) =>
  value.length < min ? `Min ${min} characters` : undefined

export const maxLength = (max: number) => (value: string) =>
  value.trim().length > max ? `Maximum ${max} characters` : undefined

export const validRoutingNumber = (value: string) =>
  value.match(ROUTING_NUMBER_REGEX)
    ? undefined
    : `Must be 9 digits and numeric only.`

export const validAccountNumber = (value: string) =>
  value.match(ACCOUNT_NUMBER_REGEX)
    ? undefined
    : `Maximum 17 digits and numeric only.`

export const mustBeEmail = (value: string) =>
  value.match(EMAIL_REGEX) ? undefined : `Enter valid email address`

export const mustBePhone = (value: any) =>
  !value || value.match(PHONE_REGEX) ? undefined : `Must be 10 numeric characters`

export const mustBeSoldTo = (value: any) =>
  !value || value.match(SOLDTO_SHIPTO_REGEX) ? undefined : `Enter valid sold-to account number`

export const mustBeShipTo = (value: any) =>
  !value || value.match(SOLDTO_SHIPTO_REGEX) ? undefined : `Enter valid ship-to account number`

export const mustBeValidName = (value: string) =>
  !value || value.match(NAME_REGEX)
    ? undefined
    : "Cannot contain special characters except (#, &, +, @)"

export const mustBeZipCode = (value: any) => {
  value = value?.split("-").join("")
  if (!value || value.length === 5 || value.length === 9) {
    return undefined
  }
  return "Enter valid zip code"
}

export const mustBeSSN = (value: any) => {
  value = value.split("-").join("")
  if (value.length === 9) {
    return undefined
  }
  return "Enter valid SSN"
}

export const mustBeEIN = (value: any) => {
  value = value.split("-").join("")
  if (value.length === 9) {
    return undefined
  }
  return "Enter valid employer identification number"
}

export const composeValidators =
  (...validators: any[]) =>
  (value: any) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined,
    )