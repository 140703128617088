import { QuestionsInterface } from ".."
import { required } from "../../utils/validators"

export enum ShipToLocation {
  ALL,
  SPECIFIC,
}

export const HOW_MANY_SHIP_TO_QUESTION: QuestionsInterface<ShipToLocation>[] = [
  {
    QUESTION_TEXT:
      "How many ship-to locations are included in this transfer of ownership?",
    VALIDATION: [required],
    OPTIONS: [
      {
        componentType: "Radio",
        label: "All Locations",
        value: ShipToLocation.ALL,
        name: "shipto",
        id: "all-location",
        dataTestId: "all-location",
        type: "radio",
      },
      {
        componentType: "Radio",
        label: "Only Some Locations",
        helperText: "(Must Specify Included Locations)",
        value: ShipToLocation.SPECIFIC,
        name: "shipto",
        id: "specific-location",
        dataTestId: "specific-location",
        type: "radio",
      },
    ],
  },
]
