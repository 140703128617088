import {AccountType, Options} from "."
import {TransactionType} from "./select-transaction"

export const TACCOUNTTYPE = {
  [AccountType.VETERINARY]: "VET_HOSP",
  [AccountType.MOBILE_VETERINARY]: "MOB_VET",
  [AccountType.ONLINE_VERTERINARY]: "ONLINE_VETORPET",
  [AccountType.PET_STORE]: "PET_STORE",
  [AccountType.NON_PROFIT]: "CITY_COUNTY_NONPROF",
  [AccountType.MILITARY]: "MILITARY",
  [AccountType.LAW_ENFORCEMENT]: "LAW_ENF",
  [AccountType.VET_TECH]: "VET_TECH_NURSING",
  [AccountType.HILLS_FOOD]: "HILLS_PROGRAM",
}
export const TOPTION = {
  [Options.YES]: true,
  [Options.NO]: false,
}

export const TTRANSACTION = {
  [TransactionType.CreateNewCustomerAccount]: "CREATE_ACCOUNT",
  [TransactionType.AddNewShipToLocation]: "ADD_SHIPTO",
  [TransactionType.UpdateCustomerAccountProfile]: "UPDATE_PROFILE",
  [TransactionType.TransferOwnership]: "TRANSFER_OWNERSHIP",
}
