import {QuestionsInterface, SubQuestions, Options} from "../index"
import {required, maxLength} from "../../utils/validators"
import {VeterinarianInfo} from "../../types"

export interface ValidateMilitary extends VeterinarianInfo {
  staff: Options
  location: Options
}

export const MILITARY_ALERT = {
  title: "We’re sorry!",
  description:
    "At this time, you must be located on a military base to be considered.",
}

export const SUB_QUESTIONS: SubQuestions<Options>[] = [
  {
    EXPECTED_PARENT_VALUE: [Options.YES],
    QUESTIONS: [
      {
        QUESTION_TEXT: "What is the name of the veterinarian?",
        VALIDATION: [required, maxLength(50)],
        OPTIONS: [
          {
            label: "Veterinarian First Name",
            name: "firstName",
            id: "firstName",
            dataTestId: "first_name",
            type: "text",
            componentType: "Input",
          },
          {
            label: "Veterinarian Last Name and Degree",
            name: "lastName",
            id: "lastName",
            dataTestId: "last_name",
            type: "text",
            componentType: "Input",
          },
        ],
      },
      {
        QUESTION_TEXT: "What is the veterinarian’s license number?",
        VALIDATION: [required],
        OPTIONS: [
          {
            label: "Veterinarian License Number",
            name: "vetLicense",
            id: "vetLicense",
            dataTestId: "vet_license",
            type: "text",
            surfly_private: "true",
            componentType: "Input",
          },
        ],
      },
    ],
  },
]

export const MILITARY_QUESTIONS: QuestionsInterface<Options>[] = [
  {
    QUESTION_TEXT:
      "Do you have a veterinarian on staff or a sponsor veterinarian?",
    QUESTION_TEXT_HELPER:
      "(Required to order Hill’s Prescription Diet pet foods)",
    VALIDATION: [required],

    OPTIONS: [
      {
        label: "Yes",
        value: Options.YES,
        name: "staff",
        id: "staff-yes",
        dataTestId: "staff-yes",
        type: "radio",
        componentType: "Radio",
      },
      {
        label: "No",
        value: Options.NO,
        name: "staff",
        id: "staff-no",
        dataTestId: "staff-no",
        type: "radio",
        componentType: "Radio",
        helperText: "(You will not be able to order Hill’s Prescription Diet)",
      },
    ],
    SUB_QUESTIONS: [...SUB_QUESTIONS],
  },
  {
    QUESTION_TEXT: "Are you currently located on a military base?",
    VALIDATION: [required],
    OPTIONS: [
      {
        label: "Yes",
        value: Options.YES,
        name: "location",
        id: "location-yes",
        dataTestId: "location-yes",
        type: "radio",
        componentType: "Radio",
      },
      {
        label: "No",
        value: Options.NO,
        name: "location",
        id: "location-no",
        dataTestId: "location-no",
        type: "radio",
        componentType: "Radio",
      },
    ],
  },
]
