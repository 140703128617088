import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import {HashRouter} from "react-router-dom"
import {NACMachineProvider} from "./context"
import ScrollToTop from "./utils/ScrollToTop"
import {AuthProvider} from "./hooks/use-auth"

function renderApp() {
  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
  )

  root.render(
    <React.StrictMode>
      <HashRouter>
        <AuthProvider>
          <NACMachineProvider>
            <ScrollToTop />
            <App />
          </NACMachineProvider>
        </AuthProvider>
      </HashRouter>
    </React.StrictMode>,
  )
}

renderApp()
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
