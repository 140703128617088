/* eslint-disable @typescript-eslint/no-explicit-any */
export const formatZip = (value: any) => {
  if (!value) return value
  const onlyNums = value.replace(/[^\d]/g, "")
  if (onlyNums.length <= 5) return onlyNums
  return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5)}`
}

export const formatSSN = (value: any) => {
  if (!value) return value
  const onlyNums = value.replace(/[^\d]/g, "")
  if (onlyNums.length <= 3) return onlyNums
  if (onlyNums.length <= 5)
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 5)}-${onlyNums.slice(
    5,
    9,
  )}`
}

export const formatEIN = (value: any) => {
  if (!value) return value
  const onlyNums = value.replace(/[^\d]/g, "")
  if (onlyNums.length <= 2) return onlyNums
  return `${onlyNums.slice(0, 2)}-${onlyNums.slice(2, 9)}`
}

export const formatPhone = (value: any) => {
  if (!value) return value
  const onlyNums = value.replace(/[^\d]/g, "") 
  if (onlyNums.length > 6) {
    value = `(${onlyNums.substring(0,3)}) ${onlyNums.substring(3,6)}-${onlyNums.substring(6)}`
  } else if (onlyNums.length > 3) {
    value = `(${onlyNums.substring(0,3)}) ${onlyNums.substring(3)}`
  } else if (onlyNums.length > 0) {
    value = `(${onlyNums.substring(0,4)}`
  }
  return value
}
