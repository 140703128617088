import {QuestionsInterface, Options} from "../index"
import {required} from "../../utils/validators"

export const ADDITIONAL_SHIPTO_QUESTIONS: QuestionsInterface<Options>[] = [
  {
    QUESTION_TEXT: "Would you like to add another ship-to location?",
    BLOCK_HELPER_TEXT: "(You may add up to 5 locations, at this time.)",
    VALIDATION: [required],
    OPTIONS: [
      {
        label: "Yes",
        value: Options.YES,
        name: "additionalShipto",
        id: "additionalShipto-yes",
        dataTestId: "additionalShipto-yes",
        type: "radio",
        componentType: "Radio",
      },
      {
        label: "No",
        value: Options.NO,
        name: "additionalShipto",
        id: "additionalShipto-no",
        dataTestId: "additionalShipto-no",
        type: "radio",
        componentType: "Radio",
      },
    ],
  },
]
