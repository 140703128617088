import {useContext, useEffect} from "react"
import {useNavigate, useLocation} from "react-router-dom"
import {Form} from "react-final-form"
import {WrapperContainer, Button, Stepper} from "ui"
import Question from "../utils/Question"
import {
  RoutePaths,
  FormButton,
  QuestionsInterface,
  AccountType,
  Options,
  UPDATE_QUESTIONS,
} from "../../constants"
import {nacMachineContext} from "../../context"
import {NACEvents, NACStates} from "../../machine/nac-machine.types"
import {
  HEADER,
  PAYMENT_INFO_PET_QUESTIONS,
  PAYMENT_INFO_VET_QUESTIONS,
} from "../../constants/payment-information"
import {PaymentMethod} from "../../types"
import {TransactionType} from "../../constants/select-transaction"

type valuesType = {
  paymentMethod: PaymentMethod
}

const PaymentInformation = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const {state, send} = useContext(nacMachineContext) || {}
  const isEditFlow = state?.history?.value === NACStates.review

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (values: any) => {
    send(NACEvents.NEXT, {
      data: {...values, history: state?.history?.value},
    })
    const isTransferOwnership =
      Number(state?.context?.selectedTransaction) ===
      TransactionType.TransferOwnership
    const isUpdateProfile =
      Number(state?.context?.selectedTransaction) ===
      TransactionType.UpdateCustomerAccountProfile

    if (isEditFlow) {
      /* check if the user is in the edit flow, if so, navigate to the review page */
      navigate(RoutePaths.REVIEW)
    } else if (isTransferOwnership) {
      navigate(RoutePaths.SHIP_TO_ACCOUNT)
    } else if (isUpdateProfile) {
      /* 
        check if user wants to make another update, if so, navigate to the update account page
      */
      if (Number(values.update) === Options.YES) {
        navigate(RoutePaths.UPDATE_ACCOUNT)
      } else {
        navigate(RoutePaths.DATE_SIGN)
      }
    } else {
      navigate(RoutePaths.ADD_SHIPTO)
    }
  }

  const renderSubQuestion = (
    question: QuestionsInterface<PaymentMethod>,
    values: valuesType,
    index: number,
    fieldName: string,
  ) => {
    if (
      question?.SUB_QUESTIONS &&
      question?.SUB_QUESTIONS[0].EXPECTED_PARENT_VALUE.includes(
        values[fieldName as keyof valuesType],
      )
    ) {
      return (
        <>
          <Question
            questions={question?.SUB_QUESTIONS[0].QUESTIONS}
            renderSubQuestion={renderSubQuestion}
            values={values}
            subQuestion={true}
          />
        </>
      )
    }
  }

  const isNonProfitVet =
    Number(state?.context?.accountType) === AccountType.NON_PROFIT &&
    Number(state?.context?.validateSpeciality?.staff) === Options.YES

  const isVetMobileVetOrNonProfitVet =
    [AccountType.VETERINARY, AccountType.MOBILE_VETERINARY].includes(
      Number(state?.context?.accountType),
    ) || isNonProfitVet

  const paymentInfoQuestions = isVetMobileVetOrNonProfitVet
    ? PAYMENT_INFO_VET_QUESTIONS
    : PAYMENT_INFO_PET_QUESTIONS

  useEffect(() => {
    if (state?.value !== NACStates.paymentInformation) {
      send(NACEvents.INIT, {data: {route: location.pathname}})
    }
  }, [])
  return (
    <>
      <Stepper currentStep={3} totalSteps={5} />
      <h2 className="my-4 text-sm font-bold">{HEADER}</h2>

      <div className="flex justify-center">
        <div className="w-full sm:w-[520px]">
          <WrapperContainer>
            <Form
              onSubmit={onSubmit}
              initialValues={{...state?.context?.paymentInformation}}
              render={({handleSubmit, invalid, values}) => (
                <form onSubmit={handleSubmit}>
                  <Question
                    questions={paymentInfoQuestions}
                    renderSubQuestion={renderSubQuestion}
                    values={values}
                  />

                  {/* 
                    Show Update question only if the selected transaction is Update Customer Account Profile
                  */}
                  {Number(state?.context?.selectedTransaction) ===
                    TransactionType.UpdateCustomerAccountProfile && (
                    <Question questions={UPDATE_QUESTIONS} values={values} />
                  )}

                  <div className="flex justify-end gap-8">
                    <Button
                      variant="text"
                      onClick={() => {
                        send(NACEvents.BACK, {
                          data: {history: state?.history?.value},
                        })
                        if (isEditFlow) {
                          navigate(RoutePaths.REVIEW)
                        } else if (
                          state?.context?.selectedTransaction ==
                          TransactionType.UpdateCustomerAccountProfile
                        ) {
                          navigate(RoutePaths.UPDATE_ACCOUNT)
                        } else {
                          navigate(RoutePaths.FINANCIAL_INFO)
                        }
                      }}
                    >
                      {FormButton.BACK}
                    </Button>
                    <Button
                      disabled={invalid}
                      variant="primary"
                      mode="filled"
                      type="submit"
                    >
                      {isEditFlow
                        ? FormButton.SAVE_AND_CONTINUE
                        : FormButton.CONTINUE}
                    </Button>
                  </div>
                </form>
              )}
            />
          </WrapperContainer>
        </div>
      </div>
    </>
  )
}

export default PaymentInformation
