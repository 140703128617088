import { Options } from "../constants"
import { NACMachineContext } from "../machine"

const TNonProfit = {
  [Options.YES]: "ASSISTANCE_ORG",
  [Options.NO]: "SHELTER_HUMAN_SOCIETY",
}

export function transformNonProfitDetails(context: NACMachineContext) {
  return {
    isNonProfitWith501c3: context.validateSpeciality?.nonProfit == Options.YES,
    nonProfitType:
      context?.validateSpeciality?.organization &&
      TNonProfit[context?.validateSpeciality?.organization],
  }
}
