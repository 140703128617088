import React, {useContext, useEffect} from "react"
import {Form} from "react-final-form"
import {useNavigate, useLocation} from "react-router-dom"
import {Button, WrapperContainer} from "ui"
import {FormButton, RoutePaths} from "../../../constants"
import {TransactionType} from "../../../constants/select-transaction"
import {
  SHIP_TO_ACCOUNT_QUESTION,
  SHIP_TO_TRANSFER_QUESTION,
} from "../../../constants/shipto/account"
import {UpdateAccountOptions} from "../../../constants/update-account-options"
import {nacMachineContext} from "../../../context"
import {NACEvents, NACStates} from "../../../machine"
import {AddShipTo} from "../../../types"
import Question from "../../utils/Question"

const ShipToAccount = () => {
  const {state, send} = useContext(nacMachineContext) || {}
  const navigate = useNavigate()
  const location = useLocation()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (values: any) => {
    const {addShipTo = []} = state?.context || {}
    const existingIndex = addShipTo.findIndex(
      (shipto: AddShipTo) => shipto.shipToAccountNumber == values.accountNum,
    )

    const updateIndex =
      existingIndex >= 0
        ? existingIndex
        : state?.context?.addShipTo
        ? state?.context?.addShipTo.length
        : 0
    
    if (values.accountNum === undefined) {
      values.accountNum = updateIndex
    }
    
    send(NACEvents.NEXT, {
      data: {
        shipToAccountNumber: values.accountNum,
      },
    })
    const isUpdateFlow =
      Number(state?.context?.selectedTransaction) ===
        TransactionType.UpdateCustomerAccountProfile &&
      [
        UpdateAccountOptions.CLINIC_SHIPPING_LOCATION_CONTACTS,
        UpdateAccountOptions.STORE_SHIPPING_LOCATION_CONTACTS,
        UpdateAccountOptions.UNIVERSITY_SHIPPING_LOCATION_CONTACTS,
      ].includes(state?.context?.updateAccount)
    const nextRoute = isUpdateFlow ? RoutePaths.CONTACTS : RoutePaths.ADD_SHIPTO
    navigate(`${nextRoute}?id=${updateIndex}`)
  }

  useEffect(() => {
    if (state?.value !== NACStates.shipToNumber) {
      send(NACEvents.INIT, {data: {route: location.pathname}})
    }
  }, [])

  return (
    <WrapperContainer classes="my-4 w-full sm:w-[520px]">
      <Form
        onSubmit={onSubmit}
        render={({handleSubmit, invalid, values, touched}) => {
          return (
            <form onSubmit={handleSubmit}>
              <Question
                questions={
                  state?.context?.selectedTransaction ==
                  TransactionType.TransferOwnership
                    ? SHIP_TO_TRANSFER_QUESTION
                    : SHIP_TO_ACCOUNT_QUESTION
                }
                values={values}
                touched={touched}
              />
              <div className="mt-14 flex justify-end gap-8">
                <Button
                  variant="text"
                  onClick={() => {
                    const backRoute =
                      state?.context?.selectedTransaction ==
                      TransactionType.UpdateCustomerAccountProfile
                        ? RoutePaths.UPDATE_ACCOUNT
                        : state?.context?.selectedTransaction ==
                          TransactionType.TransferOwnership 
                          ? RoutePaths.PAYMENT_INFORMATION 
                          : RoutePaths.HOW_MANY_SHIP_TO_QUESTION

                    send(NACEvents.BACK)
                    navigate(backRoute)
                  }}
                >
                  {FormButton.BACK}
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  mode="filled"
                  disabled={invalid}
                >
                  {FormButton.SAVE_AND_CONTINUE}
                </Button>
              </div>
            </form>
          )
        }}
      />
    </WrapperContainer>
  )
}

export default ShipToAccount
