import React from "react"
import { Button, WrapperContainer } from "ui"
import { HILLS_PET_URL, THANK_YOU } from "../../constants/thank-you"

const ThankYou = () => {
  function navigateToHillsPet() {
    window.open(HILLS_PET_URL, "_blank", "noopener,noreferrer")
  }

  return (
    <>
      <h1 className="my-6 max-w-md text-center text-[2.75rem] font-extrabold leading-10">
        {THANK_YOU.header}
      </h1>
      <WrapperContainer>
        <div className="p-4">
          <p className="max-w-[728px] text-center">{THANK_YOU.message}</p>
          <div className="mt-4 flex justify-center">
            <Button
              variant="primary"
              mode="filled"
              onClick={navigateToHillsPet}
            >
              {THANK_YOU.cta}
            </Button>
          </div>
        </div>
      </WrapperContainer>
    </>
  )
}

export default ThankYou
