export interface SelectEnquiry {
  label: string;
  helperText?: string;
  value: number;
  name: string;
  id: string;
}

export enum NonProfitEnquiry {
  YES,
  NO,
}

export const NONPROFIT_QUESTION =
  "Are you inquiring on behalf of a zoo, research facility or breeder?";

export const BLOCKED_ENQUIRY: NonProfitEnquiry[] = [NonProfitEnquiry.YES];

export const ENQUIRY_ALERT = {
  title: "We’re sorry!",
  description:
    "At this time, we aren’t accepting zoo, research facility or breeder customers. Please contact us at a later date, as we may in the future.",
};

export const ENQUIRY_ITEMS: Array<SelectEnquiry> = [
  {
    label: "Yes",
    value: NonProfitEnquiry.YES,
    name: "enquiry",
    id: "non-profit-yes",
  },
  {
    label: "No",
    value: NonProfitEnquiry.NO,
    name: "enquiry",
    id: "non-profit-no",
  },
];
