import { required } from "ui"
import { Options, QuestionsInterface } from ".."

export const SHIP_TO_NUMBERS_MESSAGE =
  "The following ship-to locations will be included in this transfer of ownership"

export const ADDITONAL_SHIP_TO_QUESTIONS: QuestionsInterface<Options>[] = [
  {
    QUESTION_TEXT:
      "Are there additional ship-to locations that should be included?",
    VALIDATION: [required],
    OPTIONS: [
      {
        componentType: "Radio",
        label: "Yes",
        value: Options.YES,
        name: "additional",
        id: "yes",
        dataTestId: "yes",
        type: "radio",
        helperText: "(Add Another Location)",
      },
      {
        componentType: "Radio",
        label: "No",
        helperText: "(This List Is Correct)",
        value: Options.NO,
        name: "additional",
        id: "no",
        dataTestId: "no",
        type: "radio",
      },
    ],
  },
]
