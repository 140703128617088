import {useState, useContext, useEffect} from "react"
import {useNavigate, useLocation} from "react-router-dom"
import {Form, Field} from "react-final-form"
import {WrapperContainer, Radio, Button} from "ui"
import {
  UPDATE_ACCOUNT_QUESTION,
  UPDATE_OPTIONS_VET,
  UPDATE_OPTIONS_PET,
  UPDATE_OPTIONS_UNIVERSITY,
  UPDATE_OPTIONS_GOV,
  UPDATE_ACCOUNTS_VET,
  UPDATE_ACCOUNTS_PET,
  UPDATE_ACCOUNTS_UNIVERSITY,
  UPDATE_ACCOUNTS_GOV,
  ROUTES,
  UpdateAccountOptions,
} from "../../constants/update-account-options"
import {RoutePaths, FormButton, QuestionOptionsInterface} from "../../constants"
import {nacMachineContext} from "../../context"
import {NACEvents, NACStates} from "../../machine/nac-machine.types"

const UpdateAccount = () => {
  const [options, setOptions] = useState<QuestionOptionsInterface[]>([])
  const navigate = useNavigate()
  const location = useLocation()
  const {state, send} = useContext(nacMachineContext) || {}

  const onSubmit = (values: {updateOption: string}) => {
    send(NACEvents.NEXT, {data: values.updateOption})
    navigate(ROUTES[values.updateOption as UpdateAccountOptions] as string)
  }
  useEffect(() => {
    if (state?.value !== NACStates.updateAccount) {
      send(NACEvents.INIT, {data: {route: location.pathname}})
    }
    if (UPDATE_ACCOUNTS_VET.includes(Number(state.context.accountType))) {
      setOptions(UPDATE_OPTIONS_VET)
    } else if (
      UPDATE_ACCOUNTS_PET.includes(Number(state.context.accountType))
    ) {
      setOptions(UPDATE_OPTIONS_PET)
    } else if (
      UPDATE_ACCOUNTS_UNIVERSITY.includes(Number(state.context.accountType))
    ) {
      setOptions(UPDATE_OPTIONS_UNIVERSITY)
    } else if (
      UPDATE_ACCOUNTS_GOV.includes(Number(state.context.accountType))
    ) {
      setOptions(UPDATE_OPTIONS_GOV)
    }
  }, [])
  return (
    <div className="flex justify-center">
      <div className="w-full sm:w-[520px]">
        <WrapperContainer>
          <p className="mb-3 font-bold text-gray-700">
            {UPDATE_ACCOUNT_QUESTION}
          </p>
          <Form
            onSubmit={onSubmit}
            render={({handleSubmit, invalid}) => (
              <form onSubmit={handleSubmit}>
                <div className=" flex flex-col gap-2">
                  {options.map(option => {
                    return (
                      <Field
                        key={option.id}
                        name={option.name}
                        component={Radio}
                        type="radio"
                        value={`${option.value}`}
                        label={option.label}
                        validate={value => (value ? undefined : "Required")}
                        helpertext={option.helperText}
                      />
                    )
                  })}
                </div>
                <div className="mt-10 flex justify-end gap-8">
                  <Button
                    variant="text"
                    onClick={() => {
                      send(NACEvents.BACK)
                      navigate(RoutePaths.SHIP_TO_QUALIFIER)
                    }}
                  >
                    {FormButton.BACK}
                  </Button>
                  <Button
                    disabled={invalid}
                    variant="primary"
                    mode="filled"
                    type="submit"
                  >
                    {FormButton.CONTINUE}
                  </Button>
                </div>
              </form>
            )}
          />
        </WrapperContainer>
      </div>
    </div>
  )
}

export default UpdateAccount
