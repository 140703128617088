import React, {useContext, useEffect} from "react"
import {Field, Form} from "react-final-form"
import {useNavigate, useLocation} from "react-router-dom"
import {Button, Radio, WrapperContainer} from "ui"
import {AccountType, FormButton, RoutePaths} from "../../constants"
import {
  SELECT_TRANSACTION_HEADER,
  TransactionType,
  TRANSACTION_ITEMS,
} from "../../constants/select-transaction"
import {nacMachineContext} from "../../context"
import {NACEvents, NACStates} from "../../machine"
import {getRoute} from "../../utils/getRoute"

const SelectTranscationType = () => {
  const {state, send} = useContext(nacMachineContext) || {}
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (state?.value !== NACStates.selectTranscationType) {
      send(NACEvents.INIT, {data: {route: location.pathname}})
    }
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (values: any) => {
    send(NACEvents.NEXT, {data: values.selectedTransaction})
    const nextRoute = [
      TransactionType.AddNewShipToLocation,
      TransactionType.UpdateCustomerAccountProfile,
      TransactionType.TransferOwnership,
    ].includes(Number(values.selectedTransaction))
      ? RoutePaths.SHIP_TO_QUALIFIER
      : RoutePaths.BUISNESS_INFORMATION
    navigate(nextRoute)
  }

  const updatedTransactionItems = getTransactionItems(
    state?.context?.accountType,
  )

  return (
    <WrapperContainer classes="my-4 w-full sm:w-[520px]">
      <p className="mb-3 font-bold text-gray-700">
        {SELECT_TRANSACTION_HEADER}
      </p>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          selectedTransaction: state?.context?.selectedTransaction,
        }}
        render={({handleSubmit, values}) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className=" flex flex-col gap-2">
                {updatedTransactionItems.map(transactionItem => {
                  return (
                    <Field
                      name={transactionItem.name}
                      component={Radio}
                      type="radio"
                      value={`${transactionItem.value}`}
                      label={transactionItem.label}
                      validate={value => (value ? undefined : "Required")}
                    />
                  )
                })}
              </div>
              <div className="mt-10 flex justify-end gap-8">
                <Button
                  variant="text"
                  onClick={() => {
                    const backRoute = getRoute(state?.context?.accountType)
                    send(NACEvents.BACK)
                    if (backRoute) {
                      navigate(backRoute)
                    }
                  }}
                >
                  {FormButton.BACK}
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  mode="filled"
                  disabled={!values.selectedTransaction}
                >
                  {FormButton.CONTINUE}
                </Button>
              </div>
            </form>
          )
        }}
      />
    </WrapperContainer>
  )
}

function getTransactionItems(accountType: AccountType) {
  const isMilataryorLawAccountType = [
    AccountType.LAW_ENFORCEMENT,
    AccountType.MILITARY,
  ].includes(Number(accountType))
  const isUniversityAccount = accountType == AccountType.VET_TECH
  if (isMilataryorLawAccountType) {
    return TRANSACTION_ITEMS.filter(
      item => item.value !== TransactionType.TransferOwnership,
    )
  } else if (isUniversityAccount) {
    return TRANSACTION_ITEMS.filter(
      item =>
        ![
          TransactionType.TransferOwnership,
          TransactionType.AddNewShipToLocation,
        ].includes(item.value),
    )
  }
  return TRANSACTION_ITEMS
}

export default SelectTranscationType
