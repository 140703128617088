import React, {useState, useContext, useEffect} from "react"
import {useNavigate, useLocation} from "react-router-dom"
import {Form, Field} from "react-final-form"
import {WrapperContainer, Radio, AlertDialog, Button} from "ui"
import {
  NONPROFIT_QUESTION,
  ENQUIRY_ALERT,
  ENQUIRY_ITEMS,
  BLOCKED_ENQUIRY,
} from "../../../constants/accounts/non-profit"
import {RoutePaths, FormButton} from "../../../constants"
import {nacMachineContext} from "../../../context"
import {NACEvents, NACStates} from "../../../machine/nac-machine.types"

const NonProfit = () => {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const {state, send} = useContext(nacMachineContext) || {}

  const renderAlertBox = () => {
    return (
      <AlertDialog
        open={open}
        setOpen={setOpen}
        title={ENQUIRY_ALERT.title}
        description={ENQUIRY_ALERT.description}
      />
    )
  }

  const onSubmit = (values: {enquiry: string}) => {
    if (BLOCKED_ENQUIRY.includes(parseInt(values.enquiry))) {
      setOpen(true)
    } else {
      send(NACEvents.NEXT, {data: values})
      navigate(RoutePaths.VALIDATE_SPECIALTY)
    }
  }

  useEffect(() => {
    if (state?.value !== NACStates.validateNonProfitSpeciality) {
      send(NACEvents.INIT, {data: {route: location.pathname}})
    }
  }, [])
  return (
    <div className="flex justify-center">
      <div className="w-full sm:w-[520px]">
        <WrapperContainer>
          <p className="mb-3 font-bold text-gray-700">{NONPROFIT_QUESTION}</p>

          <Form
            onSubmit={onSubmit}
            initialValues={{
              enquiry: state.context.validateNonProfit?.enquiry,
            }}
            render={({handleSubmit, invalid}) => (
              <form onSubmit={handleSubmit}>
                <div className=" flex flex-col gap-2">
                  {ENQUIRY_ITEMS.map(enquiry => {
                    return (
                      <Field
                        key={enquiry.id}
                        name={enquiry.name}
                        component={Radio}
                        type="radio"
                        value={`${enquiry.value}`}
                        label={enquiry.label}
                        validate={value => (value ? undefined : "Required")}
                      />
                    )
                  })}
                </div>
                <div className="mt-10 flex justify-end gap-8">
                  <Button
                    variant="text"
                    onClick={() => {
                      send(NACEvents.BACK)
                      navigate(RoutePaths.ACCOUNT_TYPE)
                    }}
                  >
                    {FormButton.BACK}
                  </Button>
                  <Button
                    disabled={invalid}
                    variant="primary"
                    mode="filled"
                    type="submit"
                  >
                    {FormButton.CONTINUE}
                  </Button>
                </div>
              </form>
            )}
          />
          {renderAlertBox()}
        </WrapperContainer>
      </div>
    </div>
  )
}

export default NonProfit
