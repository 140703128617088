import {useContext} from "react"
import {nacMachineContext} from "../../../context"
import Question from "../../utils/Question"
import OperationalHours from "./OperationalHours"
import {Options, QuestionsInterface} from "../../../constants"
import {
  OUTSIDE_HOURS_QUESTION,
  OUTSIDE_OPERATIONAL_HOURS_QUESTION,
} from "../../../constants/shipto/add"
import {TransactionType} from "../../../constants/select-transaction"
import {required} from "../../../utils/validators"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const OutsideHours = ({values}: Record<string, any>) => {
  const {state} = useContext(nacMachineContext) || {}
  const UPDATE = (value : QuestionsInterface<Options>[]) => {
    const validation = value[0].VALIDATION?.filter((item : unknown) => item !== required)
    value[0].VALIDATION = validation
    return value
  }
  const isUpdateProfile = state?.context?.selectedTransaction ==
    TransactionType.UpdateCustomerAccountProfile

  return (
    <div className="mb-10">
      <Question questions={isUpdateProfile ? 
        UPDATE(OUTSIDE_HOURS_QUESTION) : OUTSIDE_HOURS_QUESTION} values={values} />
      {values?.outsideHours === Options.YES.toString() && (
        <OperationalHours
          values={values}
          question={OUTSIDE_OPERATIONAL_HOURS_QUESTION}
          fieldName={OUTSIDE_OPERATIONAL_HOURS_QUESTION.fieldName}
          closedMessage={OUTSIDE_OPERATIONAL_HOURS_QUESTION.closedMessage}
          placeholder={OUTSIDE_OPERATIONAL_HOURS_QUESTION.placeholder}
        />
      )}
    </div>
  )
}

export default OutsideHours