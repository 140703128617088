import React, {useContext, useState, useEffect} from "react"
import {useNavigate, useLocation} from "react-router-dom"
import {WrapperContainer, Radio, Button, AlertDialog} from "ui"
import {HILLSPET_EMAIL_CANADA, FormButton, RoutePaths} from "../../constants"
import {nacMachineContext} from "../../context"
import {NACEvents, NACStates} from "../../machine"
import {Field, Form} from "react-final-form"
import {
  LOCATION,
  LOCATION_ITEMS,
  BLOCKED_SELECT_LOCATION_ALERT,
  emailUs,
  selectLocationQuestion,
} from "../../constants/select-location"

const SelectLocation = () => {
  const [shouldDisplayModal, setShouldDisplayModal] = useState(false)
  const {state, send} = useContext(nacMachineContext) || {}
  const navigate = useNavigate()
  const location = useLocation()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (values: any) => {
    const {country} = values
    if (Number(country) === LOCATION.CANADA) {
      setShouldDisplayModal(true)
    } else if (send) {
      send(NACEvents.NEXT, {
        data: country,
      })
      navigate(RoutePaths.ACCOUNT_TYPE)
    }
  }

  function handleConfirm(e: React.MouseEvent) {
    window.open(`mailto:${HILLSPET_EMAIL_CANADA}`, "_blank")
    e.preventDefault()
  }

  useEffect(() => {
    if (state?.value !== NACStates.selectLocation) {
      send(NACEvents.INIT, {data: {route: location.pathname}})
    }
  }, [])

  return (
    <WrapperContainer classes="w-full sm:w-[520px]">
      <p className="mb-3 text-lg font-bold text-gray-700">
        {selectLocationQuestion}
      </p>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          country: state?.context?.location,
        }}
        render={({handleSubmit, values}) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col gap-2">
                {LOCATION_ITEMS.map(({name, label, value}) => {
                  return (
                    <Field
                      name={name}
                      component={Radio}
                      type="radio"
                      value={`${value}`}
                      label={label}
                      validate={value => (value ? undefined : "Required")}
                    />
                  )
                })}
              </div>
              <div className="mt-10 flex justify-end gap-8">
                <Button
                  variant="text"
                  onClick={() => {
                    send(NACEvents.BACK)
                    navigate(RoutePaths.LANDING_PAGE)
                  }}
                >
                  {FormButton.BACK}
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  mode="filled"
                  disabled={!values.country}
                >
                  {FormButton.CONTINUE}
                </Button>
              </div>
            </form>
          )
        }}
      />
      <AlertDialog
        description={BLOCKED_SELECT_LOCATION_ALERT.description}
        open={shouldDisplayModal}
        setOpen={setShouldDisplayModal}
        title={BLOCKED_SELECT_LOCATION_ALERT.title}
        labelConfirm={emailUs}
        handleConfirm={handleConfirm}
      />
    </WrapperContainer>
  )
}

export default SelectLocation
