/* eslint-disable @typescript-eslint/no-explicit-any */
import {useState, useEffect, useContext, createContext} from "react"
import {useNavigate} from "react-router-dom"
import axios from "axios"
import gigya from "../lib/gigya"
import {AUTHENTICATION_STATUS} from "../constants/authStatus"

type User = {
  UID: string
  UIDSignature: string
  signatureTimestamp: string
}

interface Auth {
  user: User
  setUser: React.Dispatch<React.SetStateAction<User | Record<string, unknown>>>
  status: string
  setStatus: React.Dispatch<React.SetStateAction<string>>
  error: string | null
  setError: React.Dispatch<React.SetStateAction<null>>
  checkSessionStatus: () => void
}

const authContext = createContext<Auth | Record<string, unknown>>({})

export function AuthProvider({children}: any) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuth = () => {
  return useContext(authContext)
}

function useProvideAuth() {
  const navigate = useNavigate()
  const [user, setUser] = useState<User | Record<string, unknown>>({})
  const [status, setStatus] = useState(AUTHENTICATION_STATUS.LOADING)
  const [error, setError] = useState(null)

  /* Use this function to check user session status.
    Return Session Status type: Boolean
  */
  async function sessionExists() {
    return gigya.hasSession().then((sessionExist: boolean) => sessionExist)
  }

  function handleLogout() {
    axios
      .delete(`${process.env.REACT_APP_JWT_API_URL}/v1/auth/token`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("gigyaToken")}`,
        },
      })
      .finally(() => {
        window.localStorage.removeItem("gigyaToken")
        setStatus(AUTHENTICATION_STATUS.NOT_AUTHENTICATED)
        gigya.accounts.logout()
      })
  }

  useEffect(() => {
    /* 
      Fetch the user's information.
      If fetch is successful, set the user's information to the auth state.
      If fetch is unsuccessful, set the error to the status state.
    */
    function fetchUserData(response: any) {
      if (response.errorCode === 0) {
        setUser({
          UID: response.UID,
          UIDSignature: response.UIDSignature,
          signatureTimestamp: response.signatureTimestamp,
        })
        if (localStorage.getItem("gigyaToken") !== null) {
          setStatus(AUTHENTICATION_STATUS.AUTHENTICATED)
        } else {
          axios
            .get(`${process.env.REACT_APP_JWT_API_URL}/v1/auth/token`, {
              params: {
                UID: response.UID,
                UIDSignature: response.UIDSignature,
                signatureTimestamp: response.signatureTimestamp,
                brand: "hills",
              },
            })
            .then((apiResponse: any) => {
              if (apiResponse.status === 200) {
                window?.localStorage.setItem(
                  "gigyaToken",
                  apiResponse.data.token,
                )
                setStatus(AUTHENTICATION_STATUS.AUTHENTICATED)
                navigate("/", {replace: true})
              }
            })
        }
      } else {
        setError(response.errorMessage)
      }
    }

    /* 
      Check if the user is logged in.
      If the user is logged in, fetch user information and set to the global state.
      If the user is not logged in, set the global state to an empty object.
    */

    gigya.hasSession().then((sessionExist: boolean) => {
      if (sessionExist === true) {
        gigya.accounts.getAccountInfo({callback: fetchUserData})
      } else if (sessionExist === false) {
        setUser({})
        setStatus(AUTHENTICATION_STATUS.NOT_AUTHENTICATED)
      }
    })
  }, [])

  return {
    user,
    setUser,
    status,
    setStatus,
    error,
    setError,
    sessionExists,
    handleLogout,
  }
}
