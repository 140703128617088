import { HILLSPET_EMAIL_CANADA } from ".";

export enum LOCATION {
  USA,
  CANADA,
}

export const selectLocationQuestion = "Where are you located?";
export const emailUs = "Email Us";
export interface SelectLocationType {
  label: string;
  value: number;
  name: string;
}
export const LOCATION_ITEMS: Array<SelectLocationType> = [
  {
    label: "United States",
    value: LOCATION.USA,
    name: "country",
  },
  {
    label: "Canada",
    value: LOCATION.CANADA,
    name: "country",
  },
];
export const BLOCKED_SELECT_LOCATION_ALERT = {
  title: "We’re sorry!",
  description: `This site is intended for United States customers only. For help with accounts in Canada, send an email to <a href=mailto:${HILLSPET_EMAIL_CANADA}>Contact_Canada@hillspet.com</a>`,
};
