import { QuestionsInterface, Options } from "../index"
import { required } from "../../utils/validators"

export interface ValidateVetTech {
  accredition: Options
  order: Options
  program: PROGRAM
}

export type PROGRAM = "VET_TECH_NURSING_SCHOOL" | "COLLEGE_VET_MED"
export const SCHOOLS_HILLS_EMAIL = "schools_hills_vip_market@hillspet.com"

export const VET_TECH_ALERT = {
  title: "We’re sorry!",
  labelConfirm: "Email Us",
  description:
    "You must be AVMA-accredited and ordering for your own pet exclusively to be considered. If you have any questions, you can email us at schools_hills_vip_market@hillspet.com.",
}

export const VET_TECH_QUESTIONS: QuestionsInterface<Options>[] = [
  {
    QUESTION_TEXT:
      "Are you accredited by the American Veterinary Medical Association (AVMA) and ordering for your own pet exclusively?",
    VALIDATION: [required],
    OPTIONS: [
      {
        label: "Yes",
        value: Options.YES,
        name: "accredition",
        id: "accredition-yes",
        type: "radio",
        componentType: "Radio",
        dataTestId: "accredition-yes",
      },
      {
        label: "No",
        value: Options.NO,
        name: "accredition",
        id: "accredition-no",
        dataTestId: "accredition-no",
        type: "radio",
        componentType: "Radio",
      },
    ],
  },
  {
    QUESTION_TEXT:
      "Are you ordering the product to feed your dog and cat patients on campus?",
    VALIDATION: [required],
    OPTIONS: [
      {
        label: "Yes",
        value: Options.YES,
        name: "order",
        id: "order-yes",
        dataTestId: "order-yes",
        type: "radio",
        componentType: "Radio",
      },
      {
        label: "No",
        value: Options.NO,
        name: "order",
        id: "order-no",
        dataTestId: "order-no",
        type: "radio",
        componentType: "Radio",
      },
    ],
  },
  {
    QUESTION_TEXT:
      "Which of the following best describes your school or program?",
    VALIDATION: [required],
    OPTIONS: [
      {
        label: "Veterinary Technology/Nursing School",
        value: "VET_TECH_NURSING_SCHOOL",
        name: "program",
        id: "program-school",
        dataTestId: "program-school",
        type: "radio",
        componentType: "Radio",
      },
      {
        label: "College of Veterinary Medicine",
        value: "COLLEGE_VET_MED",
        name: "program",
        id: "program-college",
        dataTestId: "program-college",
        type: "radio",
        componentType: "Radio",
      },
    ],
  },
]
