/* eslint-disable no-unsafe-optional-chaining */
import React, {useContext, useEffect, useState} from "react"
import {Field, Form} from "react-final-form"
import arrayMutators from "final-form-arrays"
import {FieldArray} from "react-final-form-arrays"
import {
  ADD_NEW_CONTACT,
  AGREEMENT_TERMS,
  Options,
  DELETE_CONTACT,
  DELETE_CONTACT_MESSAGE,
  HEADER,
  TABLE_HEADERS,
  INITIAL_VALUE,
  MULTIPLE_AGREEMENT_POLICY,
  NO_CONTACTS,
  PRIVACY_POLICY,
  POLICY,
} from "../../constants/contacts"
import {AccountType, Options as RadioOptions, PHONE_REGEX} from "../../constants"
import PrimaryContactAlert from "./PrimaryContactAlert"
import UpdateContactAlert from "./UpdateContactAlert"
import {
  AlertDialog,
  Button,
  Checkbox,
  IconPersonOutline,
  PlusIcon,
  Table,
} from "ui"
import {FormButton, RoutePaths, UPDATE_QUESTIONS} from "../../constants"
import {useNavigate, useLocation, useSearchParams} from "react-router-dom"
import {nacMachineContext} from "../../context"
import {NACEvents, NACStates} from "../../machine"
import {getTableHeaders, getTableRows} from "./contacts-utils"
import {ContactDetails} from "../../types"
import {TransactionType} from "../../constants/select-transaction"
import Question from "../utils/Question"
import {UpdateAccountOptions} from "../../constants/update-account-options"

export function Contacts() {
  const location = useLocation()
  const {state, send} = useContext(nacMachineContext) || {}
  const [searchParams] = useSearchParams()
  const currentIndex = searchParams.get("id")
    ? Number(searchParams.get("id"))
    : state?.context?.addShipTo?.length - 1
  const {
    contacts: initialContacts = [],
    primaryContact: initialPrimaryContact,
    agreement,
    multipleAgreement,
  } = (state?.context?.addShipTo?.length &&
    state?.context?.addShipTo[currentIndex]?.contactInformation) ||
  {}
  const [open, setOpen] = useState(false)
  const [contacts, setContacts] = useState<ContactDetails[]>(initialContacts)
  const [selectedContactId, setSelectedContactId] = useState("")
  const [openPrimaryModal, setOpenPrimaryModal] = useState(true)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openAgreementModal, setOpenAgreementModal] = useState(false)
  const navigate = useNavigate()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (values: any) => {
    contacts.map((m)=>{
      m.phone = m.phone.replace(PHONE_REGEX, "");
    });
    if (
      contacts.filter(contact => contact?.marketingCommunications == true)
        .length > 0 &&
      !(values?.agreement?.length && values?.multipleAgreement?.length)
    ) {
      setOpenAgreementModal(true)
    } else if (!values?.agreement?.length) {
      setOpenAgreementModal(true)
    } else {
      const primaryIndex = contacts.findIndex(
        contact => Number(contact.id) === Number(values.primaryContact),
      )
      if (primaryIndex > -1) {
        contacts[primaryIndex].viewDeliveryStatus = true
      }
      send(NACEvents.NEXT, {
        data: {
          ...values,
          contacts,
          updateIndex: currentIndex,
          paramsId: searchParams.get("id"),
        },
      })
      if (state?.context?.addShipTo?.length == 5) {
        return navigate(RoutePaths.DATE_SIGN)
      }

      const nextRoute = getNextRoute(
        state?.context?.accountType,
        Number(state?.context?.selectedTransaction),
        values?.update,
        searchParams.get("id"),
      )

      navigate(nextRoute)
    }
  }

  const POLICY_LINK = (
    <a href={POLICY.url} target="_blank">
      {PRIVACY_POLICY.label}
    </a>
  )
  const POLICY_LABEL = (
    <p>
      {POLICY.message} {POLICY_LINK}
    </p>
  )

  const MULTIPLE_POLICY_LABEL = (
    <p>
      {POLICY.multipleComtacts} {POLICY_LINK}
    </p>
  )

  useEffect(() => {
    // On close of update modal reset selectedContactId value
    if (open === false) {
      setSelectedContactId("")
    }
    if (state?.value !== NACStates.contact) {
      send(NACEvents.INIT, {data: {route: location.pathname}})
    }
  }, [open])

  const EmptyRow = (
    <tr className="border-b-4 border-white bg-brand-secondary">
      <td colSpan={8}>
        <div className="flex flex-col items-center justify-center p-8">
          <div className="mb-7 h-10 w-10">
            <IconPersonOutline />
          </div>
          <p className="font-medium">{NO_CONTACTS.message}</p>
          <p>{NO_CONTACTS.helperText}</p>
        </div>
      </td>
    </tr>
  )

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        mutators={{...arrayMutators}}
        initialValues={{
          contacts: initialContacts.length
            ? initialContacts
            : [{...INITIAL_VALUE}],
          primaryContact: initialPrimaryContact ?? "1",
          agreement,
          multipleAgreement,
        }}
        keepDirtyOnReinitialize
        render={({handleSubmit, values, invalid}) => {
          return (
            <>
              <h2 className="my-4 px-3 text-2xl font-bold">{HEADER}</h2>
              <form onSubmit={handleSubmit}>
                <Table
                  headerItems={getTableHeaders(
                    Number(state?.context?.accountType),
                    TABLE_HEADERS,
                  )}
                  data={getTableRows(
                    contacts,
                    Number(state?.context?.accountType),
                    values.primaryContact,
                    setOpenDeleteModal,
                    setSelectedContactId,
                    setOpen,
                  )}
                  EmptyRowMessage={EmptyRow}
                />
                <FieldArray name="contacts">
                  {({fields}) => (
                    <div>
                      <PrimaryContactAlert
                        fields={fields}
                        updateContacts={(updatedContacts: ContactDetails[]) => {
                          setContacts(updatedContacts)
                        }}
                        openPrimaryModal={
                          state?.context?.addShipTo[currentIndex]
                            ?.contactInformation
                            ? false
                            : openPrimaryModal
                        }
                        setOpenPrimaryModal={setOpenPrimaryModal}
                        primaryContactId={values.primaryContact}
                        contacts={contacts}
                      />
                      <UpdateContactAlert
                        contacts={contacts}
                        open={open}
                        setOpen={setOpen}
                        selectedContactId={selectedContactId}
                        primaryContactId={values.primaryContact}
                        addContact={(values: ContactDetails) =>
                          setContacts([
                            ...contacts,
                            {...values, id: contacts.length + 1},
                          ])
                        }
                        updateContact={(updatedContacts: ContactDetails[]) => {
                          setContacts(updatedContacts)
                          setSelectedContactId("")
                        }}
                      />
                      <AlertDialog
                        open={openDeleteModal}
                        setOpen={setOpenDeleteModal}
                        title={DELETE_CONTACT}
                        labelConfirm={DELETE_CONTACT}
                        confirmVariant={"danger"}
                        handleConfirm={() => {
                          const updatedContacts = contacts.filter(
                            contact => contact.id !== Number(selectedContactId),
                          )
                          setContacts(updatedContacts)
                          setOpenDeleteModal(!openDeleteModal)
                        }}
                      >
                        <div className="my-4">{DELETE_CONTACT_MESSAGE}</div>
                      </AlertDialog>
                      <AlertDialog
                        open={openAgreementModal}
                        setOpen={setOpenAgreementModal}
                        title={AGREEMENT_TERMS.header}
                        labelClose={Options.CLOSE}
                        handleConfirm={() => {
                          const updatedContacts = contacts.filter(
                            contact => contact.id !== Number(selectedContactId),
                          )
                          setContacts(updatedContacts)
                          setOpenDeleteModal(!openDeleteModal)
                        }}
                      >
                        <div className="my-4">{AGREEMENT_TERMS.message}</div>
                      </AlertDialog>
                      <div className="my-6 flex cursor-pointer justify-end">
                        <Button
                          variant="text"
                          mode="outlined"
                          disabled={contacts.length >= 6 ? true : false}
                          icon={
                            <div className="mr-4 mt-3 h-5 w-5">
                              <PlusIcon />
                            </div>
                          }
                          onClick={() => {
                            if (contacts.length === 0) {
                              setOpenPrimaryModal(true)
                            } else {
                              setOpen(true)
                            }
                          }}
                        >
                          <div className="py-4">{ADD_NEW_CONTACT}</div>
                        </Button>
                      </div>
                      <div className="border-t-2 border-black p-3">
                        <div className="max-w-5xl" data-testid="agreement">
                          <Field
                            name={PRIVACY_POLICY.name}
                            component={Checkbox}
                            type="checkbox"
                            value={PRIVACY_POLICY.value}
                            label={POLICY_LABEL}
                          />
                        </div>

                        {contacts.filter(
                          contact => contact?.marketingCommunications === true,
                        ).length > 0 && (
                          <div
                            className="max-w-5xl"
                            data-testid="multiple-agreement"
                          >
                            <Field
                              name={MULTIPLE_AGREEMENT_POLICY.name}
                              component={Checkbox}
                              type="checkbox"
                              value={MULTIPLE_AGREEMENT_POLICY.value}
                              label={MULTIPLE_POLICY_LABEL}
                            />
                          </div>
                        )}
                      </div>
                      {state?.context?.selectedTransaction ==
                        TransactionType.UpdateCustomerAccountProfile && (
                        <Question
                          questions={UPDATE_QUESTIONS}
                          values={values}
                        />
                      )}
                      <div className="mb-14 flex justify-end gap-8">
                        <Button
                          type="button"
                          variant="text"
                          onClick={() => {
                            const isUpdateFlow =
                              Number(state?.context?.selectedTransaction) ===
                                TransactionType.UpdateCustomerAccountProfile &&
                              [
                                UpdateAccountOptions.CLINIC_SHIPPING_LOCATION_CONTACTS,
                                UpdateAccountOptions.STORE_SHIPPING_LOCATION_CONTACTS,
                                UpdateAccountOptions.UNIVERSITY_SHIPPING_LOCATION_CONTACTS,
                              ].includes(state?.context?.updateAccount)
                            const backRoute = isUpdateFlow
                              ? RoutePaths.UPDATE_ACCOUNT
                              : RoutePaths.ADD_SHIPTO
                            send(NACEvents.BACK)
                            navigate(backRoute)
                          }}
                        >
                          {FormButton.BACK}
                        </Button>
                        <Button
                          variant="primary"
                          mode="filled"
                          type="submit"
                          disabled={invalid}
                        >
                          {state?.context?.selectedTransaction ==
                            TransactionType.UpdateCustomerAccountProfile ||
                          state?.history?.event?.type == NACEvents.EDIT
                            ? FormButton.SAVE_AND_CONTINUE
                            : FormButton.CONTINUE}
                        </Button>
                      </div>
                    </div>
                  )}
                </FieldArray>
              </form>
            </>
          )
        }}
      />
    </div>
  )
}

function getNextRoute(
  accountType: AccountType,
  selectedTransaction: TransactionType,
  update: RadioOptions,
  paramsId: string | null,
) {
  const updateFlowNextRoute =
    update == RadioOptions.YES
      ? RoutePaths.UPDATE_ACCOUNT
      : RoutePaths.DATE_SIGN
  if (selectedTransaction == TransactionType.UpdateCustomerAccountProfile) {
    return updateFlowNextRoute
  } else if (selectedTransaction == TransactionType.TransferOwnership) {
    return RoutePaths.ADDITIONAL_SHIPTO_TRANSFER
  } else if (paramsId) {
    return RoutePaths.REVIEW
  }

  return RoutePaths.ADDITIONAL_SHIPTO
}

export default Contacts
