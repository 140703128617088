import {useContext, useState, useEffect} from "react"
import {useNavigate} from "react-router-dom"
import {nacMachineContext} from "../../context"
import {NACEvents} from "../../machine/nac-machine.types"
import {AccountType} from "../../constants"
import {DateSignInformationType} from "../../types/review"
import {AUTHORIZER_TITLE, EDIT} from "../../constants/review"

const DateSignInformation = () => {
  const navigate = useNavigate()
  const {state, send} = useContext(nacMachineContext) || {}
  const [dateSignInfo, setDateSignInfo] = useState<DateSignInformationType>(
    {} as DateSignInformationType,
  )

  const hideAuthorizer = [
    AccountType.MILITARY,
    AccountType.NON_PROFIT,
    AccountType.VET_TECH,
  ].includes(Number(state.context.accountType))

  useEffect(() => {
    if (Object.keys(state).length > 0) {
      const dateSignInfoState = state.context.dateSign
      setDateSignInfo({
        dateSign: {
          title: !hideAuthorizer
            ? "Owner/Corporate Officer"
            : "Authorized Signer",
          testId: "authorizer-info",
          data: [
            `${dateSignInfoState.firstName} ${dateSignInfoState.lastName}`,
            dateSignInfoState.title,
            dateSignInfoState.email,
          ],
        },
      })
    }
  }, [])

  const renderDateSignInfo = (dateSignInfo: DateSignInformationType) => {
    const keys = Object.keys(dateSignInfo) as Array<
      keyof DateSignInformationType
    >
    return keys.map(key => {
      return (
        <div className="col-span-1" data-testid={dateSignInfo?.[key]?.testId}>
          <dt className="text-sm font-bold">{dateSignInfo?.[key]?.title}</dt>
          {dateSignInfo?.[key]?.data.map(data => {
            return (
              <>
                <dd className="text-sm font-light">{data}</dd>
              </>
            )
          })}
        </div>
      )
    })
  }

  return (
    <section className="mt-10 pt-3">
      <div className="flex justify-between pb-5">
        <h2 className="text-lg font-bold">{AUTHORIZER_TITLE}</h2>
        <button
          className="font-medium text-brand-primary"
          onClick={() => {
            send(NACEvents.EDIT, {data: "dateSignInfo"})
            navigate(`/datesign?edit=true`)
          }}
        >
          {EDIT}
        </button>
      </div>
      <div className="border-t border-gray-300">
        <div className="mt-6 grid grid-cols-2 gap-6 lg:grid-cols-3">
          {renderDateSignInfo(dateSignInfo)}
        </div>
      </div>
    </section>
  )
}

export default DateSignInformation
