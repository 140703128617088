import {useState, useContext, useEffect} from "react"
import {useNavigate, useLocation} from "react-router-dom"
import {Form, Field} from "react-final-form"
import {WrapperContainer, Radio, AlertDialog, Button} from "ui"
import {
  INTEND_QUESTION,
  PET_INTEND_ALERT,
  PET_INTEND_ITEMS,
  BLOCKED_INTEND,
} from "../../../constants/accounts/veterinary"
import {RoutePaths, FormButton} from "../../../constants"
import {nacMachineContext} from "../../../context"
import {NACEvents, NACStates} from "../../../machine/nac-machine.types"

const Veterinay = () => {
  const [open, setOpen] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()
  const {state, send} = useContext(nacMachineContext) || {}

  const renderAlertBox = () => {
    return (
      <AlertDialog
        open={open}
        setOpen={setOpen}
        title={PET_INTEND_ALERT.title}
        description={PET_INTEND_ALERT.description}
        handleConfirm={() => window.open(PET_INTEND_ALERT.contactURL, "_blank")}
        labelConfirm="Contact Us"
      />
    )
  }

  const onSubmit = (values: {intend: string}) => {
    if (BLOCKED_INTEND.includes(parseInt(values.intend))) {
      setOpen(true)
    } else {
      send(NACEvents.NEXT, {data: values});
      if (location.pathname === RoutePaths.ACCOUNT_PET) {
        navigate(RoutePaths.ACCOUNT_PET_ALLOWED)
      } else {
        navigate(RoutePaths.SELECT_TRANSACTION_TYPE)
      }
    }
  }

  useEffect(() => {
    if (state?.value !== NACStates.validatePetFoodRetailer) {
      send(NACEvents.INIT, {data: {route: location.pathname}})
    }
  }, [])
  return (
    <div className="flex justify-center">
      <div className="w-full sm:w-[520px]">
        <WrapperContainer>
          <p className="mb-3 font-bold text-gray-700">{INTEND_QUESTION}</p>

          <Form
            onSubmit={onSubmit}
            initialValues={{intend: state.context.validatePet?.intend}}
            render={({handleSubmit, invalid}) => (
              <form onSubmit={handleSubmit}>
                <div className=" flex flex-col gap-2">
                  {PET_INTEND_ITEMS.map(intend => {
                    return (
                      <Field
                        key={intend.id}
                        name={intend.name}
                        component={Radio}
                        type="radio"
                        value={`${intend.value}`}
                        label={intend.label}
                        validate={value => (value ? undefined : "Required")}
                      />
                    )
                  })}
                </div>
                <div className="mt-10 flex justify-end gap-8">
                  <Button
                    variant="text"
                    onClick={() => {
                      send(NACEvents.BACK)
                      navigate(RoutePaths.ACCOUNT_TYPE)
                    }}
                  >
                    {FormButton.BACK}
                  </Button>
                  <Button
                    disabled={invalid}
                    variant="primary"
                    mode="filled"
                    type="submit"
                  >
                    {FormButton.CONTINUE}
                  </Button>
                </div>
              </form>
            )}
          />
          {renderAlertBox()}
        </WrapperContainer>
      </div>
    </div>
  )
}

export default Veterinay
