import {AccountType, Options} from "../constants"
import {FinacialInformation} from "../types"

export function transformFinacialInformation(
  accountType: AccountType,
  financialInformation?: FinacialInformation,
) {
  if (accountType == AccountType.VET_TECH) {
    return
  }
  const purchasingAgentInformation = {
    firstName: financialInformation?.agentFirstName,
    lastName: financialInformation?.agentLastName,
    emailAddress: financialInformation?.agentEmail,
    phoneNo: financialInformation?.agentPhoneNumber,
  }

  const accountsPayableInformation = {
    firstName: financialInformation?.billingContactFirstName,
    lastName: financialInformation?.billingContactLastName,
    emailAddress: financialInformation?.billingContactEmail,
    phoneNo: financialInformation?.billingContactPhoneNumber,
  }

  const billingAddressInformation = {
    streetAddress: financialInformation?.streetAddress,
    apartment: financialInformation?.apartment,
    city: financialInformation?.city,
    state: financialInformation?.state,
    zipCode: financialInformation?.zipcode,
  }

  const orderInfo = {
    accountsPayableInformation,
    billingAddressInformation,
    emailForElectronicInvoice: financialInformation?.invoiceemail,
    emailForOrderConfirmations: financialInformation?.orderConfirmationEmail,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any

  if (accountType != AccountType.MILITARY) {
    orderInfo.purchasingAgentInformation = purchasingAgentInformation
  }

  const purchaseOrderInformation = {
    isPurchaseOrderRequired:
      financialInformation?.isPONeeded == Options.YES ? true : false,
    purchaseOrderNo: financialInformation?.bpo,
  }
  return [AccountType.LAW_ENFORCEMENT, AccountType.MILITARY].includes(
    Number(accountType),
  )
    ? {
        ...orderInfo,
        purchaseOrderInformation,
      }
    : orderInfo
}
