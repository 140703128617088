import {useContext} from "react"
import {nacMachineContext} from "../../../context"
import Question from "../../utils/Question"
import Option from "../../utils/Option"
import {Options, QuestionsInterface} from "../../../constants"
import {TransactionType} from "../../../constants/select-transaction"
import {
  SALES_TAX_CHARGE_QUESTION,
  EXEMPTION_CERTIFICATE,
} from "../../../constants/shipto/add"
import {required} from "../../../utils/validators"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SalesTax = ({values}: Record<string, any>) => {
  const {state} = useContext(nacMachineContext) || {}
  const UPDATE = (value : QuestionsInterface<Options>[]) => {
    const validation = value[0].VALIDATION?.filter((item : unknown) => item !== required)
    value[0].VALIDATION = validation
    return value
  }
  const isUpdateProfile = state?.context?.selectedTransaction ==
    TransactionType.UpdateCustomerAccountProfile

  return (
    <>
      <div className="mb-10">
        <Question questions={isUpdateProfile ? 
          UPDATE(SALES_TAX_CHARGE_QUESTION) : SALES_TAX_CHARGE_QUESTION} values={values} />
      </div>
      {values?.salesTax === Options.NO.toString() && (
        <div className="mb-10">
          <p className="text-gray-700">
            <span className="font-bold">{EXEMPTION_CERTIFICATE.label}</span>
          </p>
          <Option option={EXEMPTION_CERTIFICATE.option} />
        </div>
      )}
    </>
  )
}

export default SalesTax