/* eslint-disable @typescript-eslint/no-explicit-any */
import {TransitionConfigOrTarget} from "xstate"
import {
  NACMachineEvents,
  NACMachineContext,
  NACStates,
} from "./nac-machine.types"
import {RoutePaths} from "../constants"

export const RESET: TransitionConfigOrTarget<
  NACMachineContext,
  NACMachineEvents
> = [
  {
    target: NACStates.init,
    cond: (_context: any, event: {data?: {route: RoutePaths}}) =>
      event?.data?.route === RoutePaths.LANDING_PAGE,
  },
  {
    target: NACStates.selectLocation,
    cond: (_context: any, event: {data?: {route: RoutePaths}}) =>
      event?.data?.route === RoutePaths.SELECT_LOCATION,
  },
  {
    target: NACStates.selectAccountType,
    cond: (_context: any, event: {data?: {route: RoutePaths}}) =>
      event?.data?.route === RoutePaths.ACCOUNT_TYPE,
  },
  {
    target: NACStates.validatePetFoodRetailer,
    cond: (_context: any, event: {data?: {route: RoutePaths}}) =>
      event?.data?.route === RoutePaths.ACCOUNT_VETERINARY ||
      event?.data?.route === RoutePaths.ACCOUNT_PET,
  },
  {
    target: NACStates.validatePetAllow,
    cond: (_context: any, event: {data?: {route: RoutePaths}}) =>
      event?.data?.route === RoutePaths.ACCOUNT_PET
  },
  {
    target: NACStates.validateHomeImprovementStore,
    cond: (_context: any, event: {data?: {route: RoutePaths}}) =>
      event?.data?.route === RoutePaths.ACCOUNT_PET
  },
  {
    target: NACStates.validateNonProfitSpeciality,
    cond: (_context: any, event: {data?: {route: RoutePaths}}) =>
      event?.data?.route === RoutePaths.ACCOUNT_NON_PROFIT,
  },
  {
    target: NACStates.validateLawEnforcement,
    cond: (_context: any, event: {data?: {route: RoutePaths}}) =>
      event?.data?.route === RoutePaths.ACCOUNT_LAW_ENFORCEMENT,
  },
  {
    target: NACStates.validateMilitaryAccount,
    cond: (_context: any, event: {data?: {route: RoutePaths}}) =>
      event?.data?.route === RoutePaths.ACCOUNT_MILITARY,
  },
  {
    target: NACStates.validateUniversity,
    cond: (_context: any, event: {data?: {route: RoutePaths}}) =>
      event?.data?.route === RoutePaths.ACCOUNT_VET_TECH,
  },
  {
    target: NACStates.selectTranscationType,
    cond: (_context: any, event: {data?: {route: RoutePaths}}) =>
      event?.data?.route === RoutePaths.SELECT_TRANSACTION_TYPE,
  },
  {
    target: NACStates.enterBuisnessInformation,
    cond: (_context: any, event: {data?: {route: RoutePaths}}) =>
      event?.data?.route === RoutePaths.BUISNESS_INFORMATION,
  },
  {
    target: NACStates.financeInfo,
    cond: (_context: any, event: {data?: {route: RoutePaths}}) =>
      event?.data?.route === RoutePaths.FINANCIAL_INFO,
  },
  {
    target: NACStates.paymentInformation,
    cond: (_context: any, event: {data?: {route: RoutePaths}}) =>
      event?.data?.route === RoutePaths.PAYMENT_INFORMATION,
  },
  {
    target: NACStates.shipToQualifier,
    cond: (_context: any, event: {data?: {route: RoutePaths}}) =>
      event?.data?.route === RoutePaths.SHIP_TO_QUALIFIER,
  },
  {
    target: NACStates.addShipTo,
    cond: (_context: any, event: {data?: {route: RoutePaths}}) =>
      event?.data?.route === RoutePaths.ADD_SHIPTO,
  },
  {
    target: NACStates.contact,
    cond: (_context: any, event: {data?: {route: RoutePaths}}) =>
      event?.data?.route === RoutePaths.CONTACTS,
  },
  {
    target: NACStates.additionalShipTo,
    cond: (_context: any, event: {data?: {route: RoutePaths}}) =>
      event?.data?.route === RoutePaths.ADDITIONAL_SHIPTO,
  },
  {
    target: NACStates.dateSign,
    cond: (_context: any, event: {data?: {route: RoutePaths}}) =>
      event?.data?.route === RoutePaths.DATE_SIGN,
  },
  {
    target: NACStates.review,
    cond: (_context: any, event: {data?: {route: RoutePaths}}) =>
      event?.data?.route === RoutePaths.REVIEW,
  },
  {
    target: NACStates.updateAccount,
    cond: (_context: any, event: {data?: {route: RoutePaths}}) =>
      event?.data?.route === RoutePaths.UPDATE_ACCOUNT,
  },
  {
    target: NACStates.shipToNumber,
    cond: (_context: any, event: {data?: {route: RoutePaths}}) =>
      event?.data?.route === RoutePaths.SHIP_TO_ACCOUNT,
  },
  {
    target: NACStates.howManyShipTo,
    cond: (_context: any, event: {data?: {route: RoutePaths}}) =>
      event?.data?.route === RoutePaths.SHIP_TO_ACCOUNT,
  },
  {
    target: NACStates.transferShipToLocation,
    cond: (_context: any, event: {data?: {route: RoutePaths}}) =>
      event?.data?.route === RoutePaths.SHIP_TO_ACCOUNT,
  },
]
