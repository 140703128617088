import {QuestionsInterface, Options} from "../index"
import {
  formatPhone
} from "./../../components/utils/format"
import {
  required,
  maxLength,
  mustBeEmail,
  mustBePhone,
  mustBeSoldTo,
} from "../../utils/validators"

export interface ShipToQualifier {
  soldTo: string
  firstName: string
  lastName: string
  phone: string
  email: string
  transferDate?: string
  transfer?: TransferOwnership
}

export type TransferOwnership = "NEW_OWNER" | "PARTNERSHIP_BUY_OUT"

export const TRANSFER_OWNERSHIP_QUESTIONS: QuestionsInterface<TransferOwnership>[] =
  [
    {
      QUESTION_TEXT: "What is effective date of the transfer?",
      VALIDATION: [required],
      OPTIONS: [
        {
          componentType: "Input",
          label: "Date of Transfer",
          id: "transferDate",
          name: "transferDate",
          type: "date",
        },
      ],
    },
    {
      QUESTION_TEXT: "Why are you transferring ownership?",
      VALIDATION: [required],
      OPTIONS: [
        {
          componentType: "Radio",
          label: "New Owner",
          id: "new-owner",
          name: "transfer",
          type: "radio",
          value: "NEW_OWNER",
        },
        {
          componentType: "Radio",
          label: "Partnership Buy-Out",
          id: "partnership",
          name: "transfer",
          type: "radio",
          value: "PARTNERSHIP_BUY_OUT",
        },
      ],
    },
  ]

export const NEW_SHIPTO_QUESTIONS: QuestionsInterface<Options>[] = [
  {
    QUESTION_TEXT: "Please provide your Hill’s Sold-To Account number",
    VALIDATION: [required, mustBeSoldTo],

    OPTIONS: [
      {
        label: "Sold-To Account Number",
        name: "soldTo",
        id: "soldTo",
        dataTestId: "sold_to",
        type: "text",
        componentType: "Input",
      },
    ],
  },
  {
    QUESTION_TEXT: "Please provide your Hill’s Sold-To Account number",
    VALIDATION: [mustBeSoldTo],

    OPTIONS: [
      {
        label: "Sold-To Account Number",
        name: "soldTo",
        id: "soldToNotRequired",
        dataTestId: "sold_to",
        type: "text",
        componentType: "Input",
      },
    ],
  },
  {
    QUESTION_TEXT: "What is your contact information?",
    QUESTION_TEXT_HELPER:
      " (We will only use this contact information for communication about your account.)",
    OPTIONS: [
      {
        label: "First Name",
        name: "firstName",
        id: "firstName",
        dataTestId: "first_name",
        type: "text",
        componentType: "Input",
        VALIDATION: [required, maxLength(50)],
      },
      {
        label: "Last Name",
        name: "lastName",
        id: "lastName",
        dataTestId: "last_name",
        type: "text",
        componentType: "Input",
        VALIDATION: [required, maxLength(50)],
      },
      {
        label: "Phone Number",
        name: "phone",
        id: "phone",
        dataTestId: "phone",
        type: "text",
        componentType: "Input",
        VALIDATION: [required, mustBePhone],
        format: formatPhone,
      },
      {
        label: "Email Address",
        name: "email",
        id: "email",
        dataTestId: "email",
        type: "text",
        componentType: "Input",
        VALIDATION: [required, mustBeEmail],
      },
    ],
  },
]
