import {useContext, useEffect} from "react"
import {useNavigate, useLocation} from "react-router-dom"
import {WrapperContainer, Button} from "ui"
import {Form} from "react-final-form"
import Question from "../../utils/Question"
import {nacMachineContext} from "../../../context"
import {NACEvents, NACStates} from "../../../machine/nac-machine.types"
import {RoutePaths, FormButton, Options} from "../../../constants"
import {ADDITIONAL_SHIPTO_QUESTIONS} from "../../../constants/shipto/additional"

const additonalShipTo = () => {
  const {state, send} = useContext(nacMachineContext) || {}
  const location = useLocation()
  const navigate = useNavigate()
  const onSubmit = (values: {additionalShipto: string}) => {
    const isAddNewShipTo = parseInt(values.additionalShipto) === Options.YES
    if (isAddNewShipTo) {
      send(NACEvents.NEXT, {data: {isAddNewShipTo}})
      navigate(RoutePaths.ADD_SHIPTO)
    } else {
      send(NACEvents.NEXT, {data: {isAddNewShipTo}})
      navigate(RoutePaths.DATE_SIGN)
    }
  }
  useEffect(() => {
    if (state?.value !== NACStates.additionalShipTo) {
      send(NACEvents.INIT, {data: {route: location.pathname}})
    }
  }, [])
  return (
    <div className="flex justify-center">
      <div className="w-full sm:w-[520px]">
        <WrapperContainer>
          <Form
            initialValues={{
              ...state?.context?.additionalShipTo,
            }}
            onSubmit={onSubmit}
            render={({handleSubmit, invalid, values}) => (
              <form onSubmit={handleSubmit}>
                <Question
                  questions={ADDITIONAL_SHIPTO_QUESTIONS}
                  values={values}
                />
                <div className="flex justify-end gap-8">
                  <Button
                    variant="text"
                    onClick={() => {
                      send(NACEvents.BACK)
                      navigate(RoutePaths.CONTACTS)
                    }}
                  >
                    {FormButton.BACK}
                  </Button>
                  <Button
                    disabled={invalid}
                    variant="primary"
                    mode="filled"
                    type="submit"
                  >
                    {FormButton.CONTINUE}
                  </Button>
                </div>
              </form>
            )}
          />
        </WrapperContainer>
      </div>
    </div>
  )
}

export default additonalShipTo
