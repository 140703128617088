import {useContext, useEffect} from "react"
import {useNavigate, useLocation, useSearchParams} from "react-router-dom"
import {WrapperContainer, Button, Stepper} from "ui"
import {Form} from "react-final-form"
import Question from "../utils/Question"
import {
  DATESIGN_OFFICER_QUESTIONS,
  DATESIGN_SIGNER_QUESTIONS,
  HEADER,
  NOTE,
} from "../../constants/datesign"
import {nacMachineContext} from "../../context"
import {
  NACEvents,
  NACMachineContext,
  NACStates,
} from "../../machine/nac-machine.types"
import {RoutePaths, FormButton, AccountType} from "../../constants"
import {TransactionType} from "../../constants/select-transaction"
import {ShipToLocation} from "../../constants/shipto/how-many-ship-to"
import {UpdateAccountOptions} from "../../constants/update-account-options"

type Value = {
  firstName: string
  lastName: string
  title: string
  email: string
}

const DateSign = () => {
  const {state, send} = useContext(nacMachineContext) || {}
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const isEditFlow = state?.history?.value === NACStates.review

  const onSubmit = (values: Value) => {
    send(NACEvents.NEXT, {data: {...values}})
    navigate(RoutePaths.REVIEW)
  }

  const showSignerQuestion = [
    AccountType.MILITARY,
    AccountType.NON_PROFIT,
    AccountType.VET_TECH,
  ].includes(Number(state.context.accountType))

  useEffect(() => {
    if (state?.value !== NACStates.dateSign) {
      send(NACEvents.INIT, {data: {route: location.pathname}})
    }
  }, [])

  return (
    <>
      <div className="my-1">
        <Stepper currentStep={5} totalSteps={5} />
        <h2 className="my-4 text-center text-sm font-bold">{HEADER}</h2>
      </div>
      <div className="flex justify-center">
        <div className="w-full sm:w-[520px]">
          <WrapperContainer>
            <div className="mb-10 rounded bg-brand-primary p-4 text-sm text-white">
              <span className="font-bold">{NOTE.title}</span>
              <span className="font-light">{NOTE.description}</span>
            </div>
            <Form
              initialValues={{
                firstName: state.context.dateSign?.firstName,
                lastName: state.context.dateSign?.lastName,
                title: state.context.dateSign?.title,
                email: state.context.dateSign?.email,
              }}
              onSubmit={onSubmit}
              render={({handleSubmit, invalid, values}) => (
                <form onSubmit={handleSubmit}>
                  {showSignerQuestion ? (
                    <Question
                      questions={DATESIGN_SIGNER_QUESTIONS}
                      values={values}
                    />
                  ) : (
                    <Question
                      questions={DATESIGN_OFFICER_QUESTIONS}
                      values={values}
                    />
                  )}

                  <div className="flex justify-end gap-8">
                    <Button
                      variant="text"
                      onClick={() => {
                        const backRoute = getBackRoute(
                          state?.context,
                          searchParams,
                        )
                        send(NACEvents.BACK, {data: state?.history?.value})
                        navigate(backRoute)
                      }}
                    >
                      {FormButton.BACK}
                    </Button>
                    <Button
                      disabled={invalid}
                      variant="primary"
                      mode="filled"
                      type="submit"
                    >
                      {isEditFlow ||
                      state?.history?.event?.type == NACEvents.EDIT
                        ? FormButton.SAVE_AND_CONTINUE
                        : FormButton.REVIEW}
                    </Button>
                  </div>
                </form>
              )}
            />
          </WrapperContainer>
        </div>
      </div>
    </>
  )
}

function getBackRoute(
  context: NACMachineContext,
  searchParams: URLSearchParams,
): RoutePaths {
  if (searchParams.get("edit") === "true") {
    return RoutePaths.REVIEW
  }
  if (context?.addShipTo?.length == 5) {
    return RoutePaths.CONTACTS
  }
  if (
    context?.selectedTransaction == TransactionType.UpdateCustomerAccountProfile
  ) {
    if (context?.updateAccount == UpdateAccountOptions.BUSINESS_INFORMATION) {
      return RoutePaths.BUISNESS_INFORMATION
    } else if (
      [
        UpdateAccountOptions.CLINIC_SHIPPING_LOCATION_CONTACTS,
        UpdateAccountOptions.STORE_SHIPPING_LOCATION_CONTACTS,
        UpdateAccountOptions.UNIVERSITY_SHIPPING_LOCATION_CONTACTS,
      ].includes(context.updateAccount)
    ) {
      return RoutePaths.CONTACTS
    } else if (
      [
        UpdateAccountOptions.CLINIC_SHIPPING_LOCATION,
        UpdateAccountOptions.STORE_SHIPPING_LOCATION,
        UpdateAccountOptions.UNIVERSITY_SHIPPING_LOCATION,
      ].includes(context.updateAccount)
    ) {
      return RoutePaths.ADD_SHIPTO
    } else if (
      context?.updateAccount == UpdateAccountOptions.ORDERING_INFORMATION
    ) {
      return RoutePaths.FINANCIAL_INFO
    } else if (
      context?.updateAccount == UpdateAccountOptions.PAYMENT_PREFERENCE
    ) {
      return RoutePaths.PAYMENT_INFORMATION
    }
  }
  if (context?.accountType == AccountType.VET_TECH) {
    return RoutePaths.CONTACTS
  }
  if (context?.selectedTransaction == TransactionType.TransferOwnership) {
    return context?.howManyShipTo == ShipToLocation.ALL
      ? RoutePaths.HOW_MANY_SHIP_TO_QUESTION
      : RoutePaths.ADDITIONAL_SHIPTO_TRANSFER
  }

  return RoutePaths.ADDITIONAL_SHIPTO
}

export default DateSign
