import {useContext} from "react"
import {nacMachineContext} from "../../../context"
import Option from "../../utils/Option"
import {PHONE_NUMBER_QUESTION} from "../../../constants/shipto/add"
import {TransactionType} from "../../../constants/select-transaction"
import {required} from "../../../utils/validators"
import {QuestionOptionsInterface} from "../../../constants"

const PhoneNumber = () => {
  const {state} = useContext(nacMachineContext) || {}
  const UPDATE = (value : QuestionOptionsInterface) => {
    const validation = value.VALIDATION?.filter((item : unknown) => item !== required)
    value.VALIDATION = validation
    return value
  }
  const isUpdateProfile = state?.context?.selectedTransaction ==
    TransactionType.UpdateCustomerAccountProfile

  return (
    <div className="mb-10 flex flex-col">
      <p className="mb-3 text-gray-700">
        <span className="font-bold">{PHONE_NUMBER_QUESTION.question}</span>
      </p>
      <div className="grid gap-5 md:grid-cols-2">
        <Option option={isUpdateProfile ? 
          UPDATE(PHONE_NUMBER_QUESTION.option) : PHONE_NUMBER_QUESTION.option} />
      </div>
    </div>
  )
}

export default PhoneNumber