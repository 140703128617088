import {required, mustBePhone, mustBeEmail} from "../../utils/validators"
import {formatPhone} from "../../components/utils/format"
import {AccountType} from "../"
import {Input, Checkbox} from "ui"

export const PRIMARY_CONTACT_TITLE = {
  lablel: "Please designate a primary delivery contact",
  helperText: "(Required)",
}
export const UPDATE_CONTACT_TITLE = "Contact Information and Permissions"

export const ALERT_CONFIRM_LABEL = "Continue"

export const DELETE_CONTACT = "Delete Contact"

export const DELETE_CONTACT_MESSAGE =
  "Are you sure you would like to delete this contact? This action is permanent and cannot be undone."

export const INITIAL_VALUE = {
  firstName: "",
  lastName: "",
  title: "",
  email: "",
  phone: "",
  sms: "",
  notifications: false,
  viewDeliveryStatus: false,
  notifyRouteDelay: false,
  sendFeedProgramDetails: false,
  sendClinicDetails: false,
  marketingCommunications: false,
}

export const CONTACT_INFORMATION_OPTIONS = [
  {
    label: "First Name",
    name: "firstName",
    id: "firstName",
    htmlFor: "firstName",
    type: "text",
    component: Input,
    validators: [required],
  },
  {
    label: "Last Name",
    name: "lastName",
    id: "lastName",
    htmlFor: "lastName",
    type: "text",
    component: Input,
    validators: [required],
  },
  {
    label: "Title",
    name: "title",
    id: "title",
    htmlFor: "title",
    type: "text",
    component: Input,
    validators: [required],
  },
  {
    label: "Email Address",
    name: "email",
    id: "email",
    htmlFor: "email",
    type: "email",
    component: Input,
    validators: [required, mustBeEmail],
  },
  {
    label: "Phone Number",
    name: "phone",
    id: "phone",
    htmlFor: "phone",
    type: "text",
    component: Input,
    validators: [required, mustBePhone],
    format: formatPhone,
  },
  {
    label: "Send this contact alerts and notifications via SMS",
    name: "notifications",
    type: "checkbox",
    component: Checkbox,
  },
]

export const CONTACT_INFORMATION_SMS = {
  label: "Mobile Phone Number",
  name: "sms",
  type: "text",
  component: Input,
  validators: [required, mustBePhone],
  format: formatPhone,
}

export const PRIVACY_POLICY = {
  name: "agreement",
  value: "Privacy Policy",
  label: "Privacy Policy",
}

export const MULTIPLE_AGREEMENT_POLICY = {
  name: "multipleAgreement",
  value: "Multiple Agreement",
  label: "Multiple Agreement",
}

export const CONFIGURE_PERMISSIONS_LABEL = "Configure permissions"

export const CONFIGURE_PERMISSIONS_OPTIONS = [
  {
    label: "Able to view delivery status",
    name: "viewDeliveryStatus",
    type: "checkbox",
    component: Checkbox,
  },
  {
    label: "Notify of a route delay",
    name: "notifyRouteDelay",
    type: "checkbox",
    component: Checkbox,
  },
  {
    label: "Send details about Hill’s Pro Staff Feeding program",
    name: "sendFeedProgramDetails",
    type: "checkbox",
    component: Checkbox,
    helpertext: "(Order for your own pet)",
    ignoreAccounts: [AccountType.LAW_ENFORCEMENT, AccountType.NON_PROFIT, AccountType.MILITARY],
  },
  {
    label: "Send details about Hill’s Retail Order",
    name: "sendClinicDetails",
    type: "checkbox",
    component: Checkbox,
    helpertext: "(Hill’s clinic ordering program)",
    ignoreAccounts: [AccountType.LAW_ENFORCEMENT, AccountType.VET_TECH],
  },
  {
    label: "Interested in receiving marketing communications",
    name: "marketingCommunications",
    type: "checkbox",
    component: Checkbox,
  },
]

export const HEADER =
  "Use the table below to add, edit or remove contacts for this ship-to location."

export const TableHeaders = {
  contactInformation: "Contact Information",
  deliveryStatus: "Delivery Status",
  routeDelays: "Route Delays",
  services: "Access to Hill’s Tools & Services",
  comm: "Send Hill’s Marketing Comms",
  primary: "Make Primary Delivery Contact",
  editDelete: "Edit or Remove",
}

export const NO_CONTACTS = {
  message: "No additional contacts have been added to this ship-to location.",
  helperText: "(Use the link below to add a new contact) & Contact Icon",
}

export const POLICY = {
  message:
    "By checking this box, I confirm that the contacts above would like to receive service communications from Hill’s as governed by our",
  multipleComtacts:
    "By checking this box, I confirm that the indicated contacts above would like to receive communication from Hill’s family of brands about products, offers and programs and that they have read and agree to our ",
  url: "https://www.hillspet.com/privacy-policy",
}

export const ADD_NEW_CONTACT = "Add New Contact"

export const TABLE_HEADERS = [
  TableHeaders.contactInformation,
  TableHeaders.deliveryStatus,
  TableHeaders.routeDelays,
  TableHeaders.services,
  TableHeaders.comm,
  TableHeaders.primary,
  TableHeaders.editDelete,
]

export const SMS = {
  question: "Contact via SMS ? ",
  mobile: "Mobile",
}

export const ACCESS_STATUS = {
  HRO: "HRO",
  VIP: "VIP",
}

export const AGREEMENT_TERMS = {
  header: "Agreement Terms",
  message:
    "In order to proceed, you must either agree to the terms by checking the box(es), or change the selected communications preferences.",
}

export const Options = {
  CLOSE: "Close",
  YES: "Yes",
  NO: "No",
}

export const TERMS = {
  option: {
    label: AGREEMENT_TERMS,
    name: "contactsAgreement",
    id: "contactsAgreement",
    dataTestId: "contactsAgreement",
    type: "checkbox",
    componentType: "Checkbox",
  },
}

export const MULTIPLE_CONTACT_TERMS = {
  option: {
    label: AGREEMENT_TERMS.message,
    name: "multipleAgreement",
    id: "multipleAgreement",
    dataTestId: "multipleAgreement",
    type: "checkbox",
    componentType: "Checkbox",
  },
}
