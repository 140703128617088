import React, {Dispatch, SetStateAction, useContext} from "react"
import {Form, Field} from "react-final-form"
import {AlertDialog} from "ui"
import {composeValidators} from "../../utils/validators"
import {
  UPDATE_CONTACT_TITLE,
  ALERT_CONFIRM_LABEL,
  CONTACT_INFORMATION_OPTIONS,
  CONFIGURE_PERMISSIONS_LABEL,
  CONFIGURE_PERMISSIONS_OPTIONS,
  CONTACT_INFORMATION_SMS,
} from "../../constants/contacts"
import {ContactDetails} from "../../types"
import {nacMachineContext} from "../../context"

interface Props {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  addContact: (values: ContactDetails) => void
  primaryContactId: number
  contacts: ContactDetails[]
  selectedContactId: string
  updateContact: (updatedContacts: ContactDetails[]) => void
}

const UpdateContactAlert = ({
  open,
  setOpen,
  addContact,
  contacts,
  selectedContactId,
  updateContact,
  primaryContactId,
}: Props) => {
  const selectedContact = contacts.find(
    contact => contact.id == Number(selectedContactId),
  )
  const {state} = useContext(nacMachineContext) || {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (values: any) => {
    if (!selectedContactId) {
      addContact({...values})
    } else {
      const updatedContacts = contacts.map(contact =>
        contact.id === Number(selectedContactId)
          ? {...values, id: Number(selectedContactId)}
          : contact,
      )
      updateContact(updatedContacts)
    }
  }

  const showSMSField = () => {
    const {name: optionName, validators, ...rest} = CONTACT_INFORMATION_SMS
    return (
      <Field
        name={`${optionName}`}
        id={`${optionName}`}
        htmlFor={`${optionName}`}
        validate={
          Array.isArray(validators)
            ? composeValidators(...validators)
            : undefined
        }
        {...rest}
      />
    )
  }

  const initialValues = (
    selectedContact: ContactDetails | undefined,
    primaryContactId: number,
  ) => {
    if (selectedContact?.id == primaryContactId) {
      return {...selectedContact, viewDeliveryStatus: true}
    }
    return {...selectedContact}
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues(selectedContact, primaryContactId)}
      render={({handleSubmit, values, form: {reset}, invalid}) => (
        <form onSubmit={handleSubmit}>
          <AlertDialog
            open={open}
            setOpen={setOpen}
            title={UPDATE_CONTACT_TITLE}
            size="lg"
            labelConfirm={ALERT_CONFIRM_LABEL}
            handleConfirm={() => {
              onSubmit(values)
              reset()
              setOpen(!open)
            }}
            disableSubmit={invalid}
          >
            <div className="mb-2 grid gap-12 pt-2 md:grid-cols-2">
              <div className="col-span-1">
                {CONTACT_INFORMATION_OPTIONS.filter(
                  option => option.name !== "notifications",
                ).map((option, index) => {
                  const {validators, ...rest} = option

                  return (
                    <Field
                      validate={
                        Array.isArray(validators)
                          ? composeValidators(...validators)
                          : undefined
                      }
                      {...rest}
                      key={index}
                    />
                  )
                })}

                {values?.notifications ? showSMSField() : <></>}
              </div>
              <div className="col-span-1">
                <h5 className="mb-1 mt-2 text-sm font-semibold uppercase">
                  {CONFIGURE_PERMISSIONS_LABEL}
                </h5>
                {CONFIGURE_PERMISSIONS_OPTIONS.map((option, index) => {
                  const {ignoreAccounts} = option
                  if (
                    !ignoreAccounts?.includes(
                      Number(state?.context?.accountType),
                    )
                  ) {
                    if (
                      Number(selectedContactId) == primaryContactId &&
                      option.name == "viewDeliveryStatus"
                    ) {
                      return <Field {...option} key={index} disabled={true} />
                    }
                    return <Field {...option} key={index} />
                  }
                })}
              </div>
            </div>
          </AlertDialog>
        </form>
      )}
    />
  )
}

export default UpdateContactAlert
