import {createMachine} from "xstate"
import {assign} from "xstate/lib/actions"
import {AccountType, Options} from "../constants"
import {TransactionType} from "../constants/select-transaction"
import {ShipToLocation} from "../constants/shipto/how-many-ship-to"
import {UpdateAccountOptions} from "../constants/update-account-options"
import {
  NACMachineEvents,
  NACMachineContext,
  NACMachineStates,
  NACStates,
  NACEvents,
} from "./nac-machine.types"
import {RESET} from "./reset"

export const newCustomerAccountMachine = createMachine<
  NACMachineContext,
  NACMachineEvents,
  NACMachineStates
>({
  id: "nac-machine",
  initial: NACStates.init,
  context: {} as NACMachineContext,
  schema: {
    events: {} as
      | {type: NACEvents.INIT; data?: string}
      | {type: NACEvents.BACK}
      | {type: NACEvents.NEXT; data?: string}
      | {type: NACEvents.EDIT; data?: string}
      | {type: NACEvents.CLICK; data?: string},
  },
  states: {
    [NACStates.init]: {
      on: {
        INIT: RESET,
        NEXT: {
          target: NACStates.selectLocation,
        },
      },
    },
    [NACStates.selectLocation]: {
      on: {
        INIT: RESET,
        BACK: {
          target: NACStates.init,
        },
        NEXT: {
          target: NACStates.selectAccountType,
          actions: assign({
            location: (_context, event) => event.data,
          }),
        },
      },
    },

    [NACStates.selectAccountType]: {
      on: {
        INIT: RESET,
        BACK: {
          target: NACStates.selectLocation,
          actions: assign(context => {
            return {
              ...context,
            }
          }),
        },
        NEXT: [
          {
            actions: assign({accountType: (_context, event) => event.data}),
            target: NACStates.validatePetFoodRetailer,
            cond: (context, event) => {
              return [
                AccountType.VETERINARY,
                AccountType.MOBILE_VETERINARY,
                AccountType.PET_STORE,
              ].includes(Number(event.data))
            },
          },
          {
            actions: assign({accountType: (_context, event) => event.data}),
            target: NACStates.validateNonProfitSpeciality,
            cond: (context, event) => {
              return Number(event.data) === AccountType.NON_PROFIT
            },
          },
          {
            actions: assign({accountType: (_context, event) => event.data}),
            target: NACStates.validateMilitaryAccount,
            cond: (context, event) => {
              return Number(event.data) === AccountType.MILITARY
            },
          },
          {
            actions: assign({accountType: (_context, event) => event.data}),
            target: NACStates.validateLawEnforcement,
            cond: (context, event) => {
              return Number(event.data) === AccountType.LAW_ENFORCEMENT
            },
          },
          {
            actions: assign({accountType: (_context, event) => event.data}),
            target: NACStates.validateUniversity,
            cond: (context, event) => {
              return Number(event.data) === AccountType.VET_TECH
            },
          },
        ],
      },
    },
    [NACStates.validatePetFoodRetailer]: {
      on: {
        INIT: RESET,
        BACK: {
          target: NACStates.selectAccountType,
          actions: assign(context => {
            return {
              ...context,
            }
          }),
        },
        NEXT: [
          {
            actions: assign({validatePet: (_context, event) => event.data}),
            target: NACStates.validatePetAllow,
          },
        ],
      },
    },
    [NACStates.validatePetAllow]: {
      on: {
        INIT: RESET,
        BACK: {
          target: NACStates.validatePetFoodRetailer,
          actions: assign(context => {
            return {
              ...context,
            }
          }),
        },
        NEXT: [
          {
            actions: assign({validatePet: (_context, event) => event.data}),
            target: NACStates.validateHomeImprovementStore,
          },
        ],
      },
    },
    [NACStates.validateHomeImprovementStore]: {
      on: {
        INIT: RESET,
        BACK: {
          target: NACStates.validatePetAllow,
          actions: assign(context => {
            return {
              ...context,
            }
          }),
        },
        NEXT: [
          {
            actions: assign({validatePet: (_context, event) => event.data}),
            target: NACStates.selectTranscationType,
          },
        ],
      },
    },
    [NACStates.validateNonProfitSpeciality]: {
      on: {
        INIT: RESET,
        BACK: {
          target: NACStates.selectAccountType,
          actions: assign(context => {
            return {
              ...context,
            }
          }),
        },
        NEXT: [
          {
            actions: assign({
              validateNonProfit: (_context, event) => event.data,
            }),
            target: NACStates.validateSpeciality,
          },
        ],
      },
    },
    [NACStates.validateLawEnforcement]: {
      on: {
        INIT: RESET,
        BACK: {
          target: NACStates.selectAccountType,
          actions: assign(context => {
            return {
              ...context,
            }
          }),
        },
        NEXT: [
          {
            actions: assign({
              validateLaw: (_context, event) => event.data,
            }),
            target: NACStates.selectTranscationType,
          },
        ],
      },
    },
    [NACStates.validateMilitaryAccount]: {
      on: {
        INIT: RESET,
        BACK: {
          target: NACStates.selectAccountType,
          actions: assign(context => {
            return {
              ...context,
            }
          }),
        },
        NEXT: [
          {
            actions: assign({
              validateMilitary: (_context, event) => event.data,
            }),
            target: NACStates.selectTranscationType,
          },
        ],
      },
    },
    [NACStates.validateUniversity]: {
      on: {
        INIT: RESET,
        BACK: {
          target: NACStates.selectAccountType,
          actions: assign(context => {
            return {
              ...context,
            }
          }),
        },
        NEXT: [
          {
            actions: assign({
              validateUniversity: (_context, event) => event.data,
            }),
            target: NACStates.selectTranscationType,
          },
        ],
      },
    },
    [NACStates.selectTranscationType]: {
      on: {
        INIT: RESET,
        BACK: [
          {
            target: NACStates.validatePetFoodRetailer,
            cond: _context => {
              return [
                AccountType.VETERINARY,
                AccountType.MOBILE_VETERINARY,
                AccountType.PET_STORE,
              ].includes(Number(_context.accountType))
            },
          },
          {
            target: NACStates.validateSpeciality,
            cond: _context => {
              return Number(_context.accountType) === AccountType.NON_PROFIT
            },
          },
          {
            target: NACStates.validateMilitaryAccount,
            cond: _context => {
              return Number(_context.accountType) === AccountType.MILITARY
            },
          },
          {
            target: NACStates.validateLawEnforcement,
            cond: _context => {
              return (
                Number(_context.accountType) === AccountType.LAW_ENFORCEMENT
              )
            },
          },
          {
            target: NACStates.validateUniversity,
            cond: _context => {
              return Number(_context.accountType) === AccountType.VET_TECH
            },
          },
        ],
        NEXT: [
          {
            target: NACStates.shipToQualifier,
            actions: assign({
              selectedTransaction: (_context, event) => event.data,
            }),
            cond: (_context, event) => {
              return [
                TransactionType.AddNewShipToLocation,
                TransactionType.TransferOwnership,
                TransactionType.UpdateCustomerAccountProfile,
              ].includes(Number(event.data))
            },
          },

          {
            target: NACStates.enterBuisnessInformation,
            actions: assign({
              selectedTransaction: (_context, event) => event.data,
            }),
          },
        ],
      },
    },
    [NACStates.enterBuisnessInformation]: {
      on: {
        INIT: RESET,
        BACK: [
          {
            target: NACStates.review,

            cond: (_context, event) => {
              const {history} = event.data
              return history === NACStates.review
            },
          },
          {
            actions: assign(context => {
              return {
                ...context,
              }
            }),
            target: NACStates.shipToQualifier,
            cond: _context => {
              return (
                Number(_context.selectedTransaction) ===
                TransactionType.TransferOwnership
              )
            },
          },

          {
            actions: assign(context => {
              return {
                ...context,
              }
            }),
            target: NACStates.selectTranscationType,
          },
        ],
        NEXT: [
          {
            target: NACStates.updateAccount,
            actions: assign({
              businessInformation: (_context, event) => event.data,
            }),
            cond: (_context, event) => {
              return (
                Number(_context.selectedTransaction) ===
                  TransactionType.UpdateCustomerAccountProfile &&
                event?.data?.update == Options.YES
              )
            },
          },
          {
            target: NACStates.dateSign,
            actions: assign({
              businessInformation: (_context, event) => event.data,
            }),
            cond: (_context, event) => {
              return (
                Number(_context.selectedTransaction) ===
                  TransactionType.UpdateCustomerAccountProfile &&
                event?.data?.update == Options.NO
              )
            },
          },
          {
            target: NACStates.review,
            actions: assign({
              businessInformation: (_context, event) => event.data,
            }),
            cond: (_context, event) => {
              const {history} = event.data
              return history === NACStates.review
            },
          },

          {
            target: NACStates.financeInfo,
            actions: assign({
              businessInformation: (_context, event) => event.data,
            }),
            cond: _context => {
              return Number(_context.accountType) !== AccountType.VET_TECH
            },
          },
          {
            target: NACStates.addShipTo,
            actions: assign({
              businessInformation: (_context, event) => event.data,
            }),
            cond: _context => {
              return Number(_context.accountType) === AccountType.VET_TECH
            },
          },
        ],
      },
    },
    [NACStates.validateSpeciality]: {
      on: {
        INIT: RESET,
        BACK: {
          target: NACStates.validateNonProfitSpeciality,
          actions: assign(context => {
            return {
              ...context,
            }
          }),
        },
        NEXT: [
          {
            actions: assign({
              validateSpeciality: (_context, event) => event.data,
            }),
            target: NACStates.selectTranscationType,
          },
        ],
      },
    },
    [NACStates.financeInfo]: {
      on: {
        INIT: RESET,
        BACK: [
          {
            target: NACStates.review,

            cond: (_context, event) => {
              const {history} = event.data
              return history === NACStates.review
            },
          },
          {
            target: NACStates.updateAccount,
            cond: _context => {
              return (
                Number(_context.selectedTransaction) ===
                TransactionType.UpdateCustomerAccountProfile
              )
            },
          },
          {
            actions: assign(context => {
              return {
                ...context,
              }
            }),
            target: NACStates.enterBuisnessInformation,
          },
        ],
        NEXT: [
          {
            target: NACStates.addShipTo,
            actions: assign({
              financialInformation: (_context, event) => event.data,
            }),
            cond: _context => {
              return [
                AccountType.LAW_ENFORCEMENT,
                AccountType.MILITARY,
              ].includes(Number(_context.accountType))
            },
          },
          {
            target: NACStates.review,
            actions: assign({
              financialInformation: (_context, event) => event.data,
            }),
            cond: (_context, event) => {
              const {history} = event.data
              return history === NACStates.review
            },
          },
          {
            target: NACStates.dateSign,
            actions: assign({
              financialInformation: (_context, event) => event.data,
            }),
            cond: (_context, event) => {
              return (
                Number(_context.selectedTransaction) ===
                  TransactionType.UpdateCustomerAccountProfile &&
                Number(event.data.update) === Options.NO
              )
            },
          },
          {
            target: NACStates.updateAccount,
            actions: assign({
              financialInformation: (_context, event) => event.data,
            }),
            cond: (_context, event) => {
              return (
                Number(_context.selectedTransaction) ===
                  TransactionType.UpdateCustomerAccountProfile &&
                Number(event.data.update) === Options.YES
              )
            },
          },
          {
            target: NACStates.paymentInformation,
            actions: assign({
              financialInformation: (_context, event) => event.data,
            }),
          },
        ],
      },
    },
    [NACStates.addShipTo]: {
      on: {
        INIT: RESET,

        BACK: [
          {
            target: NACStates.review,

            cond: (_context, event) => {
              const {history} = event.data
              return history === NACStates.review
            },
          },
          {
            target: NACStates.shipToNumber,
            cond: _context => {
              return [
                TransactionType.UpdateCustomerAccountProfile,
                TransactionType.TransferOwnership,
              ].includes(Number(_context.selectedTransaction))
            },
          },
          {
            target: NACStates.shipToQualifier,
            actions: assign(context => {
              return {
                ...context,
              }
            }),
            cond: _context => {
              return (
                Number(_context.selectedTransaction) ==
                TransactionType.AddNewShipToLocation
              )
            },
          },
          {
            target: NACStates.enterBuisnessInformation,
            actions: assign(context => {
              return {
                ...context,
              }
            }),
            cond: _context => {
              return _context.accountType == AccountType.VET_TECH
            },
          },
          {
            target: NACStates.financeInfo,
            actions: assign(context => {
              return {
                ...context,
              }
            }),
            cond: _context => {
              return [
                AccountType.LAW_ENFORCEMENT,
                AccountType.MILITARY,
              ].includes(Number(_context.accountType))
            },
          },

          {
            target: NACStates.paymentInformation,
            actions: assign(context => {
              return {
                ...context,
              }
            }),
          },
        ],
        NEXT: [
          {
            target: NACStates.dateSign,
            actions: assign({
              addShipTo: (_context, event) => {
                const {history, ...data} = event.data
                if (
                  history === NACStates.review ||
                  history === NACStates.dateSign
                ) {
                  const updatedIndex = data.updateIndex
                    ? data.updateIndex
                    : _context.addShipTo.length - 1
                  _context.addShipTo[updatedIndex] = data
                  return [..._context.addShipTo]
                }
                _context.addShipTo[data.updateIndex] = {
                  ..._context.addShipTo[data.updateIndex],
                  ...data,
                }
                return [..._context.addShipTo]
              },
            }),
            cond: _context => {
              return (
                Number(_context.selectedTransaction) ==
                TransactionType.UpdateCustomerAccountProfile
              )
            },
          },
          {
            target: NACStates.updateAccount,
            actions: assign({
              addShipTo: (_context, event) => {
                const {...data} = event.data
                _context.addShipTo[data.updateIndex] = {
                  ..._context.addShipTo[data.updateIndex],
                  ...data,
                }
                return [..._context.addShipTo]
              },
            }),
            cond: (_context, event) => {
              return (
                Number(_context.selectedTransaction) ===
                  TransactionType.UpdateCustomerAccountProfile &&
                Number(event.data.update) === Options.YES
              )
            },
          },
          {
            actions: assign({
              addShipTo: (_context, event) => {
                const {history, ...data} = event.data
                if (
                  [
                    NACStates.shipToQualifier,
                    NACStates.enterBuisnessInformation,
                    NACStates.paymentInformation,
                    NACStates.financeInfo,
                  ].includes(history)
                ) {
                  if (_context?.addShipTo?.length > 0) {
                    _context.addShipTo[_context.addShipTo.length - 1] = {
                      ..._context.addShipTo[_context.addShipTo.length - 1],
                      ...data,
                    }
                    return _context.addShipTo
                  }
                  return [data]
                }
                if (history === NACStates.review) {
                  _context.addShipTo[data.updateIndex] = {
                    ..._context.addShipTo[data.updateIndex],
                    ...data,
                  }

                  return [..._context.addShipTo]
                }
                if (history === NACStates.shipToNumber) {
                  _context.addShipTo[data.updateIndex] = {
                    ..._context.addShipTo[data.updateIndex],
                    ...data,
                  }
                  return [..._context.addShipTo]
                }
                if (history === "contact") {
                  const updatedIndex = data.updateIndex
                    ? data.updateIndex
                    : _context.addShipTo.length - 1
                  _context.addShipTo[updatedIndex] = data
                  return [..._context.addShipTo]
                }
                return [..._context.addShipTo, data]
              },
            }),
            target: NACStates.contact,
          },
        ],
      },
    },
    [NACStates.contact]: {
      on: {
        INIT: RESET,
        BACK: [
          {
            target: NACStates.updateAccount,

            cond: _context => {
              return (
                Number(_context.selectedTransaction) ===
                  TransactionType.UpdateCustomerAccountProfile &&
                [
                  UpdateAccountOptions.CLINIC_SHIPPING_LOCATION_CONTACTS,
                  UpdateAccountOptions.STORE_SHIPPING_LOCATION_CONTACTS,
                  UpdateAccountOptions.UNIVERSITY_SHIPPING_LOCATION_CONTACTS,
                ].includes(_context.updateAccount)
              )
            },
          },
          {
            target: NACStates.addShipTo,
          },
        ],
        NEXT: [
          {
            target: NACStates.updateAccount,
            actions: assign({
              addShipTo: (_context, event) => {
                const {updateIndex, ...data} = event.data
                const initialData = _context.addShipTo[updateIndex]
                _context.addShipTo[updateIndex] = {
                  ...initialData,
                  contactInformation: data,
                }
                return [..._context.addShipTo]
              },
            }),
            cond: (_context, event) => {
              return (
                Number(_context.selectedTransaction) ===
                  TransactionType.UpdateCustomerAccountProfile &&
                event?.data?.update == Options.YES
              )
            },
          },
          {
            target: NACStates.dateSign,
            actions: assign({
              addShipTo: (_context, event) => {
                const {updateIndex, ...data} = event.data
                const initialData = _context.addShipTo[updateIndex]
                _context.addShipTo[updateIndex] = {
                  ...initialData,
                  contactInformation: data,
                }
                return [..._context.addShipTo]
              },
            }),
            cond: (_context, event) => {
              return (
                (Number(_context.selectedTransaction) ===
                  TransactionType.UpdateCustomerAccountProfile &&
                  event?.data?.update == Options.NO) ||
                _context.accountType == AccountType.VET_TECH
              )
            },
          },
          {
            target: NACStates.transferShipToLocation,
            actions: assign({
              addShipTo: (_context, event) => {
                const {updateIndex, ...data} = event.data
                const initialData = _context.addShipTo[updateIndex]
                _context.addShipTo[updateIndex] = {
                  ...initialData,
                  contactInformation: data,
                }
                return [..._context.addShipTo]
              },
            }),
            cond: _context => {
              return (
                Number(_context.selectedTransaction) ===
                TransactionType.TransferOwnership
              )
            },
          },
          {
            target: NACStates.review,
            actions: assign({
              addShipTo: (_context, event) => {
                const {updateIndex, ...data} = event.data

                const initialData = _context.addShipTo[updateIndex]
                _context.addShipTo[updateIndex] = {
                  ...initialData,
                  contactInformation: data,
                }
                return [..._context.addShipTo]
              },
            }),
            cond: (_context, event) => {
              const {paramsId} = event.data
              return paramsId
            },
          },
          {
            target: NACStates.review,
            actions: assign({
              addShipTo: (_context, event) => {
                const {updateIndex, ...data} = event.data

                const initialData = _context.addShipTo[updateIndex]
                _context.addShipTo[updateIndex] = {
                  ...initialData,
                  contactInformation: data,
                }
                return [..._context.addShipTo]
              },
            }),
            cond: _context => {
              return _context.addShipTo.length == 5
            },
          },
          {
            target: NACStates.additionalShipTo,
            actions: assign({
              addShipTo: (_context, event) => {
                const {updateIndex, ...data} = event.data

                const initialData = _context.addShipTo[updateIndex]
                _context.addShipTo[updateIndex] = {
                  ...initialData,
                  contactInformation: data,
                }
                return [..._context.addShipTo]
              },
            }),
          },
        ],
      },
    },
    [NACStates.shipToQualifier]: {
      on: {
        INIT: RESET,
        BACK: {
          target: NACStates.selectTranscationType,
        },
        NEXT: [
          {
            actions: assign({
              shipToQualifier: (_context, event) => event.data,
            }),
            target: NACStates.enterBuisnessInformation,
            cond: _context => {
              return (
                Number(_context.selectedTransaction) ===
                TransactionType.TransferOwnership
              )
            },
          },
          {
            actions: assign({
              shipToQualifier: (_context, event) => event.data,
            }),
            target: NACStates.updateAccount,
            cond: _context => {
              return (
                Number(_context.selectedTransaction) ===
                TransactionType.UpdateCustomerAccountProfile
              )
            },
          },
          {
            actions: assign({
              shipToQualifier: (_context, event) => event.data,
            }),
            target: NACStates.addShipTo,
          },
        ],
      },
    },
    [NACStates.paymentInformation]: {
      on: {
        INIT: RESET,
        BACK: [
          {
            target: NACStates.review,

            cond: (_context, event) => {
              const {history} = event.data
              return history === NACStates.review
            },
          },
          {
            target: NACStates.financeInfo,
          },
        ],
        NEXT: [
          {
            target: NACStates.review,
            actions: assign({
              paymentInformation: (_context, event) => event.data,
            }),
            cond: (_context, event) => {
              const {history} = event.data
              return history === NACStates.review
            },
          },
          {
            target: NACStates.shipToNumber,
            actions: assign({
              paymentInformation: (_context, event) => event.data,
            }),
            cond: _context => {
              return (
                Number(_context.selectedTransaction) ===
                TransactionType.TransferOwnership
              )
            },
          },
          {
            target: NACStates.dateSign,
            actions: assign({
              paymentInformation: (_context, event) => event.data,
            }),
            cond: (_context, event) => {
              return (
                Number(_context.selectedTransaction) ===
                  TransactionType.UpdateCustomerAccountProfile &&
                Number(event.data.update) === Options.NO
              )
            },
          },
          {
            target: NACStates.updateAccount,
            actions: assign({
              paymentInformation: (_context, event) => event.data,
            }),
            cond: (_context, event) => {
              return (
                Number(_context.selectedTransaction) ===
                  TransactionType.UpdateCustomerAccountProfile &&
                Number(event.data.update) === Options.YES
              )
            },
          },

          {
            target: NACStates.addShipTo,
            actions: assign({
              paymentInformation: (_context, event) => event.data,
            }),
          },
        ],
      },
    },
    [NACStates.additionalShipTo]: {
      on: {
        INIT: RESET,
        BACK: {
          target: NACStates.contact,
        },
        NEXT: [
          {
            target: NACStates.addShipTo,
            cond: (_context, event) => event.data.isAddNewShipTo,
          },
          {
            target: NACStates.dateSign,
            cond: (_context, event) => !event.data.isAddNewShipTo,
          },
        ],
      },
    },
    [NACStates.dateSign]: {
      on: {
        INIT: RESET,
        BACK: [
          {
            target: NACStates.enterBuisnessInformation,
            cond: _context =>
              _context.selectedTransaction ==
                TransactionType.UpdateCustomerAccountProfile &&
              _context.updateAccount ==
                UpdateAccountOptions.BUSINESS_INFORMATION,
          },
          {
            target: NACStates.financeInfo,
            cond: _context =>
              _context.selectedTransaction ==
                TransactionType.UpdateCustomerAccountProfile &&
              _context.updateAccount ==
                UpdateAccountOptions.ORDERING_INFORMATION,
          },
          {
            target: NACStates.contact,
            cond: _context =>
              _context.selectedTransaction ==
                TransactionType.UpdateCustomerAccountProfile &&
              _context.updateAccount ==
                UpdateAccountOptions.CLINIC_SHIPPING_LOCATION_CONTACTS,
          },
          {
            target: NACStates.howManyShipTo,
            cond: _context =>
              _context.selectedTransaction ==
                TransactionType.TransferOwnership &&
              _context.howManyShipTo == ShipToLocation.ALL,
          },
          {
            target: NACStates.transferShipToLocation,
            cond: _context =>
              Number(_context.selectedTransaction) ===
                TransactionType.TransferOwnership &&
              _context.howManyShipTo == ShipToLocation.SPECIFIC,
          },
          {
            target: NACStates.addShipTo,
            cond: _context =>
              Number(_context.selectedTransaction) ==
                TransactionType.UpdateCustomerAccountProfile &&
              [
                UpdateAccountOptions.CLINIC_SHIPPING_LOCATION,
                UpdateAccountOptions.STORE_SHIPPING_LOCATION,
                UpdateAccountOptions.UNIVERSITY_SHIPPING_LOCATION,
              ].includes(_context.updateAccount),
          },
          {
            target: NACStates.contact,
            cond: _context =>
              Number(_context.selectedTransaction) ===
                TransactionType.UpdateCustomerAccountProfile &&
              [
                UpdateAccountOptions.CLINIC_SHIPPING_LOCATION_CONTACTS,
                UpdateAccountOptions.STORE_SHIPPING_LOCATION_CONTACTS,
                UpdateAccountOptions.UNIVERSITY_SHIPPING_LOCATION_CONTACTS,
              ].includes(_context.updateAccount),
          },
          {
            target: NACStates.contact,
            cond: _context => _context.addShipTo.length == 5,
          },
          {
            target: NACStates.additionalShipTo,
          },
        ],
        NEXT: {
          target: NACStates.review,
          actions: assign({
            dateSign: (_context, event) => event.data,
          }),
        },
      },
    },
    [NACStates.review]: {
      on: {
        INIT: RESET,
        BACK: {
          target: NACStates.dateSign,
        },
        EDIT: [
          {
            target: NACStates.enterBuisnessInformation,
            cond: (_context, event) => event.data === "businessInfo",
          },
          {
            target: NACStates.financeInfo,
            cond: (_context, event) => event.data === "orderInfo",
          },
          {
            target: NACStates.paymentInformation,
            cond: (_context, event) => event.data === "paymentInfo",
          },
          {
            target: NACStates.addShipTo,
            cond: (_context, event) => event.data === "shippingInfo",
          },
          {
            target: NACStates.contact,
            cond: (_context, event) => event.data === "shippingContact",
          },
          {
            target: NACStates.dateSign,
            cond: (_context, event) => event.data === "dateSignInfo",
          },
        ],
        NEXT: {
          target: NACStates.pdfSign,
          actions: assign({
            review: (_context, event) => event.data,
          }),
        },
        CLICK: {
          actions: assign({
            custNotes: (_context, event) => {
              const {data: data} = event
              return data
            }
          }),
        }
      },
    },
    [NACStates.howManyShipTo]: {
      on: {
        INIT: RESET,
        BACK: {
          target: NACStates.paymentInformation,
        },
        NEXT: [
          {
            target: NACStates.shipToNumber,
            actions: assign({
              howManyShipTo: (_context, event) => {
                return event.data
              },
            }),
            cond: (_context, event) => {
              return event.data == ShipToLocation.SPECIFIC
            },
          },
          {
            target: NACStates.dateSign,
            actions: assign({
              howManyShipTo: (_context, event) => {
                return event.data
              },
            }),
          },
        ],
      },
    },
    [NACStates.shipToNumber]: {
      on: {
        INIT: RESET,
        BACK: [
          {
            target: NACStates.updateAccount,
            cond: _context => {
              return (
                _context.selectedTransaction ==
                TransactionType.UpdateCustomerAccountProfile
              )
            },
            actions: assign({
              addShipTo: _context => {
                return _context.addShipTo.filter(shipto => {
                  return shipto.location !== undefined
                })
              },
            }),
          },
          {
            target: NACStates.paymentInformation,
            actions: assign({
              addShipTo: _context => {
                return _context.addShipTo.filter(shipto => {
                  return shipto.location !== undefined
                })
              },
            }),
          },
        ],
        NEXT: [
          {
            target: NACStates.contact,
            actions: assign({
              addShipTo: (_context, event) => {
                if (!_context.addShipTo) {
                  return [event.data]
                } else {
                  const findIndex = _context.addShipTo
                    ? _context.addShipTo.findIndex(
                        shipTo =>
                          shipTo.shipToAccountNumber ==
                          event.data.shipToAccountNumber,
                      )
                    : 0

                  //To prevent duplicate insertion
                  if (findIndex >= 0) {
                    return [..._context.addShipTo]
                  }
                  return [..._context.addShipTo, event.data]
                }
              },
            }),
            cond: _context => {
              return (
                Number(_context.selectedTransaction) ===
                  TransactionType.UpdateCustomerAccountProfile &&
                [
                  UpdateAccountOptions.CLINIC_SHIPPING_LOCATION_CONTACTS,
                  UpdateAccountOptions.STORE_SHIPPING_LOCATION_CONTACTS,
                  UpdateAccountOptions.UNIVERSITY_SHIPPING_LOCATION_CONTACTS,
                ].includes(_context.updateAccount)
              )
            },
          },
          {
            target: NACStates.addShipTo,
            actions: assign({
              addShipTo: (_context, event) => {
                if (!_context.addShipTo) {
                  return [event.data]
                } else {
                  const findIndex = _context.addShipTo
                    ? _context.addShipTo.findIndex(
                        shipTo =>
                          shipTo.shipToAccountNumber ==
                          event.data.shipToAccountNumber,
                      )
                    : 0

                  //To prevent duplicate insertion
                  if (findIndex >= 0) {
                    return [..._context.addShipTo]
                  }
                  return [..._context.addShipTo, event.data]
                }
              },
            }),
          },
        ],
      },
    },
    [NACStates.transferShipToLocation]: {
      on: {
        INIT: RESET,
        BACK: {
          target: NACStates.contact,
        },
        NEXT: [
          {
            target: NACStates.shipToNumber,
            actions: assign({
              addShipTo: (_context, event) => {
                const {deletedAccountNumbers = []} = event.data
                return _context.addShipTo.filter(
                  shipto =>
                    !deletedAccountNumbers.includes(shipto.shipToAccountNumber),
                )
              },
            }),
            cond: (_context, event) =>
              Number(event.data.additional) === Options.YES,
          },
          {
            target: NACStates.dateSign,
            actions: assign({
              additionalShiptoTransfer: (_context, event) => {
                return event.data.additional
              },
              addShipTo: (_context, event) => {
                const {deletedAccountNumbers = []} = event.data
                return _context.addShipTo.filter(
                  shipto =>
                    !deletedAccountNumbers.includes(shipto.shipToAccountNumber),
                )
              },
            }),
          },
        ],
      },
    },
    [NACStates.updateAccount]: {
      on: {
        INIT: RESET,
        BACK: {
          target: NACStates.shipToQualifier,
        },
        NEXT: [
          {
            target: NACStates.enterBuisnessInformation,
            actions: assign({
              updateAccount: (_context, event) => event.data,
            }),
            cond: (_context, event) => {
              const isBusinessInfo = [
                UpdateAccountOptions.BUSINESS_INFORMATION,
                UpdateAccountOptions.UNIVERSITY_INFORMATION,
              ]
              return isBusinessInfo.includes(event.data)
            },
          },
          {
            target: NACStates.financeInfo,
            actions: assign({
              updateAccount: (_context, event) => event.data,
            }),
            cond: (_context, event) => {
              const isFinanceInfo = [UpdateAccountOptions.ORDERING_INFORMATION]
              return isFinanceInfo.includes(event.data)
            },
          },
          {
            target: NACStates.paymentInformation,
            actions: assign({
              updateAccount: (_context, event) => event.data,
            }),
            cond: (_context, event) => {
              return event.data === UpdateAccountOptions.PAYMENT_PREFERENCE
            },
          },
          {
            target: NACStates.shipToNumber,
            actions: assign({
              updateAccount: (_context, event) => event.data,
            }),
            cond: (_context, event) => {
              const isShipToNumber = [
                UpdateAccountOptions.CLINIC_SHIPPING_LOCATION,
                UpdateAccountOptions.CLINIC_SHIPPING_LOCATION_CONTACTS,
                UpdateAccountOptions.STORE_SHIPPING_LOCATION,
                UpdateAccountOptions.STORE_SHIPPING_LOCATION_CONTACTS,
                UpdateAccountOptions.UNIVERSITY_SHIPPING_LOCATION,
                UpdateAccountOptions.UNIVERSITY_SHIPPING_LOCATION_CONTACTS,
              ]
              return isShipToNumber.includes(event.data)
            },
          },
        ],
      },
    },
    [NACStates.pdfSign]: {
      on: {
        INIT: RESET,
        BACK: {
          target: NACStates.review,
        },
        NEXT: {
          target: NACStates.thankYou,
        },
      },
    },
    [NACStates.thankYou]: {
      on: {
        INIT: RESET,
        BACK: {
          target: NACStates.pdfSign,
        },
      },
      type: "final",
    },
  },
})
