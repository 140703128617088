/* eslint-disable @typescript-eslint/no-explicit-any */
import {NACMachineContext} from "../../machine/nac-machine.types"
import {TransactionType} from "../../constants/select-transaction"
import {UpdateAccountOptions} from "../../constants/update-account-options"
import {
  IGNORE_SALES_TAX,
  SHOW_BASE_ENTRY,
  SHOW_APPOINTMENT,
  SHOW_BUILDING,
} from "../../constants/shipto/add"
import {ValidateAccount} from "../../types/transform"
import {OperationalHours, ContactInfo} from "../../types"
import {TACCOUNTTYPE, TOPTION, TTRANSACTION} from "../../constants/transform"
import {
  transformBusinessInfoPayload,
  transformContactInformation,
  transformUniversityDetails,
} from "../../utils/businessinfo-utils"
import {transformFinacialInformation} from "../../utils/financeInfo-utils"
import {AccountType, Options} from "../../constants"
import {transformTransferOwnershipDetails} from "../../utils/transferOwnership-utils"
import {transformPaymentInfo} from "../../utils/paymentinfo-utils"
import {getIsVet, transformVetDetails} from "../../utils/vetInformation-utils"
import {getCurrentDate} from "../../utils/formatDate"
import {ShipToLocation} from "../../constants/shipto/how-many-ship-to"
import {transformNonProfitDetails} from "../../utils/nonProfit-utils"

const files: any[] = []

export const transform = (context: any) => {
  let transformedPayload = {} as any
  const speciality = {...context.validateSpeciality}

  if (
    AccountType.NON_PROFIT == Number(context.accountType) &&
    speciality?.nonProfit == Options.YES
  ) {
    files.push(context?.validateSpeciality?.form501)
  }

  const shoulNotHaveAddVetDetails =
    ([AccountType.VETERINARY, AccountType.MOBILE_VETERINARY].includes(
      Number(context.accountType),
    ) &&
      context.selectedTransaction == TransactionType.AddNewShipToLocation) ||
    ([AccountType.VETERINARY, AccountType.MOBILE_VETERINARY].includes(
      Number(context.accountType),
    ) &&
      context.selectedTransaction ==
        TransactionType.UpdateCustomerAccountProfile &&
      context.updateAccount !== UpdateAccountOptions.ORDERING_INFORMATION) ||
    (context.accountType == AccountType.VET_TECH &&
      context.selectedTransaction ==
        TransactionType.UpdateCustomerAccountProfile &&
      context.updateAccount !== UpdateAccountOptions.UNIVERSITY_INFORMATION) ||
    context.accountType == AccountType.PET_STORE ||
    (context.accountType == AccountType.MILITARY &&
      context.validateMilitary?.staff == Options.NO) ||
    (context.accountType == AccountType.LAW_ENFORCEMENT &&
      context.validateLaw?.staff == Options.NO) ||
    (context.accountType == AccountType.NON_PROFIT &&
      context.validateSpeciality?.staff == Options.NO)

  /* 
  Check if Create New account or Add New Ship To Location Transaction
*/
  if (
    context.selectedTransaction == TransactionType.AddNewShipToLocation ||
    context.selectedTransaction == TransactionType.CreateNewCustomerAccount ||
    context.selectedTransaction == TransactionType.TransferOwnership
  ) {
    if (context.howManyShipTo == ShipToLocation.ALL) {
      transformedPayload = {
        ...validateAccount(context),
        customerAction:
          TTRANSACTION[context.selectedTransaction as TransactionType],
        authorizedSignerDetails: context.dateSign,
        contactInformation: transformContactInformation(context),
      }
    } else {
      transformedPayload = {
        ...validateAccount(context),
        customerAction:
          TTRANSACTION[context.selectedTransaction as TransactionType],
        shipToLocationInformation: transformShippingInfo(context),
        authorizedSignerDetails: context.dateSign,
        contactInformation: transformContactInformation(context),
      }
    }
  }

  /* check if Update Account Transaction */
  if (
    context.selectedTransaction == TransactionType.UpdateCustomerAccountProfile
  ) {
    transformedPayload = {
      ...validateAccount(context),
      customerAction:
        TTRANSACTION[context.selectedTransaction as TransactionType],
      authorizedSignerDetails: context.dateSign,
    }
    if (
      context.updateAccount == UpdateAccountOptions.CLINIC_SHIPPING_LOCATION ||
      context.updateAccount ==
        UpdateAccountOptions.CLINIC_SHIPPING_LOCATION_CONTACTS ||
      context.updateAccount == UpdateAccountOptions.STORE_SHIPPING_LOCATION ||
      context.updateAccount ==
        UpdateAccountOptions.STORE_SHIPPING_LOCATION_CONTACTS ||
      context.updateAccount ==
        UpdateAccountOptions.UNIVERSITY_SHIPPING_LOCATION
    ) {
      transformedPayload.shipToLocationInformation =
        transformShippingInfo(context)
    }
  }

  /* Check if contact details provided */
  if (context?.shipToQualifier) {
    const qualifier = context.shipToQualifier
    transformedPayload.existingAccountNumber = qualifier.soldTo
    transformedPayload.contactInformation = {
      firstName: qualifier.firstName,
      lastName: qualifier.lastName,
      phoneNo: qualifier.phone,
      emailAddress: qualifier.email,
    }
  }
  if (
    context?.businessInformation &&
    Number(context.accountType) !== AccountType.VET_TECH
  ) {
    transformedPayload.businessInformation = transformBusinessInfoPayload(
      context.accoutType,
      context.selectedTransaction,
      context.businessInformation,
    )
  }
  if (context?.financialInformation) {
    transformedPayload.orderingInformation = transformFinacialInformation(
      context?.accountType,
      context.financialInformation,
    )
  }
  if (Number(context?.accountType) === AccountType.VET_TECH) {
    transformedPayload.universitySpecificDetails =
      transformUniversityDetails(context)
    transformedPayload.veterinarianInformation = transformVetDetails(context)
  }
  if (
    Number(context?.selectedTransaction) === TransactionType.TransferOwnership
  ) {
    transformedPayload.transferOfOwnershipDetails =
      transformTransferOwnershipDetails(context)
  }
  if (context?.paymentInformation) {
    const paymentInfo = transformPaymentInfo(
      context.accountType,
      context.paymentInformation,
    )

    transformedPayload.preferredModeOfPayment =
      paymentInfo?.preferredModeOfPayment
    if (paymentInfo?.paymentDetails)
      transformedPayload.paymentDetails = paymentInfo?.paymentDetails
  }
  if (!shoulNotHaveAddVetDetails) {
    transformedPayload.veterinarianInformation = transformVetDetails(context)
  }
  transformedPayload.currentDate = getCurrentDate()
  transformedPayload.isVet = getIsVet(context)
  if (context.accountType == AccountType.NON_PROFIT) {
    transformedPayload.nonProfitSpecificDetails =
      transformNonProfitDetails(context)
  }
  transformedPayload.custNotes = context?.custNotes
  return {transformedPayload, files}
}

function validateAccount(context: NACMachineContext) {
  const validationDetails = {} as ValidateAccount
  if (context.accountType) {
    validationDetails.accountType = TACCOUNTTYPE[context.accountType]
  }
  return validationDetails
}

function transformShippingInfo(context: NACMachineContext) {
  const shippingInfo = [] as any
  context?.addShipTo?.forEach(shipTo => {
    let shipToInfo = {} as any
    if (
      context.selectedTransaction ==
        TransactionType.UpdateCustomerAccountProfile &&
      (context?.updateAccount ==
        UpdateAccountOptions.CLINIC_SHIPPING_LOCATION_CONTACTS ||
        context?.updateAccount ==
          UpdateAccountOptions.STORE_SHIPPING_LOCATION_CONTACTS)
    ) {
      shipToInfo.deliveryContacts = transformShippingContacts(
        shipTo?.contactInformation,
      )
    } else {
      shipToInfo = {
        shippingLocationName: shipTo?.location ? shipTo.location : "",
        shippingAddressInformation: {
          apartment: shipTo.shippingAddress?.apartment ? shipTo.shippingAddress.apartment : "",
          streetAddress: shipTo.shippingAddress?.street ? shipTo.shippingAddress.street : "",
          city: shipTo.shippingAddress?.city ? shipTo.shippingAddress.city : "",
          state: shipTo.shippingAddress?.state ? shipTo.shippingAddress.state : "",
          zipCode: shipTo.shippingAddress?.zip ? shipTo.shippingAddress.zip : "",
          shippingLocationPhoneNo: shipTo?.phone ? shipTo.phone : "",
        },
        shouldChargeSalesTax: shipTo?.salesTax
          ? TOPTION[shipTo.salesTax]
          : false,
        daysAndHoursOfOperation: transformOperationalHours(
          shipTo.operationalHours,
        ),
        doesAcceptDeliveryOutsideOperatingHours:
          shipTo?.outsideHours && TOPTION[shipTo?.outsideHours] ? TOPTION[shipTo.outsideHours] : false,
        deliveryDoor: shipTo?.entry ? shipTo.entry : "",
        areThereStepsForDelivery: shipTo?.stepsAvailability ? TOPTION[shipTo.stepsAvailability] : false,
        canReceiveProductOnPallets: shipTo?.palletsAvailability ? TOPTION[shipTo.palletsAvailability] : false,
        isRampAvailable: shipTo?.rampAvailability ? TOPTION[shipTo.rampAvailability] : false,
        closestMajorIntersection: shipTo?.intersection ? shipTo.intersection : "",
        deliveryInstructions: shipTo?.instructions ? shipTo.instructions : "",
        existingAccountNumber: context?.shipToQualifier?.soldTo
      } as any

      if (!shipToInfo.shouldChargeSalesTax && shipTo?.exemptionCertificate) {
        files.push(shipTo.exemptionCertificate)
      }

      /* corporate/business network name field will be visible only if the user is adding a new ship-to location,
      oesnt appear in the new account creation flow
      */
      if (
        context?.selectedTransaction != TransactionType.CreateNewCustomerAccount
      ) {
        shipToInfo.corporateGroupName = shipTo?.corporateName ? shipTo.corporateName : ""
      }

      shipToInfo.shipToAccountNumber = shipTo?.shipToAccountNumber ? shipTo.shipToAccountNumber : ""

      /* show sales tax question if the account type is not there in the ignore list */
      if (
        context.accountType &&
        !IGNORE_SALES_TAX.includes(context.accountType?.toString())
      ) {
        shipToInfo.shouldChargeSalesTax =
          shipTo?.salesTax && TOPTION[shipTo?.salesTax] ? TOPTION[shipTo.salesTax] : false
      }

      if (
        context.accountType &&
        SHOW_BUILDING.includes(context.accountType?.toString())
      ) {
        shipToInfo.deliveryBuilding = shipTo?.building ? shipTo.building : ""
      }

      if (
        context.accountType &&
        SHOW_APPOINTMENT.includes(context.accountType?.toString())
      ) {
        shipToInfo.isDeliveryAppointmentReqUniversity =
          shipTo?.appointment && TOPTION[shipTo?.appointment] ? TOPTION[shipTo.appointment] : false
      }

      /* Show base entry question if the account type is there in the show list */
      if (
        context.accountType &&
        SHOW_BASE_ENTRY.includes(context?.accountType?.toString())
      ) {
        shipToInfo.credentialsRequiredForEntry = shipTo?.baseEntry ? shipTo.baseEntry : ""
      }

      /* 
        Show delivery window only if outside hour delivery is allowed
      */
      if (TOPTION[shipTo?.outsideHours]) {
        if (shipTo?.outsideHoursDelivery) {
          shipToInfo.dailyDeliveryWindow = transformOperationalHours(
            shipTo?.outsideHoursDelivery,
          )
        }
      }
      shipToInfo.deliveryContacts = transformShippingContacts(
        shipTo?.contactInformation,
      )
    }

    shippingInfo.push(shipToInfo)
  })
  return shippingInfo
}

function transformShippingContacts(contactInformation: ContactInfo) {
  const deliveryContacts = [] as any
  if (contactInformation?.contacts?.length > 0) {
    contactInformation.contacts.map(contact => {
      const contactInfo = {
        isPrimary: false,
        contactInformation: {
          firstName: contact.firstName,
          lastName: contact.lastName,
          phoneNo: contact.phone,
          title: contact.title,
          emailAddress: contact.email,
        },
        permissionConfigurations: {
          canViewDeliveryStatus: Boolean(contact?.viewDeliveryStatus),
          shouldNotifyRouteDelay: Boolean(contact.notifyRouteDelay),
          shouldSendHillsStaffFeedingProgramDetails: Boolean(
            contact?.sendFeedProgramDetails,
          ),
          shouldSendHillsClinicOrderingProgramDetails: Boolean(
            contact?.sendClinicDetails,
          ),
          interestedInMarketingCommunications: Boolean(
            contact?.marketingCommunications,
          ),
        },
      } as any

      if (Number(contactInformation.primaryContact) == contact.id) {
        contactInfo.isPrimary = true
      }
      if (contact.notifications) {
        contactInfo.mobileNoToSendAlertsAndNotificationsViaSMS = contact.sms
      }
      deliveryContacts.push(contactInfo)
    })
  }
  return deliveryContacts
}

function transformOperationalHours(operationalHours: OperationalHours) {
  const daysAndHoursOfOperation = {} as any
  Object.keys(operationalHours).map(day => {
    const dayOperational = operationalHours[day as keyof OperationalHours]
    if (dayOperational.length === 1 && dayOperational[0].duration === "") {
      daysAndHoursOfOperation[day] = {
        isOpen: false,
        hoursOfOperation: [],
      }
    } else {
      daysAndHoursOfOperation[day] = {
        isOpen: false,
        hoursOfOperation: [],
      }
      dayOperational.forEach(hours => {
        daysAndHoursOfOperation[day].hoursOfOperation.push(hours.duration)
      })
      daysAndHoursOfOperation[day].isOpen = true
    }
  })
  return daysAndHoursOfOperation
}