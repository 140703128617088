import {Field} from "react-final-form"
import {FieldArray} from "react-final-form-arrays"
import {PlusIcon, TrashIcon, Input, Checkbox} from "ui"
import {composeValidators, required} from "../../../utils/validators"
import {DAYS} from "../../../constants/shipto/add"

const OperationalHours = ({
  values,
  fieldName = "",
  closedMessage = "Closed",
  question,
  placeholder = "Hours of Operation",
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
Record<string, any>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const modifyDay = (index: number, fields: any) => {
    if (index === 0) {
      fields.push({duration: ""})
    } else {
      fields.remove(index)
    }
  }
  const disableAdd = (name: string, index: number) => {
    return values[name].length === 2 && index === 0
  }
  const renderDays = (
    day: {value: string; label: string},
    dayIndex: number,
  ) => {
    const fieldIdPrefix = fieldName ? `${fieldName}-` : ""
    const fieldDayId = `${fieldIdPrefix}${day.value}-operational`
    return (
      <FieldArray
        name={`${day.value}${fieldName && `Outside`}`}
        key={day.value}
      >
        {({fields}) => {
          return (
            <>
              {fields.map((name, index) => {
                const fieldHoursId = `${name}.duration`
                return (
                  <li
                    className={`grid min-h-[80px] w-full grid-cols-12 items-center ${
                      index == 0 && dayIndex !== 0 ? `border-t` : ``
                    } gap-y-2 gap-x-8 pr-3 lg:border-0`}
                    key={index}
                  >
                    <div
                      className={`col-span-12 mt-4 w-auto  md:col-span-3 md:mx-0 md:mt-0 ${
                        values[`${day.value}-operational`] ? `` : `grow`
                      }`}
                    >
                      {index === 0 && (
                        <Field
                          name={fieldDayId}
                          id={fieldDayId}
                          htmlFor={fieldDayId}
                          component={Checkbox}
                          type="checkbox"
                          label={day.label}
                        />
                      )}
                    </div>
                    {!values[fieldDayId] ? (
                      <p className="col-span-12 mb-2 ml-10 text-gray-500 md:col-span-8 md:m-0">
                        {closedMessage}
                      </p>
                    ) : (
                      <>
                        <div className="col-span-10 grow md:col-span-8">
                          <Field
                            name={fieldHoursId}
                            id={fieldHoursId}
                            htmlFor={fieldHoursId}
                            component={Input}
                            label={placeholder}
                            validate={composeValidators(...[required])}
                          />
                        </div>
                        <button
                          disabled={disableAdd(fields.name, index)}
                          className={`col-span-2 h-4 w-4 shrink grow-0 cursor-pointer md:col-span-1 md:ml-auto ${
                            disableAdd(fields.name, index)
                              ? `cursor-not-allowed`
                              : `cursor-pointer`
                          }`}
                          onClick={() => modifyDay(index, fields)}
                          data-testid="add-hours"
                        >
                          {index === 0 ? <PlusIcon /> : <TrashIcon />}
                        </button>
                      </>
                    )}
                  </li>
                )
              })}
            </>
          )
        }}
      </FieldArray>
    )
  }
  return (
    <div className="mb-10 flex flex-col">
      <p className="text-gray-700">
        <span className="font-bold">{question.label}</span>
        <span>{question.helperText}</span>
      </p>
      <ul className=" flex w-full flex-col">
        {DAYS.map((day, index) => renderDays(day, index))}
      </ul>
    </div>
  )
}

export default OperationalHours
