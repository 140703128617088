import {useState, useContext, useEffect} from "react"
import {useNavigate, useLocation} from "react-router-dom"
import {Form} from "react-final-form"
import {WrapperContainer, AlertDialog, Button} from "ui"
import Question from "../../utils/Question"
import {
  LAW_ALERT,
  LAW_QUESTIONS,
} from "../../../constants/accounts/law-enforcement"
import {
  RoutePaths,
  FormButton,
  QuestionsInterface,
  Options,
} from "../../../constants"
import {nacMachineContext} from "../../../context"
import {NACEvents, NACStates} from "../../../machine/nac-machine.types"

const LawEnforcement = () => {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const {state, send} = useContext(nacMachineContext) || {}

  const onSubmit = (values: {staff: string}) => {
    send(NACEvents.NEXT, {data: {...values}})
    navigate(RoutePaths.SELECT_TRANSACTION_TYPE)
  }

  const renderAlertBox = () => {
    return (
      <AlertDialog
        open={open}
        setOpen={setOpen}
        title={LAW_ALERT.title}
        description={LAW_ALERT.description}
      />
    )
  }

  const renderSubQuestion = (
    question: QuestionsInterface<Options>,
    values: {staff: string; location?: string},
    index: number,
  ) => {
    if (
      question?.SUB_QUESTIONS &&
      question?.SUB_QUESTIONS[index].EXPECTED_PARENT_VALUE.includes(
        parseInt(values.staff),
      )
    ) {
      return (
        <>
          <Question
            questions={question?.SUB_QUESTIONS[index].QUESTIONS}
            renderSubQuestion={renderSubQuestion}
            values={values}
            subQuestion={true}
          />
        </>
      )
    }
  }

  useEffect(() => {
    if (state?.value !== NACStates.validateLawEnforcement) {
      send(NACEvents.INIT, {data: {route: location.pathname}})
    }
  }, [])
  return (
    <div className="flex justify-center">
      <div className="w-full sm:w-[520px]">
        <WrapperContainer>
          <Form
            initialValues={{
              staff: state.context.validateLaw?.staff,
              firstName: state.context.validateLaw?.firstName,
              lastName: state.context.validateLaw?.lastName,
              vetLicense: state.context.validateLaw?.vetLicense,
            }}
            onSubmit={onSubmit}
            render={({handleSubmit, invalid, values}) => (
              <form onSubmit={handleSubmit}>
                <Question
                  questions={LAW_QUESTIONS}
                  renderSubQuestion={renderSubQuestion}
                  values={values}
                />
                <div className="flex justify-end gap-8">
                  <Button
                    variant="text"
                    onClick={() => {
                      send(NACEvents.BACK)
                      navigate(RoutePaths.ACCOUNT_TYPE)
                    }}
                  >
                    {FormButton.BACK}
                  </Button>
                  <Button
                    disabled={invalid}
                    variant="primary"
                    mode="filled"
                    type="submit"
                  >
                    {FormButton.CONTINUE}
                  </Button>
                </div>
              </form>
            )}
          />
          {renderAlertBox()}
        </WrapperContainer>
      </div>
    </div>
  )
}

export default LawEnforcement
