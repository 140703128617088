import {IconWarning} from "ui"
import {SESSION_EXPIRED_ERROR} from "../../constants"

const SessionExpired = () => {
  return (
    <div className="fixed inset-0 z-20 flex flex-col items-center justify-center bg-white">
      <div className="h-14 w-16">
        <IconWarning />
      </div>

      <div className="sm:w-[520px]">
        <h1 className="my-8 text-center text-[2.75rem] font-extrabold uppercase leading-10">
          {SESSION_EXPIRED_ERROR.header}
        </h1>
        <p
          className="mb-8 text-center text-lg font-normal text-brand-accent-gray-light"
          dangerouslySetInnerHTML={{__html: SESSION_EXPIRED_ERROR.message}}
        />
      </div>
    </div>
  )
}

export default SessionExpired
