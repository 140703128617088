import {mustBeEmail} from "./../utils/validators"
import {QuestionsInterface, Options} from "./index"
import {required, maxLength} from "../utils/validators"

export const NOTE = {
  title: "NOTE: ",
  description:
    " The person you specify below must be an authorized signer for your business and will be required to review and digitally sign this agreement.",
}

export const DATESIGN_OFFICER_QUESTIONS: QuestionsInterface<Options>[] = [
  {
    QUESTION_TEXT: "What is the owner/corporate officer’s name?",
    VALIDATION: [required, maxLength(50)],
    OPTIONS: [
      {
        label: "Owner/Corporate Officer First Name",
        name: "firstName",
        id: "firstName",
        dataTestId: "first_name",
        type: "text",
        componentType: "Input",
      },
      {
        label: "Owner/Corporate Officer Last Name",
        name: "lastName",
        id: "lastName",
        dataTestId: "last_name",
        type: "text",
        componentType: "Input",
      },
    ],
  },
  {
    QUESTION_TEXT: "What is the owner/corporate officer’s title?",
    VALIDATION: [required],
    OPTIONS: [
      {
        label: "Owner/Corporate Officer Title",
        name: "title",
        id: "title",
        dataTestId: "title",
        type: "text",
        componentType: "Input",
      },
    ],
  },
  {
    QUESTION_TEXT: "What is the owner/corporate officer’s email address?",
    VALIDATION: [required, mustBeEmail],
    OPTIONS: [
      {
        label: "Owner/Corporate Officer Email",
        name: "email",
        id: "email",
        dataTestId: "email",
        type: "text",
        componentType: "Input",
      },
    ],
  },
]

export const DATESIGN_SIGNER_QUESTIONS: QuestionsInterface<Options>[] = [
  {
    QUESTION_TEXT: "What is the authorized signer's officer’s name?",
    VALIDATION: [required, maxLength(50)],
    OPTIONS: [
      {
        label: "Authorized Signer First Name",
        name: "firstName",
        id: "firstName",
        dataTestId: "first_name",
        type: "text",
        componentType: "Input",
      },
      {
        label: "Authorized Signer Last Name",
        name: "lastName",
        id: "lastName",
        dataTestId: "last_name",
        type: "text",
        componentType: "Input",
      },
    ],
  },
  {
    QUESTION_TEXT: "What is the authorized signer’s title?",
    VALIDATION: [required],
    OPTIONS: [
      {
        label: "Authorized Signer Title",
        name: "title",
        id: "title",
        dataTestId: "title",
        type: "text",
        componentType: "Input",
      },
    ],
  },
  {
    QUESTION_TEXT: "What is the authorized signer’s email address?",
    VALIDATION: [required, mustBeEmail],
    OPTIONS: [
      {
        label: "Authorized Signer Email",
        name: "email",
        id: "email",
        dataTestId: "email",
        type: "text",
        componentType: "Input",
      },
    ],
  },
]

export const HEADER = "Authorizer Information"
