/* eslint-disable @typescript-eslint/no-explicit-any */
import {Field} from "react-final-form"
import {QuestionsInterface} from "../../constants/index"
import {composeValidators} from "../../utils/validators"
import {components} from "../../utils/components"

interface QuestionInterface<T> {
  questions: QuestionsInterface<T>[]
  renderSubQuestion?: (
    question: QuestionsInterface<T>,
    values: any,
    index: number,
    fieldName: string,
    touched?: {
      [key: string]: boolean
    },
  ) => void
  values: any
  subQuestion?: boolean
  touched?: {
    [key: string]: boolean
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const Question = <T extends {}>({
  questions,
  renderSubQuestion,
  values,
  subQuestion = false,
  touched,
}: QuestionInterface<T>) => {
  let fieldName = ""
  return (
    <>
      {questions.map((question, index) => {
        return (
          <div
            className={`${!subQuestion ? `mb-10` : ``} flex flex-col gap-1`}
            key={index}
          >
            <p className={`${subQuestion ? `mt-7` : ``} mb-3 text-gray-700`}>
              <span className="font-bold">{question.QUESTION_TEXT}</span>
              {question.QUESTION_TEXT_HELPER}
              {question?.BLOCK_HELPER_TEXT && (
                <p>{question?.BLOCK_HELPER_TEXT}</p>
              )}
            </p>

            {question.OPTIONS.map((option, optionIdx) => {
              fieldName = option.name
              const validation = option.VALIDATION
                ? [...option.VALIDATION]
                : question.VALIDATION
                ? [...question.VALIDATION]
                : []

              return (
                <>
                  {option.warningtext && (
                    <span className="-mt-3 text-xs font-semibold text-red-700">
                      {option.warningtext}
                    </span>
                  )}
                  <Field
                    name={option.name}
                    component={components[option.componentType]}
                    type={option.type}
                    value={
                      typeof option.value === "number"
                        ? option.value?.toString()
                        : option.value
                    }
                    label={option.label}
                    helpertext={option?.helperText}
                    validate={
                      Array.isArray(validation)
                        ? composeValidators(...validation)
                        : undefined
                    }
                    key={optionIdx}
                    id={option.id}
                    htmlFor={option.id}
                    data-testid={option?.dataTestId}
                    selectOptions={option.options}
                    helperText={option.helperText}
                    className="mb-4"
                    disabled={option.disabled}
                    parse={option?.format}
                    surfly_private={option?.surfly_private}
                  />
                </>
              )
            })}
            <>
              {renderSubQuestion &&
                renderSubQuestion(question, values, index, fieldName, touched)}
            </>
          </div>
        )
      })}
    </>
  )
}
export default Question
