import {useContext, useEffect} from "react"
import {useNavigate, useLocation, useSearchParams} from "react-router-dom"
import {WrapperContainer, Button, Stepper} from "ui"
import {Form} from "react-final-form"
import arrayMutators from "final-form-arrays"
import ShippingAdress from "./ShippingAdress"
import PhoneNumber from "./PhoneNumber"
import LocationEntry from "./LocationEntry"
import SalesTax from "./SalesTax"
import OperationalHours from "./OperationalHours"
import OutsideHours from "./OutsideHours"
import Question from "../../utils/Question"
import {nacMachineContext} from "../../../context"
import {NACEvents, NACStates} from "../../../machine/nac-machine.types"
import {
  RoutePaths,
  FormButton,
  Options,
  AccountType,
  UPDATE_QUESTIONS,
  QuestionsInterface,
  PHONE_REGEX
} from "../../../constants"
import {
  SHIPPING_LOCATION,
  BUSINESS_NETWORK,
  DELIVERY_QUESTIONS,
  DELIVERY_APPOINTMENT_QUESTION,
  OPERATIONAL_HOURS_INITIAL_VALUE,
  OPERATIONAL_HOURS_QUESTION,
  OUTSIDE_OPERATIONAL_HOURS_INITIAL_VALUE,
  IGNORE_SALES_TAX,
  SHOW_APPOINTMENT,
  SHOW_BUILDING,
  BASE_ENTRY_QUESTION,
  SHOW_BASE_ENTRY,
  HEADER,
} from "../../../constants/shipto/add"
import {TransactionType} from "../../../constants/select-transaction"
import {required} from "../../../utils/validators"

const addShipTo = () => {
  const {state, send} = useContext(nacMachineContext) || {}
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const isEditFlow = state?.history?.value === NACStates.review
  const ARRAY_UPDATE = (value : QuestionsInterface<Options>[]): QuestionsInterface<Options>[] => {
    const values = [SHIPPING_LOCATION, BASE_ENTRY_QUESTION, DELIVERY_APPOINTMENT_QUESTION]
    if (values.includes(value)) {
      const validation = value[0].VALIDATION?.filter((item: unknown) => item !== required)
      value[0].VALIDATION = validation
    }
    return value
  }
  const DELIVERY_QUESTION_UDPATE = (value : QuestionsInterface<Options>[]) => {
    for (const element of value) {
      if (element?.VALIDATION) {
        const validation = element.VALIDATION.filter((item : unknown) => item !== required)
        element.VALIDATION = validation
      }
    }
    return value
  }
  const isUpdateProfile =
    Number(state?.context?.selectedTransaction) ==
    TransactionType.UpdateCustomerAccountProfile

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (values: any) => {
    const emptyDuration = [{duration: ""}]
    const data: Record<string, unknown> = {
      location: values.location,
      shippingAddress: {
        street: values.street,
        apartment: values.apartment,
        city: values.city,
        state: values.state,
        zip: values.zip,
      },
      phone: values.phone.replace(PHONE_REGEX, ""),
      sundayOperational: Boolean(values["sunday-operational"]),
      mondayOperational: Boolean(values["monday-operational"]),
      tuesdayOperational: Boolean(values["tuesday-operational"]),
      wednesdayOperational: Boolean(values["wednesday-operational"]),
      thursdayOperational: Boolean(values["thursday-operational"]),
      fridayOperational: Boolean(values["friday-operational"]),
      saturdayOperational: Boolean(values["saturday-operational"]),

      operationalHours: {
        sunday: values["sunday-operational"] ? values.sunday : emptyDuration,
        monday: values["monday-operational"] ? values.monday : emptyDuration,
        tuesday: values["tuesday-operational"] ? values.tuesday : emptyDuration,
        wednesday: values["wednesday-operational"]
          ? values.wednesday
          : emptyDuration,
        thursday: values["thursday-operational"]
          ? values.thursday
          : emptyDuration,
        friday: values["friday-operational"] ? values.friday : emptyDuration,
        saturday: values["saturday-operational"]
          ? values.saturday
          : emptyDuration,
      },
      outsideHours: values.outsideHours,
      building: values.building,
      entry: values.entry,
      stepsAvailability: values.steps,
      palletsAvailability: values.pallets,
      rampAvailability: values.ramp,
      intersection: values.intersection,
      instructions: values.instructions,
    }
    /* 
    corporate name field will be visible only if the user is adding a new ship-to location,
    doesnt appear in the new account creation flow
     */
    if (state?.history?.value === "shipToQualifier") {
      data.corporateName = values.corporateName
    }

    /* 
      sales tax question is visible if the account type is not there in the ignore list
      And tax exemption certificate should be uploaded if user selects NO
    */
    if (!IGNORE_SALES_TAX.includes(state?.context?.accountType)) {
      data.salesTax = values.salesTax
      if (Number(data.salesTax) == Options.NO) {
        data.exemptionCertificate = values.exemptionCertificate
      }
    }

    if (parseInt(values.outsideHours) === Options.YES) {
      data.outsideHoursDelivery = {
        sunday: values["outside-sunday-operational"]
          ? values.sundayOutside
          : emptyDuration,
        monday: values["outside-monday-operational"]
          ? values.mondayOutside
          : emptyDuration,
        tuesday: values["outside-tuesday-operational"]
          ? values.tuesdayOutside
          : emptyDuration,
        wednesday: values["outside-wednesday-operational"]
          ? values.wednesdayOutside
          : emptyDuration,
        thursday: values["outside-thursday-operational"]
          ? values.thursdayOutside
          : emptyDuration,
        friday: values["outside-friday-operational"]
          ? values.fridayOutside
          : emptyDuration,
        saturday: values["outside-saturday-operational"]
          ? values.saturdayOutside
          : emptyDuration,
      }
      data.sundayOutside = Boolean(values["outside-sunday-operational"])
      data.mondayOutside = Boolean(values["outside-monday-operational"])
      data.tuesdayOutside = Boolean(values["outside-tuesday-operational"])
      data.wednesdayOutside = Boolean(values["outside-wednesday-operational"])
      data.thursdayOutside = Boolean(values["outside-thursday-operational"])
      data.fridayOutside = Boolean(values["outside-friday-operational"])
      data.saturdayOutside = Boolean(values["outside-saturday-operational"])
    }

    /* 
      appointment question is visible if the account type is not there in the ignore list
    */
    if (SHOW_APPOINTMENT.includes(state?.context?.accountType)) {
      data.appointment = values.appointment
    }

    /* 
      base entry question is visible if the account type is there in the show list
    */

    if (SHOW_BASE_ENTRY.includes(state?.context?.accountType)) {
      data.baseEntry = values.baseEntry
    }

    /* 
      set contacts to the state if the user is coming from contacts screen
    */
    if (state.history?.value === "contact") {
      data.contactInformation =
        state.context.addShipTo[
          state.context.addShipTo.length - 1
        ].contactInformation
    }

    if (state.history?.value === "shipToNumber") {
      data.update = values.update
    }
    if (isUpdateProfile) {
      if (Number(values.update) === Options.YES) {
        send(NACEvents.NEXT, {
          data: {
            ...data,
            history: state.history.value,
            updateIndex: Number(searchParams.get("id")),
          },
        })
        navigate(RoutePaths.UPDATE_ACCOUNT)
      } else {
        const history = state.history.value
        send(NACEvents.NEXT, {
          data: {
            ...data,
            updateIndex: Number(searchParams.get("id")),
            history: state.history.value,
          },
        })
        if (history === "review") {
          navigate(RoutePaths.REVIEW)
        } else {
          navigate(RoutePaths.DATE_SIGN)
        }
      }
    } else if (
      [NACStates.review, NACStates.shipToNumber].includes(state.history?.value)
    ) {
      send(NACEvents.NEXT, {
        data: {
          ...data,
          history: state.history.value,
          updateIndex: Number(searchParams.get("id")),
        },
      })
      navigate(`${RoutePaths.CONTACTS}?id=${searchParams.get("id")}`)
    } else {
      send(NACEvents.NEXT, {
        data: {
          ...data,
          history: state.history.value,
        },
      })
      navigate(RoutePaths.CONTACTS)
    }
  }

  const setInitialValues = () => {
    let initialValues: Record<string, unknown> = {}

    /* If users visits add shipto screen from contacts screen on pressing back button, 
       set the initial value of the form to the previous state value 
     */

    if (
      state.history?.value === "contact" ||
      (state.history?.value === "paymentInformation" &&
        state?.context?.addShipTo?.length > 0) ||
      (state.history?.value === "financeInfo" &&
        state?.context?.addShipTo?.length > 0) ||
      (state.history?.value === "enterBuisnessInformation" &&
        state?.context?.addShipTo?.length > 0) ||
      state.history?.value === "dateSign" ||
      state.history?.value === "review" ||
      (state.history?.value === "shipToQualifier" &&
        state?.context?.addShipTo?.length > 0)
    ) {
      let shipToState = null
      if (searchParams.get("id")) {
        shipToState =
          state.context.addShipTo[parseInt(searchParams.get("id") as string)]
      } else if (
        state.context.addShipTo &&
        state.context.addShipTo.length > 0
      ) {
        shipToState =
          state?.context?.addShipTo[state?.context?.addShipTo?.length - 1]
      }
      const initialDuration = [{duration: ""}]
      initialValues = {
        location: shipToState?.location,
        street: shipToState?.shippingAddress?.street,
        apartment: shipToState?.shippingAddress?.apartment,
        city: shipToState?.shippingAddress?.city,
        state: shipToState?.shippingAddress?.state,
        zip: shipToState?.shippingAddress?.zip,
        phone: shipToState?.phone,
        corporateName: shipToState?.corporateName,
        salesTax: shipToState?.salesTax,
        exemptionCertificate: shipToState?.exemptionCertificate,
        appointment: shipToState?.appointment,
        outsideHours: shipToState?.outsideHours,
        building: shipToState?.building,
        entry: shipToState?.entry,
        steps: shipToState?.stepsAvailability,
        pallets: shipToState?.palletsAvailability,
        ramp: shipToState?.rampAvailability,
        intersection: shipToState?.intersection,
        instructions: shipToState?.instructions,
        baseEntry: shipToState?.baseEntry,
        sunday: shipToState?.operationalHours?.sunday || initialDuration,
        monday: shipToState?.operationalHours?.monday || initialDuration,
        tuesday: shipToState?.operationalHours?.tuesday || initialDuration,
        wednesday: shipToState?.operationalHours?.wednesday || initialDuration,
        thursday: shipToState?.operationalHours?.thursday || initialDuration,
        friday: shipToState?.operationalHours?.friday || initialDuration,
        saturday: shipToState?.operationalHours?.saturday || initialDuration,
        sundayOutside:
          shipToState?.outsideHoursDelivery?.sunday || initialDuration,
        mondayOutside:
          shipToState?.outsideHoursDelivery?.monday || initialDuration,
        tuesdayOutside:
          shipToState?.outsideHoursDelivery?.tuesday || initialDuration,
        wednesdayOutside:
          shipToState?.outsideHoursDelivery?.wednesday || initialDuration,
        thursdayOutside:
          shipToState?.outsideHoursDelivery?.thursday || initialDuration,
        fridayOutside:
          shipToState?.outsideHoursDelivery?.friday || initialDuration,
        saturdayOutside:
          shipToState?.outsideHoursDelivery?.saturday || initialDuration,
      }
      initialValues["sunday-operational"] = shipToState?.sundayOperational
      initialValues["monday-operational"] = shipToState?.mondayOperational
      initialValues["tuesday-operational"] = shipToState?.tuesdayOperational
      initialValues["wednesday-operational"] = shipToState?.wednesdayOperational
      initialValues["thursday-operational"] = shipToState?.thursdayOperational
      initialValues["friday-operational"] = shipToState?.fridayOperational
      initialValues["saturday-operational"] = shipToState?.saturdayOperational

      if (parseInt(shipToState?.outsideHours) === Options.YES) {
        initialValues["outside-sunday-operational"] = shipToState?.sundayOutside
        initialValues["outside-monday-operational"] = shipToState?.mondayOutside
        initialValues["outside-tuesday-operational"] =
          shipToState?.tuesdayOutside
        initialValues["outside-wednesday-operational"] =
          shipToState?.wednesdayOutside
        initialValues["outside-thursday-operational"] =
          shipToState?.thursdayOutside
        initialValues["outside-friday-operational"] = shipToState?.fridayOutside
        initialValues["outside-saturday-operational"] =
          shipToState?.saturdayOutside
      }
    } else {
      initialValues = {
        ...OPERATIONAL_HOURS_INITIAL_VALUE,
        ...OUTSIDE_OPERATIONAL_HOURS_INITIAL_VALUE,
      }
    }
    return initialValues
  }

  useEffect(() => {
    if (state?.value !== NACStates.addShipTo) {
      send(NACEvents.INIT, {data: {route: location.pathname}})
    }
  }, [])

  return (
    <>
      <div className="my-1">
        <Stepper currentStep={4} totalSteps={5} />
        <h2 className="my-4 text-center text-sm font-bold">{HEADER}</h2>
      </div>
      <div className="flex justify-center">
        <div className="w-full lg:w-[792px]">
          <WrapperContainer>
            <Form
              mutators={{...arrayMutators}}
              onSubmit={onSubmit}
              initialValues={{
                ...setInitialValues(),
              }}
              render={({handleSubmit, invalid, values}) => (
                <form onSubmit={handleSubmit}>
                  <Question questions={isUpdateProfile ?
                    ARRAY_UPDATE(SHIPPING_LOCATION) : SHIPPING_LOCATION} values={values} />
                  {/* corporate/business network name field will be visible only if the user is adding a new ship-to location,
                doesnt appear in the new account creation flow
                */}
                  {state?.context?.selectedTransaction !=
                    TransactionType.CreateNewCustomerAccount &&
                    state?.context?.selectedTransaction !=
                      TransactionType.UpdateCustomerAccountProfile && (
                      <Question questions={BUSINESS_NETWORK} values={values} />
                    )}
                  <ShippingAdress values={values} />
                  <PhoneNumber />

                  {/* show sales tax question if the account type is not there in the ignore list */}
                  {IGNORE_SALES_TAX.includes(
                    state?.context?.accountType,
                  ) ? null : (
                    <SalesTax values={values} />
                  )}
                  <OperationalHours
                    values={values}
                    question={OPERATIONAL_HOURS_QUESTION}
                  />
                  <OutsideHours values={values} />
                  {SHOW_BUILDING.includes(state?.context?.accountType) ? (
                    <Question
                      questions={DELIVERY_QUESTIONS.slice(0, 1)}
                      values={values}
                    />
                  ) : null}

                  <LocationEntry />
                  <Question
                    questions={
                      isUpdateProfile ?
                      DELIVERY_QUESTION_UDPATE(DELIVERY_QUESTIONS.slice(1, -2)) :
                      DELIVERY_QUESTIONS.slice(1, -2)
                    }
                    values={values}
                  />
                  {/* show appointment question if the account type is not there in the ignore list */}
                  {SHOW_APPOINTMENT.includes(state?.context?.accountType) ? (
                    <Question
                      questions={isUpdateProfile ? 
                        ARRAY_UPDATE(DELIVERY_APPOINTMENT_QUESTION) : DELIVERY_APPOINTMENT_QUESTION}
                      values={values}
                    />
                  ) : null}

                  <Question
                    questions={DELIVERY_QUESTIONS.slice(-2, -1)}
                    values={values}
                  />

                  {/* Show base entry question if the account type is there in the show list */}
                  {SHOW_BASE_ENTRY.includes(state?.context?.accountType) ? (
                    <Question questions={isUpdateProfile ? 
                      ARRAY_UPDATE(BASE_ENTRY_QUESTION) : BASE_ENTRY_QUESTION} values={values} />
                  ) : null}

                  <Question
                    questions={DELIVERY_QUESTIONS.slice(-1)}
                    values={values}
                  />

                  {/* 
                    Show Update question only if the selected transaction is Update Customer Account Profile
                  */}
                  {Number(state?.context?.selectedTransaction) ===
                    TransactionType.UpdateCustomerAccountProfile && (
                    <Question questions={UPDATE_QUESTIONS} values={values} />
                  )}

                  <div className="flex justify-end gap-8">
                    <Button
                      variant="text"
                      onClick={() => {
                        const history = state?.history?.value
                        send(NACEvents.BACK, {
                          data: {history: history},
                        })
                        if (history == NACStates.additionalShipTo) {
                          navigate(RoutePaths.ADDITIONAL_SHIPTO)
                        } else if (history == NACStates.review) {
                          navigate(RoutePaths.REVIEW)
                        } else if (
                          TransactionType.TransferOwnership ===
                            Number(state?.context?.selectedTransaction) ||
                          TransactionType.UpdateCustomerAccountProfile ===
                            Number(state?.context?.selectedTransaction)
                        ) {
                          navigate(RoutePaths.SHIP_TO_ACCOUNT)
                        } else if (
                          [TransactionType.AddNewShipToLocation].includes(
                            Number(state?.context?.selectedTransaction),
                          )
                        ) {
                          navigate(RoutePaths.SHIP_TO_QUALIFIER)
                        } else if (
                          Number(state?.context?.accountType) ===
                          AccountType.VET_TECH
                        ) {
                          navigate(RoutePaths.BUISNESS_INFORMATION)
                        } else if (
                          Number(state?.context?.accountType) ===
                          AccountType.MILITARY
                        ) {
                          navigate(RoutePaths.FINANCIAL_INFO)
                        } else if (
                          TransactionType.CreateNewCustomerAccount ===
                            Number(state?.context?.selectedTransaction) &&
                          AccountType.LAW_ENFORCEMENT === Number(state?.context?.accountType)
                        ) {
                          navigate(RoutePaths.FINANCIAL_INFO)
                        } else {
                          navigate(RoutePaths.PAYMENT_INFORMATION)
                        }
                      }}
                    >
                      {FormButton.BACK}
                    </Button>
                    <Button
                      disabled={invalid}
                      variant="primary"
                      mode="filled"
                      type="submit"
                    >
                      {isEditFlow
                        ? FormButton.SAVE_AND_CONTINUE
                        : FormButton.CONTINUE}
                    </Button>
                  </div>
                </form>
              )}
            />
          </WrapperContainer>
        </div>
      </div>
    </>
  )
}

export default addShipTo