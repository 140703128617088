/* eslint-disable @typescript-eslint/no-explicit-any */
import {Field} from "react-final-form"
import {QuestionOptionsInterface} from "../../constants/index"
import {composeValidators} from "../../utils/validators"
import {components} from "../../utils/components"

interface Options {
  option: QuestionOptionsInterface
}

const Option = ({option}: Options) => {
  const validation = option.VALIDATION ? [...option.VALIDATION] : []

  return (
    <Field
      name={option.name}
      component={components[option.componentType]}
      type={option.type}
      value={
        typeof option.value === "number"
          ? option.value?.toString()
          : option.value
      }
      label={option.label}
      helpertext={option?.helperText}
      validate={
        Array.isArray(validation) ? composeValidators(...validation) : undefined
      }
      key={option.id}
      id={option.id}
      htmlFor={option.id}
      data-testid={option?.dataTestId}
      selectOptions={option.options}
      helperText={option.helperText}
      parse={option?.format}
      disabled={option.disabled}
      defaultValue={option?.defaultValue}
    />
  )
}

export default Option
