import React from "react"
interface SpinnerProps {
  loadingMessage?: string
}

export const Spinner = ({
  loadingMessage = "Generating the Document...",
}: SpinnerProps) => {
  return (
    <div className="fixed inset-0 z-20 flex flex-col items-center justify-center bg-white">
      <img
        src={process.env.REACT_APP_URL + "/images/spinner.gif"}
        className="h-[150px] w-[150px]"
        alt="spinner"
      />
      <h2 className="my-2 text-lg font-bold">{loadingMessage}</h2>
    </div>
  )
}
