import {useContext, useState, useEffect} from "react"
import {useNavigate} from "react-router-dom"
import {nacMachineContext} from "../../context"
import {NACEvents} from "../../machine/nac-machine.types"
import {AccountType} from "../../constants"
import {
  PHYSICAL_LOCATION_MAP,
  PhysicalLocation,
  LEGAL_BUSINESS_FORM_MAP,
  LegalBusinessForm,
} from "../../constants/buisness-information"
import {BusinessInfoType} from "../../types/review"
import {BUSINESS_TITLE, EDIT} from "../../constants/review"
import {TransactionType} from "../../constants/select-transaction"

const BusinessInformation = () => {
  const navigate = useNavigate()
  const {state, send} = useContext(nacMachineContext) || {}
  const [businessInfo, setBusinessInfo] = useState<BusinessInfoType>(
    {} as BusinessInfoType,
  )

  useEffect(() => {
    if (Object.keys(state).length > 0) {
      const businessInfoState = state?.context?.businessInformation

      if (
        state?.context?.selectedTransaction ==
        TransactionType.UpdateCustomerAccountProfile
      ) {
        businessInfoState.firstName = state?.context?.shipToQualifier?.firstName
        businessInfoState.lastName = state?.context?.shipToQualifier?.lastName
        businessInfoState.email = state?.context?.shipToQualifier?.email
        businessInfoState.phoneNumber = state?.context?.shipToQualifier?.phone
      }
      businessInfoState.soldToNumber = state?.context?.shipToQualifier?.soldTo

      const contactDetails = {
        contact: {
          title: "Your Contact Information",
          testId: "contact-info",
          data: [
            `${businessInfoState.firstName || ""} ${
              businessInfoState.lastName || ""
            }`,
            businessInfoState.phoneNumber,
            businessInfoState.email,
          ],
        },
      } as BusinessInfoType

      if (Number(state.context.accountType) === AccountType.VET_TECH) {
        const universityInfo = {
          university: {
            title: "University Name",
            testId: "university-info",
            data: [businessInfoState.universityDetails.universityName],
          },
          universityPrimaryContact: {
            title: "University Primary Contact",
            testId: "university-primary-contact",
            data: [
              `${businessInfoState.universityDetails.firstName} ${businessInfoState.universityDetails.lastName}`,
              businessInfoState.universityDetails.phoneNumber,
              businessInfoState.universityDetails.email,
            ],
          },
          staffVeterinarian: {
            title: "Staff Veterinarian",
            testId: "staff-veterinarian",
            data: [
              `${businessInfoState.universityDetails.vetDetails.firstName} ${businessInfoState.universityDetails.vetDetails.lastName}`,
            ],
          },
        }
        setBusinessInfo({...contactDetails, ...universityInfo})
      } else if (
        Number(state.context.accountType) === AccountType.LAW_ENFORCEMENT ||
        Number(state.context.accountType) === AccountType.MILITARY ||
        Number(state.context.accountType) === AccountType.NON_PROFIT
      ) {
        const businessInfo = {
          businessName: {
            title: "Business Name",
            testId: "business-name",
            data: [businessInfoState.businessName],
          },
          businessContact: {
            title: "Business Contact Information",
            testId: "business-contact",
            data: [
              businessInfoState.businessPhoneNum,
              businessInfoState.businessEmail,
            ],
          }
        } as BusinessInfoType
        if (Number(state.context.accountType) === AccountType.MILITARY) {
          businessInfo.vetOnStaff = {
            title: "Vet on Staff",
            testId: "vetOnStaff",
            data: state?.context?.validateMilitary?.staff === "0" ? [
              "Yes", 
              `${state?.context?.validateMilitary?.firstName} ${state?.context?.validateMilitary?.lastName}`,
              state?.context?.validateMilitary?.vetLicense
            ] : ["No"]
          }
          businessInfo.militaryBase = {
            title: "Located on Military Base",
            testId: "militaryBase",
            data: state?.context?.validateMilitary?.location === "0" ? ["Yes"] : ["No"]
          }
        }
        else if (Number(state.context.accountType) === AccountType.NON_PROFIT){
          businessInfo.vetOnStaff = {
            title: "Vet on Staff",
            testId: "vetOnStaff",
            data: state?.context?.validateSpeciality?.staff === "0" ? [
              "Yes", 
              `${state?.context?.validateSpeciality?.firstName} ${state?.context?.validateSpeciality?.lastName}`,
              state?.context?.validateSpeciality?.vetLicense
            ] : ["No"]
          }
        } 
        else {
          businessInfo.vetOnStaff = {
            title: "Vet on Staff",
            testId: "vetOnStaff",
            data: state?.context?.validateLaw?.staff === "0" ? [
              "Yes", 
              `${state?.context?.validateLaw?.firstName} ${state?.context?.validateLaw?.lastName}`,
              state?.context?.validateLaw?.vetLicense
            ] : ["No"]
          }
        }
        setBusinessInfo({...contactDetails, ...businessInfo})
      } else {
        const otherBusinessDetails = {
          businessName: {
            title: "Business Name",
            testId: "other-business-name",
            data: [businessInfoState.businessName],
          },
          businessEstablished: {
            title: "Business Established",
            testId: "businessEstablished",
            data: [businessInfoState.businessDate],
          },
          formationDate: {
            title: "Date of Formation",
            testId: "formationDate",
            data: [businessInfoState.formationDate]
          },
          formationState: {
            title: "State of Formation",
            testId: "formationState",
            data: [businessInfoState.state]
          },
          businessContact: {
            title: "Business Contact Information",
            testId: "otherBusinessContact",
            data: [
              businessInfoState.businessPhoneNum,
              businessInfoState.businessEmail,
            ],
          },
          physicalBusinessLocation: {
            title: "Physical Business Location",
            testId: "physicalBusinessLocation",
            data: [
              PHYSICAL_LOCATION_MAP[
                businessInfoState.physicalLocation as PhysicalLocation
              ],
            ],
          },
          legalForm: {
            title: "Legal Form of Business",
            testId: "legalForm",
            data: [
              LEGAL_BUSINESS_FORM_MAP[
                businessInfoState.legalBusinessform as LegalBusinessForm
              ],
            ],
          },
          displayRetailInformation: {
            title: "Display Retail Information on Hill’s Websites",
            testId: "displayRetailInformation",
            data: [businessInfoState.terms ? "Yes" : "No"],
          }
        } as BusinessInfoType

        /* check if user has entered EIN */
        if (businessInfoState.hasEIN === "0") {
          otherBusinessDetails.federalTaxId = {
            title: "Federal Tax ID",
            testId: "federalTaxId",
            data: [businessInfoState.ein],
          }
        } else if (businessInfoState.hasEIN === "1") {
          otherBusinessDetails.SSN = {
            title: "Social Security Number",
            testId: "SSN",
            data: [businessInfoState.ssn],
          }
        }
        
        /* check if user has entered sold to number */
        if (businessInfoState.soldToNumber) {
          otherBusinessDetails.soldToNumber = {
            title: "Sold To Number",
            testId: "soldToNumber",
            data: [businessInfoState.soldToNumber]
          }
        }

        /* check if user has entered corporate group name */
        if (businessInfoState.corporateGroupName) {
          otherBusinessDetails.corporateGroupName = {
            title: "Corporate Group Name",
            testId: "corporateGroupName",
            data: [businessInfoState.corporateGroupName]
          }
        }


        setBusinessInfo({...contactDetails, ...otherBusinessDetails})
      }
    }
  }, [state])

  const renderBusinessInfo = (businessInfo: BusinessInfoType) => {
    const keys = Object.keys(businessInfo) as Array<keyof BusinessInfoType>
    return keys.map(key => {
      return (
        <div className="col-span-1" data-testid={businessInfo?.[key]?.testId}>
          <dt className="text-sm font-bold">{businessInfo?.[key]?.title}</dt>
          {businessInfo?.[key]?.data.map(data => {
            return (
              <>
                <dd className="text-sm font-light">{data}</dd>
              </>
            )
          })}
        </div>
      )
    })
  }

  return (
    <section className="mt-10 pt-3">
      <div className="flex justify-between pb-5">
        <h2 className="text-lg font-bold">{BUSINESS_TITLE}</h2>
        <button
          className="font-medium text-brand-primary"
          onClick={() => {
            send(NACEvents.EDIT, {data: "businessInfo"})
            navigate(`/business-information`)
          }}
        >
          {EDIT}
        </button>
      </div>
      <div className="border-t border-gray-300">
        {Object.keys(businessInfo).length > 0 ? (
          <div className="mt-6 grid grid-cols-2 gap-6 lg:grid-cols-3">
            {renderBusinessInfo(businessInfo)}
          </div>
        ) : null}
      </div>
    </section>
  )
}

export default BusinessInformation
