import {useContext, useEffect} from "react"
import {useNavigate, useLocation} from "react-router-dom"
import {Form} from "react-final-form"
import {
  WrapperContainer,
  Button,
  Stepper,
  IconInformationCircleFilled,
} from "ui"
import Question from "../utils/Question"
import {
  RoutePaths,
  FormButton,
  QuestionsInterface,
  Options,
  UPDATE_QUESTIONS,
  PHONE_REGEX
} from "../../constants"
import {nacMachineContext} from "../../context"
import {NACEvents, NACStates} from "../../machine/nac-machine.types"
import {
  MILITARY_BILLING_CONTACT_DETAILS,
  BILLING_CONTACT_DETAILS,
  HEADER,
  INVOICE_CONFIRMATION_QUESTIONS,
  PAYMENT_TERMS_HEADER,
  PET_PAYMENT_TERMS,
  PO_QUESTIONS,
  VET_PAYMENT_TERMS,
  VET_QUESTIONS,
} from "../../constants/financial-information"
import {AccountType} from "../../constants/select-account"
import BillingAddress from "./BillingAddress"
import {FinacialInformation} from "../../types"
import {TransactionType} from "../../constants/select-transaction"

const FinancialInformation = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const {state, send} = useContext(nacMachineContext) || {}
  const history = state?.history?.value
  const isEditFlow = history === NACStates.review

  const isVetOrMobileVet = [
    AccountType.VETERINARY,
    AccountType.MOBILE_VETERINARY,
  ].includes(Number(state?.context?.accountType))
  const isMilitaryOrLawEnforcement = [
    AccountType.MILITARY,
    AccountType.LAW_ENFORCEMENT,
  ].includes(Number(state?.context?.accountType))

  const isTransferOwnership =
    Number(state?.context?.selectedTransaction) ===
    TransactionType.TransferOwnership

  const onSubmit = (values: FinacialInformation) => {
    values.agentPhoneNumber = values.agentPhoneNumber.replace(PHONE_REGEX, "");
    values.billingContactPhoneNumber = values.billingContactPhoneNumber.replace(PHONE_REGEX, "");
    send(NACEvents.NEXT, {
      data: {...values, history: state?.history?.value},
    })
    const isMilitaryOrLawEnforcement = [
      AccountType.LAW_ENFORCEMENT,
      AccountType.MILITARY,
    ].includes(Number(state?.context?.accountType))

    const isUpdateProfile =
      Number(state?.context?.selectedTransaction) ===
      TransactionType.UpdateCustomerAccountProfile

    if (isEditFlow) {
      navigate(RoutePaths.REVIEW)
    } else if (
      isMilitaryOrLawEnforcement &&
      Number(values.update) != Options.YES
    ) {
      navigate(RoutePaths.ADD_SHIPTO)
    } else if (isUpdateProfile) {
      if (Number(values.update) === Options.YES) {
        navigate(RoutePaths.UPDATE_ACCOUNT)
      } else {
        navigate(RoutePaths.DATE_SIGN)
      }
    } else {
      navigate(RoutePaths.PAYMENT_INFORMATION)
    }
  }

  type valuesType = {invoiceConfirmation: string; orderConfirmation: string}

  const renderSubQuestion = (
    question: QuestionsInterface<Options>,
    values: valuesType,
    index: number,
    fieldName: string,
  ) => {
    if (
      question?.SUB_QUESTIONS &&
      question?.SUB_QUESTIONS[0].EXPECTED_PARENT_VALUE.includes(
        parseInt(values[fieldName as keyof valuesType]),
      )
    ) {
      return (
        <>
          <Question
            questions={question?.SUB_QUESTIONS[0].QUESTIONS}
            renderSubQuestion={renderSubQuestion}
            values={values}
            subQuestion={true}
          />
        </>
      )
    }
  }

  useEffect(() => {
    if (state?.value !== NACStates.financeInfo) {
      send(NACEvents.INIT, {data: {route: location.pathname}})
    }
  }, [])

  return (
    <>
      <Stepper currentStep={2} totalSteps={5} />
      <h2 className="my-4 text-sm font-bold">{HEADER}</h2>
      <div className="flex justify-center">
        <div className="w-full sm:w-[520px]">
          <WrapperContainer>
            <Form
              onSubmit={onSubmit}
              initialValues={{...state?.context?.financialInformation}}
              render={({handleSubmit, invalid, values}) => (
                <form onSubmit={handleSubmit}>
                  {Number(state?.context?.accountType) ==
                  AccountType.MILITARY ? (
                    <Question
                      questions={MILITARY_BILLING_CONTACT_DETAILS}
                      renderSubQuestion={renderSubQuestion}
                      values={values}
                    />
                  ) : (
                    <Question
                      questions={BILLING_CONTACT_DETAILS}
                      renderSubQuestion={renderSubQuestion}
                      values={values}
                    />
                  )}

                  <BillingAddress values={values as FinacialInformation} />
                  <Question
                    questions={INVOICE_CONFIRMATION_QUESTIONS}
                    renderSubQuestion={renderSubQuestion}
                    values={values}
                  />

                  {isVetOrMobileVet && (
                    <Question
                      questions={VET_QUESTIONS}
                      renderSubQuestion={renderSubQuestion}
                      values={values}
                    />
                  )}
                  {isMilitaryOrLawEnforcement && (
                    <Question
                      questions={PO_QUESTIONS}
                      renderSubQuestion={renderSubQuestion}
                      values={values}
                    />
                  )}

                  {/* 
                    Show Update question only if the selected transaction is Update Customer Account Profile
                  */}
                  {Number(state?.context?.selectedTransaction) ===
                    TransactionType.UpdateCustomerAccountProfile && (
                    <Question questions={UPDATE_QUESTIONS} values={values} />
                  )}

                  {displayPaymentTerms(state?.context?.accountType, state)}
                  <div className="flex justify-end gap-8">
                    <Button
                      variant="text"
                      onClick={() => {
                        const history = state?.history?.value
                        send(NACEvents.BACK, {
                          data: {history: history},
                        })
                        if (history == NACStates.review) {
                          navigate(RoutePaths.REVIEW)
                        } else if (
                          state?.context?.selectedTransaction ==
                          TransactionType.UpdateCustomerAccountProfile
                        ) {
                          navigate(RoutePaths.UPDATE_ACCOUNT)
                        } else {
                          const backRoute = isEditFlow
                            ? RoutePaths.REVIEW
                            : RoutePaths.BUISNESS_INFORMATION

                          navigate(backRoute)
                        }
                      }}
                    >
                      {FormButton.BACK}
                    </Button>
                    <Button
                      disabled={invalid}
                      variant="primary"
                      mode="filled"
                      type="submit"
                    >
                      {isTransferOwnership || isEditFlow
                        ? FormButton.SAVE_AND_CONTINUE
                        : FormButton.CONTINUE}
                    </Button>
                  </div>
                </form>
              )}
            />
          </WrapperContainer>
        </div>
      </div>
    </>
  )
}

function displayPaymentTerms(
  accountType: AccountType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any,
): JSX.Element {
  let paymentTermsMessage = ""

  if (Number(accountType) == AccountType.MILITARY) {
    if (state?.context?.validateMilitary?.staff == Options.YES) {
      paymentTermsMessage = VET_PAYMENT_TERMS
    } else {
      paymentTermsMessage = PET_PAYMENT_TERMS
    }
  } else if (Number(accountType) == AccountType.LAW_ENFORCEMENT) {
    if (state?.context?.validateLaw?.staff == Options.YES) {
      paymentTermsMessage = VET_PAYMENT_TERMS
    } else {
      paymentTermsMessage = PET_PAYMENT_TERMS
    }
  } else {
    return <></>
  }

  return (
    <div className="border-t-2 border-black p-3">
      <div className="flex gap-4">
        <div className="mt-1 h-5 w-5">
          <IconInformationCircleFilled />
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-base font-bold text-black">
            {PAYMENT_TERMS_HEADER}
          </p>
          <p className="text-brand-accent-gray-light">{paymentTermsMessage}</p>
        </div>
      </div>
    </div>
  )
}

export default FinancialInformation
