import { ShipToLocation } from "../constants/shipto/how-many-ship-to"
import { NACMachineContext } from "../machine"

export function transformTransferOwnershipDetails(context: NACMachineContext) {
  const { shipToQualifier, howManyShipTo } = context
  return {
    transferOfOwnershipDate: shipToQualifier?.transferDate,
    reason: shipToQualifier?.transfer,
    areAllShipToLocationsIncluded: howManyShipTo == ShipToLocation.ALL,
  }
}
