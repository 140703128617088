import {useContext, useState, useEffect} from "react"
import {useNavigate} from "react-router-dom"
import {nacMachineContext} from "../../context"
import {NACEvents} from "../../machine/nac-machine.types"
import {
  PREFERRED_PAYMENT_MAP,
  PreferredPaymentType,
} from "../../constants/payment-information"
import {PaymentInformationType} from "../../types/review"
import {PAYMENT_TITLE, EDIT} from "../../constants/review"

const PaymentInformation = () => {
  const navigate = useNavigate()
  const {state, send} = useContext(nacMachineContext) || {}
  const [paymentInfo, setPaymentInfo] = useState<PaymentInformationType>(
    {} as PaymentInformationType,
  )

  useEffect(() => {
    if (Object.keys(state).length > 0) {
      const paymentInfoState = state.context.paymentInformation
      const AddBankDetails = [
        PreferredPaymentType.AUTO_DEBIT_20_DAYS,
        PreferredPaymentType.AUTO_DEBIT_NEXT_DAY,
        PreferredPaymentType.AUTO_DEBIT_30_DAYS,
      ].includes(paymentInfoState.paymentMethod)

      const paymentDetails = {
        prefferdMethod: {
          title: "Preferred Payment Method",
          data: [
            PREFERRED_PAYMENT_MAP[
              paymentInfoState.paymentMethod as PreferredPaymentType
            ],
          ],
        },
      } as PaymentInformationType

      if (AddBankDetails) {
        paymentDetails.accountNumber = {
          title: "Bank Account Number",
          data: [paymentInfoState.accountNumber],
        }
        paymentDetails.routingNumber = {
          title: "Bank Routing Number",
          data: [paymentInfoState.routingNumber],
        }
        paymentDetails.bankName = {
          title: "Bank Name",
          data: [paymentInfoState.bankName],
        }
      }
      setPaymentInfo(paymentDetails)
    }
  }, [])

  const renderPaymentInfo = (paymentInfo: PaymentInformationType) => {
    const keys = Object.keys(paymentInfo) as Array<keyof PaymentInformationType>
    return keys.map(key => {
      return (
        <div className="col-span-1">
          <dt className="text-sm font-bold">{paymentInfo?.[key]?.title}</dt>
          {paymentInfo?.[key]?.data.map(data => {
            return (
              <>
                <dd className="text-sm font-light">{data}</dd>
              </>
            )
          })}
        </div>
      )
    })
  }

  return (
    <section className="mt-10 pt-3">
      <div className="flex justify-between pb-5">
        <h2 className="text-lg font-bold">{PAYMENT_TITLE}</h2>
        <button
          className="font-medium text-brand-primary"
          onClick={() => {
            send(NACEvents.EDIT, {data: "paymentInfo"})
            navigate(`/payment-information`)
          }}
        >
          {EDIT}
        </button>
      </div>
      <div className="border-t border-gray-300">
        <div className="mt-6 grid grid-cols-2 gap-6 lg:grid-cols-3">
          {renderPaymentInfo(paymentInfo)}
        </div>
      </div>
    </section>
  )
}

export default PaymentInformation
