import { AccountType } from "../constants"
import { PreferredPaymentType } from "../constants/payment-information"
import { PaymentInformation } from "../types"

export const TPaymentInfo = {
  [PreferredPaymentType.AUTO_DEBIT_20_DAYS]: "ADC_20",
  [PreferredPaymentType.AUTO_DEBIT_30_DAYS]: "ADC_30",
  [PreferredPaymentType.AUTO_DEBIT_NEXT_DAY]: "ADC_NEXT_DAY",
  [PreferredPaymentType.CREDIT_CARD_NEXT_DAY_POST_INVOICE]: "CC_POST_INVOICE",
  [PreferredPaymentType.MAIL_CHECK_20_DAYS]: "CHECK_20",
  [PreferredPaymentType.MAIL_CHECK_30_DAYS]: "CHECK_30",
}

export function transformPaymentInfo(
  accountType: AccountType,
  paymentInformation?: PaymentInformation
) {
  const { paymentMethod, accountNumber, routingNumber, bankName } =
    paymentInformation || {}
  if (
    [
      AccountType.VET_TECH,
      AccountType.MILITARY,
      AccountType.LAW_ENFORCEMENT,
    ].includes(Number(accountType)) ||
    !paymentInformation
  )
    return

  const paymentDetails = {
    accountNumber: accountNumber,
    routingNumber: routingNumber,
    bankName: bankName,
  }

  return paymentMethod
    ? [
        "AUTO_DEBIT_20_DAYS",
        "AUTO_DEBIT_NEXT_DAY",
        "AUTO_DEBIT_30_DAYS",
      ].includes(paymentMethod)
      ? {
          preferredModeOfPayment: TPaymentInfo[paymentMethod],
          paymentDetails,
        }
      : { preferredModeOfPayment: TPaymentInfo[paymentMethod] }
    : undefined
}
