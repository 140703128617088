import {Options, QuestionsInterface} from "."
import {required} from "../utils/validators"

export const REVIEW_TIP = {
  title: "IMPORTANT:",
  description:
    " This is your last opportunity to make changes prior to viewing and signing your contract. Carefully review your information below and adjust, as-needed, using the “Edit” button in each respective section.",
}

export const BUSINESS_TITLE = "Business Information"
export const ORDER_TITLE = "Ordering Information"
export const PAYMENT_TITLE = "Payment Information"
export const AUTHORIZER_TITLE = "Authorizer Information"
export const CUSTOMER_NOTES_TITLE = "Customer Notes"
export const EDIT = "Edit"

export const DELIVERY_CLOSED = "Closed"
export const DELIVERY_NOT_ACCEPTED = "Not Accepting Deliveries"

export const SHIPPING_TITLE = {
  locationName: "Location Name",
  businessName: "Corporation or Business Network",
  deliveryWindow: "Delivery Window",
  primaryContact: "Primary Delivery Contact",
  shippingAddress: "Shipping Address",
  notifySMS: "Notify Primary Delivery Contact Via SMS",
  phone: "Phone Number",
  deliverTo: "Deliver To",
  primaryPhone: "Primary Delivery Contact Mobile Phone",
  salesTax: "Should Hill’s charge sales tax on purchases for this location?",
  steps: "Are there steps to go up or down in making deliveries?",
  primaryPermissions: "Primary Delivery Contact Permissions",
  operationalHours: "Hours of Operation",
  building: "Building or Location to deliver",
  pallets: "Does this location have the ability to receive product on pallets?",
  ramp: "Is there a ramp for hand truck or pallet jack use?",
  intersection: "Closest Major Intersection",
  instructions: "Delivery Instructions",
  appointment: "Is a delivery appointment required?",
  totalContacts: `Total number of contacts added for this location. You may view and update all contacts using the "Edit" link above.`,
  outsideHours: "Does this location accept deliveries outside of the operating hours?",
  entry: "Where should we enter for delivery at this location?",
  baseEntry: "What credentialing is required for delivery personnel to enter the base?"
}

export const DELIVERY_LOCATION_OPTIONS = {
  frontDoor: "FRONT_DOOR",
  backDoor: "BACK_DOOR",
  sideDoor: "SIDE_DOOR",
  receivingDock: "RECEIVING_DOCK"
}

export const PERMISSIONS = {
  viewDeliveryStatus: "View delivery status",
  notifyRouteDelay: "Notification of a route delay",
  sendFeedProgramDetails: "Access VIP Market",
  sendClinicDetails: "Access Hill's Retail Order",
  marketingCommunications: "View invoices",
}

export const DAY_LIST = [
  {
    key: "sunday",
    label: "Sunday",
  },
  {
    key: "monday",
    label: "Monday",
  },
  {
    key: "tuesday",
    label: "Tuesday",
  },
  {
    key: "wednesday",
    label: "Wednesday",
  },
  {
    key: "thursday",
    label: "Thursday",
  },
  {
    key: "friday",
    label: "Friday",
  },
  {
    key: "saturday",
    label: "Saturday",
  },
]

export const CUSTOMER_NOTES_QUESTION: QuestionsInterface<Options>[] = [
  {
    QUESTION_TEXT: `To help us process your request more quickly, please summarize the updates you would like to make and
    add any additional notes that might be needed`,
    OPTIONS: [
      {
        label: "Notes",
        name: "custNotes",
        id: "custNotes",
        dataTestId: "custNotes",
        type: "text",
        componentType: "TextArea",
        helperText: "(e.g., new account request, updating billing contact information, adding a ship-to location, etc.)"
      }
    ],
    VALIDATION: [required]
  }
]
