import {useContext} from "react"
import {
  BILLING_ADDRESS_QUESTIONS,
  BILLING_ADDRESS_QUESTIONS_DISABLED,
  BILLING_STATE,
  BILLING_STATE_DISABLED,
  BILLING_ZIP_CODE,
  BILLING_ZIP_CODE_DISABLED,
} from "../../constants/financial-information"
import Question from "../utils/Question"
import Option from "../utils/Option"
import {FinacialInformation} from "../../types"
import {nacMachineContext} from "../../context"
import {TransactionType} from "../../constants/select-transaction"

interface BillingAddressProps {
  values: FinacialInformation
}

const BillingAddress = ({values}: BillingAddressProps) => {
  const {state} = useContext(nacMachineContext) || {}
  return (
    <>
      <Question
        questions={
          state?.context?.selectedTransaction ==
          TransactionType.UpdateCustomerAccountProfile
            ? BILLING_ADDRESS_QUESTIONS_DISABLED
            : BILLING_ADDRESS_QUESTIONS
        }
        renderSubQuestion={() => undefined}
        values={values}
      />

      <div className="-mt-9 mb-10 grid gap-5 md:grid-cols-2">
        <div className="col-span-1">
          <Option
            option={
              state?.context?.selectedTransaction ==
              TransactionType.UpdateCustomerAccountProfile
                ? BILLING_STATE_DISABLED
                : BILLING_STATE
            }
          />
        </div>
        <div className="col-span-1">
          <Option
            option={
              state?.context?.selectedTransaction ==
              TransactionType.UpdateCustomerAccountProfile
                ? BILLING_ZIP_CODE_DISABLED
                : BILLING_ZIP_CODE
            }
          />
        </div>
      </div>
    </>
  )
}

export default BillingAddress
