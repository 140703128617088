/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useContext} from "react"
import {AlertDialog} from "ui"
import {Field, Form} from "react-final-form"
import {composeValidators} from "../../utils/validators"
import {
  CONTACT_INFORMATION_OPTIONS,
  CONFIGURE_PERMISSIONS_OPTIONS,
  CONFIGURE_PERMISSIONS_LABEL,
  PRIMARY_CONTACT_TITLE,
  ALERT_CONFIRM_LABEL,
  CONTACT_INFORMATION_SMS,
} from "../../constants/contacts"
import {ContactDetails} from "../../types"
import {nacMachineContext} from "../../context"

interface Props {
  fields: any
  updateContacts: (values: ContactDetails[]) => void
  openPrimaryModal: boolean
  setOpenPrimaryModal: React.Dispatch<React.SetStateAction<boolean>>
  primaryContactId: number
  contacts: ContactDetails[]
}

const PrimaryContactAlert = ({
  updateContacts,
  openPrimaryModal,
  setOpenPrimaryModal,
  contacts = [],
  primaryContactId,
}: Props) => {
  const {state} = useContext(nacMachineContext) || {}
  const primaryContact = contacts.find(
    contact => contact.id == primaryContactId,
  )

  const handleSave = (values: any) => {
    if (!primaryContact) {
      updateContacts([
        ...contacts,
        {
          ...values,
          id: contacts.length + 1,
        },
      ])
    } else {
      const updatedContacts: ContactDetails[] = contacts.map(contact => {
        return contact.id == primaryContactId
          ? {...values, id: primaryContactId}
          : contact
      })
      updateContacts(updatedContacts)
    }

    setOpenPrimaryModal(false)
  }

  const showSMSField = () => {
    const {name: optionName, validators, ...rest} = CONTACT_INFORMATION_SMS
    return (
      <Field
        name={`${optionName}`}
        id={`${optionName}`}
        htmlFor={`${optionName}`}
        validate={
          Array.isArray(validators)
            ? composeValidators(...validators)
            : undefined
        }
        {...rest}
      />
    )
  }

  return (
    <Form
      onSubmit={handleSave}
      initialValues={{...primaryContact}}
      render={({handleSubmit, values, invalid}) => (
        <form onSubmit={handleSubmit}>
          <AlertDialog
            open={openPrimaryModal}
            setOpen={setOpenPrimaryModal}
            title={PRIMARY_CONTACT_TITLE.lablel}
            titleHelper={PRIMARY_CONTACT_TITLE.helperText}
            size="lg"
            disableClose={true}
            labelConfirm={ALERT_CONFIRM_LABEL}
            disableSubmit={invalid}
            handleConfirm={() => handleSave(values)}
          >
            <div className="mb-2 grid gap-12 pt-2 md:grid-cols-2">
              <div className="col-span-1" data-test="primary-contact-alert">
                {CONTACT_INFORMATION_OPTIONS.filter(
                  option => option.name !== "notifications",
                ).map((option, index) => {
                  const {name: optionName, validators, ...rest} = option
                  return (
                    <Field
                      name={`${optionName}`}
                      id={`${optionName}`}
                      htmlFor={`${optionName}`}
                      key={index}
                      validate={
                        Array.isArray(validators)
                          ? composeValidators(...validators)
                          : undefined
                      }
                      {...rest}
                    />
                  )
                })}
                {values?.contacts && values?.contacts[0].notifications
                  ? showSMSField()
                  : null}
              </div>
              <div className="col-span-1">
                <h5 className="mb-1 mt-2 text-sm font-semibold uppercase">
                  {CONFIGURE_PERMISSIONS_LABEL}
                </h5>
                {CONFIGURE_PERMISSIONS_OPTIONS.map((option, index) => {
                  const {name: optionName, ignoreAccounts, ...rest} = option
                  if (
                    !ignoreAccounts?.includes(
                      Number(state?.context?.accountType),
                    )
                  ) {
                    if (index == 0) {
                      return (
                        <Field
                          name={`${optionName}`}
                          id={`${optionName}`}
                          htmlFor={`${optionName}`}
                          key={index}
                          checked={true}
                          disabled={true}
                          {...rest}
                        />
                      )
                    }
                    return (
                      <Field
                        name={`${optionName}`}
                        id={`${optionName}`}
                        htmlFor={`${optionName}`}
                        key={index}
                        {...rest}
                      />
                    )
                  }
                })}
              </div>
            </div>
          </AlertDialog>
        </form>
      )}
    />
  )
}

export default PrimaryContactAlert
