import {useContext} from "react"
import {nacMachineContext} from "../../../context"
import Option from "../../utils/Option"
import {DELIVERY_LOCATION_ENTRY_QUESTION} from "../../../constants/shipto/add"
import {TransactionType} from "../../../constants/select-transaction"
import {required} from "../../../utils/validators"
import {QuestionOptionsInterface} from "../../../constants"

const LocationEntry = () => {
  const {state} = useContext(nacMachineContext) || {}
  const UPDATE = (value : QuestionOptionsInterface) => {
    const validation = value.VALIDATION?.filter((item : unknown) => item !== required)
    value.VALIDATION = validation
    return value
  }
  const isUpdateProfile = state?.context?.selectedTransaction ==
    TransactionType.UpdateCustomerAccountProfile
  
  return (
    <div className="mb-10 flex flex-col">
      <p className="mb-3 text-gray-700">
        <span className="font-bold">
          {DELIVERY_LOCATION_ENTRY_QUESTION.question}
        </span>
      </p>
      <div className="grid gap-5 md:grid-cols-2">
        <Option option={isUpdateProfile ?
          UPDATE(DELIVERY_LOCATION_ENTRY_QUESTION.option) : 
          DELIVERY_LOCATION_ENTRY_QUESTION.option
        } />
      </div>
    </div>
  )
}

export default LocationEntry