import { AccountType, Options } from "../constants"
import { TransactionType } from "../constants/select-transaction"
import { NACMachineContext } from "../machine"

export function transformVetDetails(context: NACMachineContext) {
  const {
    validateSpeciality,
    validateMilitary,
    validateLaw,
    businessInformation,
    accountType,
    financialInformation,
  } = context
  switch (Number(accountType)) {
    case AccountType.MILITARY:
      return {
        firstName: validateMilitary?.firstName,
        lastName: validateMilitary?.lastName,
        licenseNo: validateMilitary?.vetLicense,
      }
    case AccountType.LAW_ENFORCEMENT:
      return {
        firstName: validateLaw?.firstName,
        lastName: validateLaw?.lastName,
        licenseNo: validateLaw?.vetLicense,
      }
    case AccountType.NON_PROFIT:
      return {
        firstName: validateSpeciality?.firstName,
        lastName: validateSpeciality?.lastName,
        licenseNo: validateSpeciality?.vetLicense,
      }
    case AccountType.VET_TECH:
      return {
        firstName:
          businessInformation?.universityDetails?.vetDetails?.firstName,
        lastName: businessInformation?.universityDetails?.vetDetails?.lastName,
      }
    case AccountType.VETERINARY:
    case AccountType.MOBILE_VETERINARY:
      return {
        firstName: financialInformation?.vetfirstName,
        lastName: financialInformation?.vetlastName,
        licenseNo: financialInformation?.vetLicense,
      }
    default:
      return undefined
  }
}

export function getIsVet(context: NACMachineContext) {
  const {
    accountType,
    selectedTransaction,
    validateSpeciality,
    validateMilitary,
    validateLaw,
  } = context
  if (
    [
      TransactionType.AddNewShipToLocation,
    ].includes(Number(selectedTransaction)) === false
  ) {
    switch (Number(accountType)) {
      case AccountType.VETERINARY:
      case AccountType.MOBILE_VETERINARY:
        return true
      case AccountType.NON_PROFIT:
        return validateSpeciality?.staff == Options.YES
      case AccountType.MILITARY:
        return validateMilitary?.staff == Options.YES
      case AccountType.LAW_ENFORCEMENT:
        return validateLaw?.staff == Options.YES
    }
  }
  return false
}
