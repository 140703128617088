import {useState, useContext, useEffect} from "react"
import {useNavigate, useLocation} from "react-router-dom"
import {Form} from "react-final-form"
import {WrapperContainer, AlertDialog, Button} from "ui"
import Question from "../../utils/Question"
import {
  VET_TECH_ALERT,
  VET_TECH_QUESTIONS,
  SCHOOLS_HILLS_EMAIL,
} from "../../../constants/accounts/vet-tech"
import {RoutePaths, FormButton, Options} from "../../../constants"
import {nacMachineContext} from "../../../context"
import {NACEvents, NACStates} from "../../../machine/nac-machine.types"

const VetTech = () => {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const {state, send} = useContext(nacMachineContext) || {}

  const onSubmit = (values: {
    accredition: string
    order: string
    program: string
  }) => {
    const {accredition, order} = values
    if (
      parseInt(accredition) === Options.NO ||
      parseInt(order) === Options.YES
    ) {
      setOpen(true)
    } else {
      send(NACEvents.NEXT, {data: {...values}})
      navigate(RoutePaths.SELECT_TRANSACTION_TYPE)
    }
  }

  const handleConfirm = () => {
    window.open(`mailto:${SCHOOLS_HILLS_EMAIL}`, "_blank")
  }

  const renderAlertBox = () => {
    return (
      <AlertDialog
        open={open}
        setOpen={setOpen}
        title={VET_TECH_ALERT.title}
        description={VET_TECH_ALERT.description}
        labelConfirm={VET_TECH_ALERT.labelConfirm}
        handleConfirm={handleConfirm}
      />
    )
  }

  useEffect(() => {
    if (state?.value !== NACStates.validateUniversity) {
      send(NACEvents.INIT, {data: {route: location.pathname}})
    }
  }, [])

  return (
    <div className="flex justify-center">
      <div className="w-full sm:w-[520px]">
        <WrapperContainer>
          <Form
            initialValues={{
              accredition: state.context.validateUniversity?.accredition,
              order: state.context.validateUniversity?.order,
              program: state.context.validateUniversity?.program,
            }}
            onSubmit={onSubmit}
            render={({handleSubmit, invalid, values}) => (
              <form onSubmit={handleSubmit}>
                <Question questions={VET_TECH_QUESTIONS} values={values} />
                <div className="flex justify-end gap-8">
                  <Button
                    variant="text"
                    onClick={() => {
                      send(NACEvents.BACK)
                      navigate(RoutePaths.ACCOUNT_TYPE)
                    }}
                  >
                    {FormButton.BACK}
                  </Button>
                  <Button
                    disabled={invalid}
                    variant="primary"
                    mode="filled"
                    type="submit"
                  >
                    {FormButton.CONTINUE}
                  </Button>
                </div>
              </form>
            )}
          />
          {renderAlertBox()}
        </WrapperContainer>
      </div>
    </div>
  )
}

export default VetTech
