import {useContext, useState, useEffect} from "react"
import {useNavigate} from "react-router-dom"
import {IconEye} from "ui"
import {nacMachineContext} from "../../context"
import {NACEvents} from "../../machine/nac-machine.types"
import {Options, AccountType} from "../../constants"
import {
  IGNORE_SALES_TAX,
  SHOW_APPOINTMENT,
  SHOW_BUILDING,
} from "../../constants/shipto/add"
import {AddShipTo, OperationalHours, ContactInfo} from "../../types/"
import {ShippingInformationType} from "../../types/review"
import {
  SHIPPING_TITLE,
  DAY_LIST,
  DELIVERY_CLOSED,
  DELIVERY_NOT_ACCEPTED,
  EDIT,
  PERMISSIONS,
  DELIVERY_LOCATION_OPTIONS,
} from "../../constants/review"
import {TransactionType} from "../../constants/select-transaction"
import {UpdateAccountOptions} from "../../constants/update-account-options"

type Props = {
  shipTo: AddShipTo
  index: number
  length: number
  viewAllShipTo: boolean
  onSetViewAllShipTo: React.Dispatch<React.SetStateAction<boolean>>
}

const ShippingInformation = ({
  shipTo,
  index,
  length,
  viewAllShipTo,
  onSetViewAllShipTo,
}: Props) => {
  const navigate = useNavigate()
  const {state, send} = useContext(nacMachineContext) || {}
  const [shippingInfo, setShippingInfo] = useState<ShippingInformationType>(
    {} as ShippingInformationType,
  )
  const [addShipToState, setAddShipToState] = useState<AddShipTo>(
    {} as AddShipTo,
  )

  const isUpdateContactsFlow =
    state?.context?.selectedTransaction ==
      TransactionType.UpdateCustomerAccountProfile &&
    (state?.context?.updateAccount ===
      UpdateAccountOptions.CLINIC_SHIPPING_LOCATION_CONTACTS ||
      state?.context?.updateAccount ===
        UpdateAccountOptions.UNIVERSITY_SHIPPING_LOCATION_CONTACTS)

  useEffect(() => {
    if (Object.keys(state).length > 0) {
      const ShippingInfoState = shipTo
      setAddShipToState(ShippingInfoState)
      const shippingDetails = {
        locationName: {
          title: SHIPPING_TITLE.locationName,
          testId: "shipping-location",
          data: [ShippingInfoState?.location],
        },
        shippingAddress: {
          title: SHIPPING_TITLE.shippingAddress,
          data: isUpdateContactsFlow
            ? []
            : [
                ShippingInfoState?.shippingAddress?.street,
                ShippingInfoState?.shippingAddress?.apartment,
                ShippingInfoState?.shippingAddress?.city &&
                  ShippingInfoState?.shippingAddress?.zip &&
                  ShippingInfoState?.shippingAddress?.state &&
                  `${ShippingInfoState?.shippingAddress?.city}, ${ShippingInfoState?.shippingAddress?.state} ${ShippingInfoState?.shippingAddress?.zip}`,
              ],
        },
        phone: {
          title: SHIPPING_TITLE.phone,
          data: [ShippingInfoState?.phone],
        },
        outsideHours: {
          title: SHIPPING_TITLE.outsideHours,
          data: ShippingInfoState?.outsideHours == Options.YES ? ["Yes"] : ["No"]
        },
        entry: {
          title: SHIPPING_TITLE.entry,
          data: ShippingInfoState?.entry === DELIVERY_LOCATION_OPTIONS.frontDoor ? ["Front Door"] : 
            ShippingInfoState?.entry === DELIVERY_LOCATION_OPTIONS.backDoor ? ["Back Door"] :
            ShippingInfoState?.entry === DELIVERY_LOCATION_OPTIONS.sideDoor ? ["Side Door"] :
            ["Receiving Dock"]
        },
        steps: {
          title: SHIPPING_TITLE.steps,
          data: [
            Number(ShippingInfoState?.stepsAvailability) === 0
              ? "Yes"
              : Number(ShippingInfoState?.stepsAvailability) === 1
              ? "No"
              : "",
          ],
        },
        pallets: {
          title: SHIPPING_TITLE.pallets,
          data: [
            Number(ShippingInfoState?.palletsAvailability) === 0
              ? "Yes"
              : Number(ShippingInfoState?.palletsAvailability) === 1
              ? "No"
              : "",
          ],
        },
        ramp: {
          title: SHIPPING_TITLE.ramp,
          data: [
            Number(ShippingInfoState?.rampAvailability) === 0
              ? "Yes"
              : Number(ShippingInfoState?.rampAvailability) === 1
              ? "No"
              : "",
          ],
        },
        intersection: {
          title: SHIPPING_TITLE.intersection,
          data: [ShippingInfoState?.intersection],
        },
        instructions: {
          title: SHIPPING_TITLE.instructions,
          data: [ShippingInfoState?.instructions],
        },
      } as ShippingInformationType

      if (Number(state?.context?.accountType) === AccountType.MILITARY) {
        shippingDetails.baseEntry = {
          title: SHIPPING_TITLE.baseEntry,
          data: [String(ShippingInfoState?.baseEntry)]
        }
      }

      if (ShippingInfoState?.corporateName) {
        shippingDetails.corporateName = {
          title: SHIPPING_TITLE.businessName,
          data: [ShippingInfoState?.corporateName],
        }
      }

      if (!IGNORE_SALES_TAX.includes(state.context.accountType)) {
        shippingDetails.salesTax = {
          title: SHIPPING_TITLE.salesTax,
          data: [
            Number(ShippingInfoState?.salesTax) === 0
              ? "Yes"
              : Number(ShippingInfoState?.salesTax) === 1
              ? "No"
              : "",
          ],
        }
      }
      if (SHOW_BUILDING.includes(state.context.accountType)) {
        shippingDetails.building = {
          title: SHIPPING_TITLE.building,
          data: [ShippingInfoState?.building],
        }
      }
      if (SHOW_APPOINTMENT.includes(state.context.accountType)) {
        shippingDetails.appointment = {
          title: SHIPPING_TITLE.appointment,
          data: [
            Number(ShippingInfoState?.appointment) === 0
              ? "Yes"
              : Number(ShippingInfoState?.appointment) === 1
              ? "No"
              : "",
          ],
        }
      }

      /* check if user has entered ship to number */
      if (ShippingInfoState.shipToAccountNumber !== undefined) {
        if (ShippingInfoState.shipToAccountNumber > 4) {
          shippingDetails.shipToNumber = {
            title: "Ship To Number",
            testId: "shipToNumber",
            data: [String(ShippingInfoState.shipToAccountNumber)]
          }
        } else {
          shippingDetails.shipToNumber = {
            title: "Ship To Number",
            testId: "shipToNumber",
            data: [String(ShippingInfoState.shipToAccountNumber + 1)]
          }
        }
        
      }

      setShippingInfo(shippingDetails)
    }
  }, [state])

  const renderShippingInfo = (shippingInfo: ShippingInformationType) => {
    const keys = Object.keys(shippingInfo) as Array<
      keyof ShippingInformationType
    >
    return keys.map(key => {
      return (
        <div className="col-span-1" data-testid={shippingInfo?.[key]?.testId}>
          <dt className="text-sm font-bold">{shippingInfo?.[key]?.title}</dt>
          {shippingInfo?.[key]?.data.map(data => {
            return (
              <>
                <dd className="text-sm font-light">{data}</dd>
              </>
            )
          })}
        </div>
      )
    })
  }

  const renderOperationalHours = (
    operationalHours: OperationalHours,
    closedMessage: string,
  ) => {
    return DAY_LIST.map(day => {
      const dayKey = day.key as keyof OperationalHours
      return (
        <tr>
          <td className="pr-3">{day.label}</td>
          <td>
            {operationalHours?.[dayKey].map(hours => {
              return (
                <p>
                  {hours.duration.trim() === ""
                    ? closedMessage
                    : hours.duration.trim()}
                </p>
              )
            })}
          </td>
        </tr>
      )
    })
  }

  const renderPrimaryContact = (contactInformation: ContactInfo) => {
    const primaryContact = contactInformation.contacts.find(
      contact =>
        Number(contact.id) === Number(contactInformation.primaryContact),
    )
    return (
      <>
        <div className="col-span-1">
          <dt className="text-sm font-bold">{SHIPPING_TITLE.primaryContact}</dt>
          <dd className="text-sm font-light">{`${primaryContact?.firstName} ${primaryContact?.lastName}`}</dd>
          <dd className="text-sm font-light">{primaryContact?.title}</dd>
          <dd className="text-sm font-light">{primaryContact?.phone}</dd>
          <dd className="text-sm font-light">{primaryContact?.email}</dd>
        </div>
        {/* SMS Notification */}
        {primaryContact?.notifications && (
          <div className="col-span-1">
            <dt className="text-sm font-bold">{SHIPPING_TITLE.notifySMS}</dt>
            <dd className="text-sm font-light">
              {primaryContact?.notifications ? "Yes" : "No"}
            </dd>
          </div>
        )}
        {/* SMS Notification Phone Number */}
        {primaryContact?.notifications && (
          <div className="col-span-1">
            <dt className="text-sm font-bold">{SHIPPING_TITLE.primaryPhone}</dt>
            <dd className="text-sm font-light">{primaryContact?.sms}</dd>
          </div>
        )}
        {/* Primary Contact Permissions */}
        <div className="col-span-1">
          <dt className="text-sm font-bold">
            {SHIPPING_TITLE.primaryPermissions}
          </dt>
          <dd className="text-sm font-light">
            <ul className="ml-5 list-disc">
              {primaryContact?.viewDeliveryStatus ? (
                <li className="text-sm font-light">
                  {PERMISSIONS.viewDeliveryStatus}
                </li>
              ) : (
                <></>
              )}
              {primaryContact?.notifyRouteDelay ? (
                <li className="text-sm font-light">
                  {PERMISSIONS.notifyRouteDelay}
                </li>
              ) : (
                <></>
              )}
              {primaryContact?.marketingCommunications ? (
                <li className="text-sm font-light">
                  {PERMISSIONS.marketingCommunications}
                </li>
              ) : (
                <></>
              )}
              {primaryContact?.sendFeedProgramDetails ? (
                <li className="text-sm font-light">
                  {PERMISSIONS.sendFeedProgramDetails}
                </li>
              ) : (
                <></>
              )}
              {primaryContact?.sendClinicDetails ? (
                <li className="text-sm font-light">
                  {PERMISSIONS.sendClinicDetails}
                </li>
              ) : (
                <></>
              )}
            </ul>
          </dd>
        </div>
        {/* Total Contacts */}
        <div className="col-span-1">
          <dt className="text-sm font-bold">{SHIPPING_TITLE.totalContacts}</dt>
          <dd className="text-sm font-light">
            {contactInformation.contacts.length}
          </dd>
        </div>
      </>
    )
  }

  return (
    <>
      {Object.keys(state).length > 0 && (
        <section className="mt-10 pt-3">
          <div className="flex justify-between pb-5">
            <h2 className="text-lg font-bold">
              {`Shipping Information (Location ${index + 1} of ${length})`}
            </h2>
            <button
              className="font-medium text-brand-primary"
              onClick={() => {
                send(NACEvents.EDIT, {data: "shippingInfo"})
                navigate(`/shipto-add?id=${index}`)
              }}
            >
              {EDIT}
            </button>
          </div>
          <div className="border-t border-gray-300">
            <div className="mt-6 grid grid-cols-1 gap-6 lg:grid-cols-3">
              {renderShippingInfo(shippingInfo)}
              {/* Primary Contact Details */}
              {addShipToState?.contactInformation &&
                renderPrimaryContact(addShipToState?.contactInformation)}
              {/* Days and hours of Operation */}
              <div className="col-span-1">
                <dt className="text-sm font-bold">
                  {SHIPPING_TITLE.operationalHours}
                </dt>
                <dd className="text-sm font-light">
                  <table className="table-auto">
                    {renderOperationalHours(
                      addShipToState.operationalHours,
                      DELIVERY_CLOSED,
                    )}
                  </table>
                </dd>
              </div>

              {/* Check if delivery outside hours allowed and display Delivery Window */}
              {Number(addShipToState?.outsideHours) === Options.YES && (
                <div className="col-span-1">
                  <dt className="text-sm font-bold">
                    {SHIPPING_TITLE.deliveryWindow}
                  </dt>
                  <dd className="text-sm font-light">
                    <table className="table-auto">
                      {addShipToState.outsideHoursDelivery &&
                        renderOperationalHours(
                          addShipToState.outsideHoursDelivery,
                          DELIVERY_NOT_ACCEPTED,
                        )}
                    </table>
                  </dd>
                </div>
              )}
            </div>
          </div>
          {index === 0 && !viewAllShipTo && length > 1 && (
            <div className="my-10">
              <button
                className="mx-auto flex items-center gap-3 font-bold text-brand-primary"
                onClick={() => onSetViewAllShipTo(true)}
              >
                <span className="h-4 w-5">
                  <IconEye />
                </span>
                <span> View All Ship-To Locations</span>
              </button>
            </div>
          )}
        </section>
      )}
    </>
  )
}

export default ShippingInformation
