export interface SelectItem {
  label: string
  helperText?: string
  value: number
  name: string
  id: string
}

export enum PetIntend {
  YES,
  NO,
}

export enum PetAllowed{
  YES,
  NO,
}


export enum HomeImprovementStore{
  YES,
  NO,
}

export const INTEND_QUESTION =
  "Do you intend to retail Hill’s pet food products?"

export const PET_ALLOWED_QUESTION =
  "Are pet parents allowed to bring their pets into the store?"

export const LOCATION_QUESTION =
  "Is the location a Home Improvement Store (Lowes, Ace Hardware, Menards, etc)?"

export const BLOCKED_INTEND: PetIntend[] = [PetIntend.NO]
export const BLOCKED_PET_ALLOWED: PetAllowed[] = [PetAllowed.NO]
export const BLOCKED_HOME_IMPROVEMENT_STORE: HomeImprovementStore[] = [HomeImprovementStore.YES]

export const PET_INTEND_ALERT = {
  title: "We’re sorry!",
  labelConfirm: "Contact Us",
  contactURL: "https://www.hillspet.com/contact-us",
  description:
    "You must intend to retail Hill’s pet food products to be considered. If you have any questions, you can contact Hill’s using the button below.",
}

export const PET_ALLOWED_ALERT = {
  title: "We’re sorry!",
  labelConfirm: "Contact Us",
  contactURL: "https://www.hillspet.com/contact-us",
  description:
    "To be eligible, your store must permit pets to shop alongside their owners. For further information, please reach out to Hill's via the button below.",
}

export const PETSTORE_LOCATION_ALERT = {
  title: "We’re sorry!",
  labelConfirm: "Contact Us",
  contactURL: "https://www.hillspet.com/contact-us",
  description:
    "At this time, your location does not qualify for an account. If you have any questions or need further information, please feel free to contact Hill's using the button below.",
}


export const PET_INTEND_ITEMS: Array<SelectItem> = [
  {
    label: "Yes",
    value: PetIntend.YES,
    name: "intend",
    id: "vet-yes",
  },
  {
    label: "No",
    value: PetIntend.NO,
    name: "intend",
    id: "vet-no",
  },
]

export const PET_ALLOWED_ITEMS: Array<SelectItem> = [
  {
    label: "Yes",
    value: PetAllowed.YES,
    name: "petAllow",
    id: "pet-yes",
  },
  {
    label: "No",
    value: PetAllowed.NO,
    name: "petAllow",
    id: "pet-no",
  },
]

export const PETSTORE_LOCATION_ITEMS: Array<SelectItem> = [
  {
    label: "Yes",
    value: HomeImprovementStore.YES,
    name: "homeImprovementStore",
    id: "location-yes",
  },
  {
    label: "No",
    value: HomeImprovementStore.NO,
    name: "homeImprovementStore",
    id: "location-no",
  },
]
