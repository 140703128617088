import { createContext } from "react";
import { newCustomerAccountMachine } from "../machine";
import { useMachine } from "@xstate/react";
interface NACMachineProviderProps {
  children: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const nacMachineContext = createContext({} as any);

export const NACMachineProvider = ({ children }: NACMachineProviderProps) => {
  const [state, send] = useMachine(newCustomerAccountMachine);
  return (
    <nacMachineContext.Provider value={{ state, send }}>
      {children}
    </nacMachineContext.Provider>
  );
};
