import {useContext, useEffect} from "react"
import {useNavigate, useLocation} from "react-router-dom"
import {Button, WrapperContainer, CheckMarckIconWithLabel} from "ui"
import {LANDING, RoutePaths} from "../../constants"
import {nacMachineContext} from "../../context"
import {NACEvents, NACStates} from "../../machine"

const LandingPage = (): JSX.Element => {
  const {send, state} = useContext(nacMachineContext)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (state?.value !== NACStates.init) {
      send(NACEvents.INIT, {data: {route: location.pathname}})
    }
  }, [])

  return (
    <div className="flex justify-center">
      <div className="max-w-3xl">
        <p
          className="my-3 px-14 text-center text-base text-brand-accent-gray-dark "
          dangerouslySetInnerHTML={{__html: LANDING.description}}
        />

        <WrapperContainer>
          <h2 className="my-2 text-[18px] font-bold">{LANDING.callout}</h2>
          <div className="my-2 mr-2 md:flex md:gap-8">
            <div className="flex flex-col md:basis-1/2">
              {LANDING.itemsList.map((item, index) => {
                if (index % 2 === 0)
                  return (
                    <div className="my-3">
                      <CheckMarckIconWithLabel
                        title={item.title}
                        description={item.description}
                      />
                    </div>
                  )
              })}
            </div>
            <div className="flex flex-col md:basis-1/2">
              {LANDING.itemsList.map((item, index) => {
                if (index % 2 === 1)
                  return (
                    <div className="my-3">
                      <CheckMarckIconWithLabel
                        title={item.title}
                        description={item.description}
                      />
                    </div>
                  )
              })}
            </div>
          </div>
          <div className="flex flex-col items-center">
            <p
              className="mt-3 mb-5 text-sm text-brand-accent-gray-light md:text-center"
              dangerouslySetInnerHTML={{__html: LANDING.caution}}
            />
            <Button
              variant="primary"
              mode="filled"
              onClick={() => {
                send(NACEvents.NEXT)
                navigate(RoutePaths.SELECT_LOCATION)
              }}
            >
              {LANDING.cta}
            </Button>
          </div>
        </WrapperContainer>
        <div className="text-white">
          <p
            className="mt-6 rounded bg-brand-primary px-4 pt-4 pb-5 text-sm leading-5 text-white"
            dangerouslySetInnerHTML={{__html: LANDING.tip}}
          />
        </div>
      </div>
    </div>
  )
}

export default LandingPage
