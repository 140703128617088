import {QuestionOptionsInterface, AccountType, RoutePaths} from "./index"

export const UPDATE_ACCOUNTS_VET = [
  AccountType.VETERINARY,
  AccountType.MOBILE_VETERINARY,
  AccountType.NON_PROFIT
]
export const UPDATE_ACCOUNTS_GOV = [
  AccountType.MILITARY,
  AccountType.LAW_ENFORCEMENT
]
export const UPDATE_ACCOUNTS_PET = [AccountType.PET_STORE]
export const UPDATE_ACCOUNTS_UNIVERSITY = [AccountType.VET_TECH]

export enum UpdateAccountOptions {
  BUSINESS_INFORMATION = "businessInformation",
  ORDERING_INFORMATION = "orderingInformation",
  STORE_SHIPPING_LOCATION = "storeShippingLocation",
  STORE_SHIPPING_LOCATION_CONTACTS = "storeShippingLocationContacts",
  CLINIC_SHIPPING_LOCATION = "clinicShippingLocation",
  CLINIC_SHIPPING_LOCATION_CONTACTS = "clinicShippingLocationContacts",
  PAYMENT_PREFERENCE = "paymentPreference",
  UNIVERSITY_INFORMATION = "universityInformation",
  UNIVERSITY_SHIPPING_LOCATION = "universityShippingLocation",
  UNIVERSITY_SHIPPING_LOCATION_CONTACTS = "universityShippingLocationContacts",
}

export type ROUTES = {
  [key in UpdateAccountOptions]?: string
}

export const ROUTES: ROUTES = {
  [UpdateAccountOptions.BUSINESS_INFORMATION]: RoutePaths.BUISNESS_INFORMATION,
  [UpdateAccountOptions.ORDERING_INFORMATION]: RoutePaths.FINANCIAL_INFO,
  [UpdateAccountOptions.STORE_SHIPPING_LOCATION]: RoutePaths.SHIP_TO_ACCOUNT,
  [UpdateAccountOptions.STORE_SHIPPING_LOCATION_CONTACTS]:
    RoutePaths.SHIP_TO_ACCOUNT,
  [UpdateAccountOptions.CLINIC_SHIPPING_LOCATION]: RoutePaths.SHIP_TO_ACCOUNT,
  [UpdateAccountOptions.CLINIC_SHIPPING_LOCATION_CONTACTS]:
    RoutePaths.SHIP_TO_ACCOUNT,
  [UpdateAccountOptions.PAYMENT_PREFERENCE]: RoutePaths.PAYMENT_INFORMATION,
  [UpdateAccountOptions.UNIVERSITY_INFORMATION]:
    RoutePaths.BUISNESS_INFORMATION,
  [UpdateAccountOptions.UNIVERSITY_SHIPPING_LOCATION]:
    RoutePaths.SHIP_TO_ACCOUNT,
  [UpdateAccountOptions.UNIVERSITY_SHIPPING_LOCATION_CONTACTS]:
    RoutePaths.SHIP_TO_ACCOUNT,
}

export const UPDATE_ACCOUNT_QUESTION = "What would you like to update?"

export const UPDATE_OPTIONS_VET: QuestionOptionsInterface[] = [
  {
    label: "Business Information",
    helperText: "(Business Name, Phone Number, Business Email)",
    value: UpdateAccountOptions.BUSINESS_INFORMATION,
    name: "updateOption",
    id: "update-business-info",
    dataTestId: "update-business-info",
    type: "radio",
    componentType: "Radio",
  },
  {
    label: "Ordering Information",
    helperText: "(Modify Contacts, Billing Information, and Vet Information)  ",
    value: UpdateAccountOptions.ORDERING_INFORMATION,
    name: "updateOption",
    id: "update-business-contacts",
    dataTestId: "update-business-contacts",
    type: "radio",
    componentType: "Radio",
  },
  {
    label: "Clinic Shipping Location",
    helperText: "(Location Name and/or Address)",
    value: UpdateAccountOptions.CLINIC_SHIPPING_LOCATION,
    name: "updateOption",
    id: "update-clinic-location",
    dataTestId: "update-clinic-location",
    type: "radio",
    componentType: "Radio",
  },
  {
    label: "Clinic Shipping Location Contacts",
    helperText: "(Modify Contacts and Update Communication Preferences)",
    value: UpdateAccountOptions.CLINIC_SHIPPING_LOCATION_CONTACTS,
    name: "updateOption",
    id: "update-clinic-contacts",
    dataTestId: "update-clinic-contacts",
    type: "radio",
    componentType: "Radio",
  },
  {
    label: "Payment Preferences",
    value: UpdateAccountOptions.PAYMENT_PREFERENCE,
    name: "updateOption",
    id: "update-payment",
    dataTestId: "update-payment",
    type: "radio",
    componentType: "Radio",
  },
]

export const UPDATE_OPTIONS_GOV = UPDATE_OPTIONS_VET.slice(0,-1)

export const UPDATE_OPTIONS_PET: QuestionOptionsInterface[] = [
  {
    label: "Business Information",
    helperText: "(Business Name, Phone Number, Business Email)",
    value: UpdateAccountOptions.BUSINESS_INFORMATION,
    name: "updateOption",
    id: "update-business-info",
    dataTestId: "update-business-info",
    type: "radio",
    componentType: "Radio",
  },
  {
    label: "Ordering Information",
    helperText: "(Modify Contacts, Billing Information, and Vet Information)",
    value: UpdateAccountOptions.ORDERING_INFORMATION,
    name: "updateOption",
    id: "update-business-contacts",
    dataTestId: "update-business-contacts",
    type: "radio",
    componentType: "Radio",
  },
  {
    label: "Store Shipping Location",
    helperText: "(Location Name and/or Address)",
    value: UpdateAccountOptions.STORE_SHIPPING_LOCATION,
    name: "updateOption",
    id: "update-store-location",
    dataTestId: "update-store-location",
    type: "radio",
    componentType: "Radio",
  },
  {
    label: "Store Shipping Location Contacts",
    helperText: "(Modify Contacts and Update Communication Preferences)",
    value: UpdateAccountOptions.STORE_SHIPPING_LOCATION_CONTACTS,
    name: "updateOption",
    id: "update-store-contacts",
    dataTestId: "update-store-contacts",
    type: "radio",
    componentType: "Radio",
  },
  {
    label: "Payment Preferences",
    value: UpdateAccountOptions.PAYMENT_PREFERENCE,
    name: "updateOption",
    id: "update-payment",
    dataTestId: "update-payment",
    type: "radio",
    componentType: "Radio",
  },
]

export const UPDATE_OPTIONS_UNIVERSITY = [
  {
    label: "University/School Information",
    helperText: "(Name, Phone Number, Email, Vet Information)",
    value: UpdateAccountOptions.UNIVERSITY_INFORMATION,
    name: "updateOption",
    id: "update-university-info",
    dataTestId: "update-university-info",
    type: "radio",
    componentType: "Radio",
  },
  {
    label: "Shipping Location",
    helperText: "(Location Name and/or Address)",
    value: UpdateAccountOptions.UNIVERSITY_SHIPPING_LOCATION,
    name: "updateOption",
    id: "update-university-shipping",
    dataTestId: "update-university-shipping",
    type: "radio",
    componentType: "Radio",
  },
  {
    label: "Shipping Location Contacts",
    helperText: "(Modify Contacts and Update Communication Preferences)",
    value: UpdateAccountOptions.UNIVERSITY_SHIPPING_LOCATION_CONTACTS,
    name: "updateOption",
    id: "update-university-shipping-contact",
    dataTestId: "update-university-shipping-contact",
    type: "radio",
    componentType: "Radio",
  },
]
