export function formatDate(inputDate: string) {
  if (!inputDate) return
  const [year, month, day] = inputDate.split("-")
  return `${month}/${day}/${year}`
}

export function formatDateInYearFormat(inputDate: string) {
  if (!inputDate) return
  const dateArray = inputDate.split("/")
  const formattedDate = [dateArray[2], dateArray[0], dateArray[1]].join("-")
  return formattedDate
}

export function getCurrentDate(): string {
  const today = new Date()
  const yyyy = today.getFullYear()
  let mm = String(today.getMonth() + 1) // Months start at 0!
  let dd = String(today.getDate())

  if (Number(dd) < 10) {
    dd = "0" + dd
  }
  if (Number(mm) < 10) mm = "0" + mm
  return `${mm}/${dd}/${yyyy}`
}
