import {useContext} from "react"
import {nacMachineContext} from "../../../context"
import Question from "../../utils/Question"
import Option from "../../utils/Option"
import {
  SHIPPING_ADDRESS,
  SHIPPING_ZIP_CODE,
  BILLING_STATE,
} from "../../../constants/shipto/add"
import {TransactionType} from "../../../constants/select-transaction"
import {required} from "../../../utils/validators"
import {Options, QuestionsInterface, QuestionOptionsInterface} from "../../../constants/index"

const ShippingAdress = ({ values }: Record<string, unknown>) => {
  const {state} = useContext(nacMachineContext) || {}
  const UPDATE = (value : QuestionsInterface<Options>[]) => {
    const { OPTIONS } = value[0]
    for (let i = 0; i < OPTIONS.length; i++) {
      if (OPTIONS[i]?.VALIDATION) {
        const validation = OPTIONS[i]?.VALIDATION?.filter((item : unknown) => item !== required)
        OPTIONS[i].VALIDATION = validation
      }
    }
    return value
  }
  const UPDATE_BILLING_ZIP = (value : QuestionOptionsInterface) => {
    const validation = value.VALIDATION?.filter((item : unknown) => item !== required)
    value.VALIDATION = validation
    return value
  }
  const isUpdateProfile = state?.context?.selectedTransaction ==
    TransactionType.UpdateCustomerAccountProfile

  return (
    <div className="mb-10">
      <Question questions={isUpdateProfile ?
        UPDATE(SHIPPING_ADDRESS) : SHIPPING_ADDRESS} values={values} />
      <div className="-mt-9 grid gap-5  md:grid-cols-2">
        <Option option={isUpdateProfile ? 
          UPDATE_BILLING_ZIP(BILLING_STATE) : BILLING_STATE} />
        <Option option={isUpdateProfile ? 
          UPDATE_BILLING_ZIP(SHIPPING_ZIP_CODE) : SHIPPING_ZIP_CODE} />
      </div>
    </div>
  )
}

export default ShippingAdress