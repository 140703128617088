var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/icons/CalendarIcon.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var SvgCalendarIcon = (_a) => {
  var _b = _a, {
    title,
    titleId
  } = _b, props = __objRest(_b, [
    "title",
    "titleId"
  ]);
  return /* @__PURE__ */ jsxs("svg", __spreadProps(__spreadValues({
    viewBox: "0 0 20 19",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId
  }, props), {
    children: [
      title ? /* @__PURE__ */ jsx("title", {
        id: titleId,
        children: title
      }) : null,
      /* @__PURE__ */ jsx("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M7 0v1h6V0h2v1h3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h3V0h2Zm11 7H2v10h16V7ZM7 13v2H5v-2h2Zm4 0v2H9v-2h2Zm4 0v2h-2v-2h2ZM7 9v2H5V9h2Zm4 0v2H9V9h2Zm4 0v2h-2V9h2ZM5 3H2v2h16V3h-3v1h-2V3H7v1H5V3Z",
        fill: "#0054A4"
      })
    ]
  }));
};
var CalendarIcon_default = SvgCalendarIcon;

// src/icons/CheckmarkIcon.tsx
import { jsx as jsx2, jsxs as jsxs2 } from "react/jsx-runtime";
var SvgCheckmarkIcon = (_a) => {
  var _b = _a, {
    title,
    titleId
  } = _b, props = __objRest(_b, [
    "title",
    "titleId"
  ]);
  return /* @__PURE__ */ jsxs2("svg", __spreadProps(__spreadValues({
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId
  }, props), {
    children: [
      title ? /* @__PURE__ */ jsx2("title", {
        id: titleId,
        children: title
      }) : null,
      /* @__PURE__ */ jsx2("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M9 16.2 4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2Z",
        fill: "currentColor"
      }),
      /* @__PURE__ */ jsx2("mask", {
        id: "checkmark-icon_svg__a",
        style: {
          maskType: "alpha"
        },
        maskUnits: "userSpaceOnUse",
        x: 3,
        y: 5,
        width: 18,
        height: 14,
        children: /* @__PURE__ */ jsx2("path", {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M9 16.2 4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2Z",
          fill: "#fff"
        })
      })
    ]
  }));
};
var CheckmarkIcon_default = SvgCheckmarkIcon;

// src/icons/CloseIcon.tsx
import { jsx as jsx3, jsxs as jsxs3 } from "react/jsx-runtime";
var SvgCloseIcon = (_a) => {
  var _b = _a, {
    title,
    titleId
  } = _b, props = __objRest(_b, [
    "title",
    "titleId"
  ]);
  return /* @__PURE__ */ jsxs3("svg", __spreadProps(__spreadValues({
    viewBox: "0 0 12 12",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId
  }, props), {
    children: [
      title ? /* @__PURE__ */ jsx3("title", {
        id: titleId,
        children: title
      }) : null,
      /* @__PURE__ */ jsx3("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M10.243 11.657 6 7.414l-4.243 4.243-1.414-1.414L4.586 6 .343 1.757 1.757.343 6 4.586 10.243.343l1.414 1.414L7.414 6l4.243 4.243-1.414 1.414Z",
        fill: "#0054A4"
      })
    ]
  }));
};
var CloseIcon_default = SvgCloseIcon;

// src/icons/CrossIcon.tsx
import { jsx as jsx4, jsxs as jsxs4 } from "react/jsx-runtime";
var SvgCrossIcon = (_a) => {
  var _b = _a, {
    title,
    titleId
  } = _b, props = __objRest(_b, [
    "title",
    "titleId"
  ]);
  return /* @__PURE__ */ jsxs4("svg", __spreadProps(__spreadValues({
    viewBox: "0 0 20 20",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId
  }, props), {
    children: [
      title ? /* @__PURE__ */ jsx4("title", {
        id: titleId,
        children: title
      }) : null,
      /* @__PURE__ */ jsx4("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M10 0C4.47 0 0 4.47 0 10s4.47 10 10 10 10-4.47 10-10S15.53 0 10 0Zm5 13.59L13.59 15 10 11.41 6.41 15 5 13.59 8.59 10 5 6.41 6.41 5 10 8.59 13.59 5 15 6.41 11.41 10 15 13.59Z",
        fill: "#0054A4"
      })
    ]
  }));
};
var CrossIcon_default = SvgCrossIcon;

// src/icons/DownloadIcon.tsx
import { jsx as jsx5, jsxs as jsxs5 } from "react/jsx-runtime";
var SvgDownloadIcon = (_a) => {
  var _b = _a, {
    title,
    titleId
  } = _b, props = __objRest(_b, [
    "title",
    "titleId"
  ]);
  return /* @__PURE__ */ jsxs5("svg", __spreadProps(__spreadValues({
    viewBox: "0 0 20 18",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId
  }, props), {
    children: [
      title ? /* @__PURE__ */ jsx5("title", {
        id: titleId,
        children: title
      }) : null,
      /* @__PURE__ */ jsx5("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M11.13 9.455V0h-2v9.714L6.707 7.293 5.293 8.707 10 13.414l4.707-4.707-1.414-1.414-2.163 2.162ZM2 16v-4H0v6h20v-6h-2v4H2Z",
        fill: "#0054A4"
      })
    ]
  }));
};
var DownloadIcon_default = SvgDownloadIcon;

// src/icons/EditIcon.tsx
import { jsx as jsx6, jsxs as jsxs6 } from "react/jsx-runtime";
var SvgEditIcon = (_a) => {
  var _b = _a, {
    title,
    titleId
  } = _b, props = __objRest(_b, [
    "title",
    "titleId"
  ]);
  return /* @__PURE__ */ jsxs6("svg", __spreadProps(__spreadValues({
    viewBox: "0 0 20 20",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId
  }, props), {
    children: [
      title ? /* @__PURE__ */ jsx6("title", {
        id: titleId,
        children: title
      }) : null,
      /* @__PURE__ */ jsx6("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16.131 0a3.469 3.469 0 0 1 2.598 5.767l-.145.154-6.967 6.967a3.329 3.329 0 0 1-1.329.813l-.216.062-5.646 1.411 1.411-5.646a3.33 3.33 0 0 1 .721-1.38l.154-.165 6.967-6.967a3.473 3.473 0 0 1 1.985-.982l.232-.025.235-.009Zm.003 2-.119.005c-.298.023-.58.137-.811.325l-.111.1-.824.825 2.077 2.076.824-.824a1.468 1.468 0 0 0-.895-2.5L16.134 2Zm-1.203 4.745L12.855 4.67 8.633 8.89l1.558.52.519 1.557 4.221-4.222Zm-7.757 5.68.442-1.766.993.331.332.994-1.767.441Zm.29-8.426 2.001-2L3 2l-.176.005A3 3 0 0 0 .005 4.824L0 5v12l.005.176a3 3 0 0 0 2.819 2.819L3 20h12l.176-.005a3 3 0 0 0 2.819-2.819L18 17V9.534l-2 2V17l-.007.117a1 1 0 0 1-.876.876L15 18H3l-.117-.007a1 1 0 0 1-.876-.876L2 17V5l.007-.117a1 1 0 0 1 .876-.876L3 4h4.464Z",
        fill: "#0054A4"
      })
    ]
  }));
};
var EditIcon_default = SvgEditIcon;

// src/icons/ExpandedDownIcon.tsx
import { jsx as jsx7, jsxs as jsxs7 } from "react/jsx-runtime";
var SvgExpandedDownIcon = (_a) => {
  var _b = _a, {
    title,
    titleId
  } = _b, props = __objRest(_b, [
    "title",
    "titleId"
  ]);
  return /* @__PURE__ */ jsxs7("svg", __spreadProps(__spreadValues({
    viewBox: "0 0 12 8",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId
  }, props), {
    children: [
      title ? /* @__PURE__ */ jsx7("title", {
        id: titleId,
        children: title
      }) : null,
      /* @__PURE__ */ jsx7("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M10.6.3 12 1.7l-6 6-6-6L1.4.3 6 4.9 10.6.3Z",
        fill: "#0054A4"
      })
    ]
  }));
};
var ExpandedDownIcon_default = SvgExpandedDownIcon;

// src/icons/ExpandedUpIcon.tsx
import { jsx as jsx8, jsxs as jsxs8 } from "react/jsx-runtime";
var SvgExpandedUpIcon = (_a) => {
  var _b = _a, {
    title,
    titleId
  } = _b, props = __objRest(_b, [
    "title",
    "titleId"
  ]);
  return /* @__PURE__ */ jsxs8("svg", __spreadProps(__spreadValues({
    viewBox: "0 0 12 8",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId
  }, props), {
    children: [
      title ? /* @__PURE__ */ jsx8("title", {
        id: titleId,
        children: title
      }) : null,
      /* @__PURE__ */ jsx8("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M10.6 7.7 12 6.3l-6-6-6 6 1.4 1.4L6 3.1l4.6 4.6Z",
        fill: "#0054A4"
      })
    ]
  }));
};
var ExpandedUpIcon_default = SvgExpandedUpIcon;

// src/icons/IconEye.tsx
import { jsx as jsx9, jsxs as jsxs9 } from "react/jsx-runtime";
var SvgIconEye = (_a) => {
  var _b = _a, {
    title,
    titleId
  } = _b, props = __objRest(_b, [
    "title",
    "titleId"
  ]);
  return /* @__PURE__ */ jsxs9("svg", __spreadProps(__spreadValues({
    viewBox: "0 0 22 17",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId
  }, props), {
    children: [
      title ? /* @__PURE__ */ jsx9("title", {
        id: titleId,
        children: title
      }) : null,
      /* @__PURE__ */ jsx9("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M2.186 8.86a16.33 16.33 0 0 0 2.128 2.855c1.567 1.671 3.747 3.21 6.438 3.21 2.69 0 4.87-1.539 6.437-3.21a16.338 16.338 0 0 0 2.128-2.855 16.338 16.338 0 0 0-2.128-2.855c-1.567-1.672-3.747-3.211-6.438-3.211-2.69 0-4.87 1.54-6.437 3.21A16.33 16.33 0 0 0 2.186 8.86Zm18.236 0 .864-.433-.001-.003-.003-.006-.01-.02a7.177 7.177 0 0 0-.17-.314A18.258 18.258 0 0 0 18.6 4.682C16.87 2.837 14.215.86 10.752.86c-3.464 0-6.12 1.977-7.849 3.822A18.263 18.263 0 0 0 .401 8.084a10.953 10.953 0 0 0-.17.314l-.01.02-.003.006-.001.002.864.434-.865-.433L0 8.86l.216.432.865-.432-.865.432.001.002.001.002.003.006.01.02a6.643 6.643 0 0 0 .17.314 18.26 18.26 0 0 0 2.502 3.402c1.73 1.845 4.385 3.822 7.849 3.822 3.463 0 6.118-1.977 7.848-3.822a18.255 18.255 0 0 0 2.502-3.402 11.154 11.154 0 0 0 .17-.314l.01-.02.003-.006.001-.002s0-.002-.864-.434Zm0 0 .865.432.216-.432-.217-.433-.864.433Z",
        fill: "#0054A4"
      }),
      /* @__PURE__ */ jsx9("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M10.752 7.19a1.67 1.67 0 1 0 0 3.34 1.67 1.67 0 0 0 0-3.34ZM7.146 8.86a3.604 3.604 0 1 1 7.209 0 3.604 3.604 0 0 1-7.209 0Z",
        fill: "#0054A4"
      })
    ]
  }));
};
var IconEye_default = SvgIconEye;

// src/icons/IconInformationCircleFilled.tsx
import { jsx as jsx10, jsxs as jsxs10 } from "react/jsx-runtime";
var SvgIconInformationCircleFilled = (_a) => {
  var _b = _a, {
    title,
    titleId
  } = _b, props = __objRest(_b, [
    "title",
    "titleId"
  ]);
  return /* @__PURE__ */ jsxs10("svg", __spreadProps(__spreadValues({
    viewBox: "0 0 20 20",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId
  }, props), {
    children: [
      title ? /* @__PURE__ */ jsx10("title", {
        id: titleId,
        children: title
      }) : null,
      /* @__PURE__ */ jsx10("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10Zm0-12.488c.431 0 .78-.122 1.045-.366.265-.245.398-.554.398-.927 0-.357-.133-.649-.398-.877C10.779 5.114 10.43 5 10 5c-.431 0-.78.12-1.045.36a1.16 1.16 0 0 0-.398.896c0 .357.133.655.398.896.266.24.614.36 1.045.36ZM11.182 15V8.259H8.818V15h2.364Z",
        fill: "#0054A4"
      })
    ]
  }));
};
var IconInformationCircleFilled_default = SvgIconInformationCircleFilled;

// src/icons/IconPdf.tsx
import { jsx as jsx11, jsxs as jsxs11 } from "react/jsx-runtime";
var SvgIconPdf = (_a) => {
  var _b = _a, {
    title,
    titleId
  } = _b, props = __objRest(_b, [
    "title",
    "titleId"
  ]);
  return /* @__PURE__ */ jsxs11("svg", __spreadProps(__spreadValues({
    viewBox: "0 0 19 22",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId
  }, props), {
    children: [
      title ? /* @__PURE__ */ jsx11("title", {
        id: titleId,
        children: title
      }) : null,
      /* @__PURE__ */ jsx11("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16.5 19a1 1 0 0 1-1 1H3.49a.995.995 0 0 1-.99-.999V17h-2v2c0 1.654 1.342 3 2.99 3H15.5c1.654 0 3-1.346 3-3V5.023l-.007-.118a1.002 1.002 0 0 0-.314-.616L13.816.265 13.72.186A.997.997 0 0 0 13.14 0H3.5l-.176.005A3 3 0 0 0 .5 3v6h2V3l.007-.117A1 1 0 0 1 3.5 2l7.5-.001v5.373l5.5-.001V19Zm-.098-13.629-3.4.001L13 2.233l3.402 3.138ZM1.608 13.902V15H.42v-4.2h1.92c.38 0 .71.063.99.189s.496.306.648.54c.152.234.228.509.228.825 0 .316-.076.59-.228.822a1.475 1.475 0 0 1-.648.537c-.28.126-.61.189-.99.189h-.732Zm0-.936h.66c.244 0 .428-.053.552-.159.124-.106.186-.257.186-.453s-.062-.348-.186-.456c-.124-.108-.308-.162-.552-.162h-.66v1.23ZM6.828 15c.46 0 .868-.086 1.224-.258.356-.172.632-.416.828-.732.196-.316.294-.686.294-1.11 0-.424-.098-.794-.294-1.11a1.943 1.943 0 0 0-.828-.732 2.78 2.78 0 0 0-1.224-.258H4.842V15h1.986Zm-.798-.948h.75c.36 0 .649-.102.867-.306.218-.204.327-.486.327-.846s-.11-.642-.327-.846c-.218-.204-.507-.306-.867-.306h-.75v2.304Zm4.956.948v-1.44h1.854v-.918h-1.854v-.924h2.106V10.8H9.798V15h1.188Z",
        fill: "#0054A4"
      })
    ]
  }));
};
var IconPdf_default = SvgIconPdf;

// src/icons/IconPersonOutline.tsx
import { jsx as jsx12, jsxs as jsxs12 } from "react/jsx-runtime";
var SvgIconPersonOutline = (_a) => {
  var _b = _a, {
    title,
    titleId
  } = _b, props = __objRest(_b, [
    "title",
    "titleId"
  ]);
  return /* @__PURE__ */ jsxs12("svg", __spreadProps(__spreadValues({
    viewBox: "0 0 32 32",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId
  }, props), {
    children: [
      title ? /* @__PURE__ */ jsx12("title", {
        id: titleId,
        children: title
      }) : null,
      /* @__PURE__ */ jsx12("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M16 3.8a4.2 4.2 0 1 1 .001 8.399A4.2 4.2 0 0 1 16 3.8Zm0 18c5.94 0 12.2 2.92 12.2 4.2v2.2H3.8V26c0-1.28 6.26-4.2 12.2-4.2ZM16 0c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8Zm0 18c-5.34 0-16 2.68-16 8v6h32v-6c0-5.32-10.66-8-16-8Z",
        fill: "#0054A4"
      })
    ]
  }));
};
var IconPersonOutline_default = SvgIconPersonOutline;

// src/icons/IconTrash.tsx
import { jsx as jsx13, jsxs as jsxs13 } from "react/jsx-runtime";
var SvgIconTrash = (_a) => {
  var _b = _a, {
    title,
    titleId
  } = _b, props = __objRest(_b, [
    "title",
    "titleId"
  ]);
  return /* @__PURE__ */ jsxs13("svg", __spreadProps(__spreadValues({
    viewBox: "0 0 18 21",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId
  }, props), {
    children: [
      title ? /* @__PURE__ */ jsx13("title", {
        id: titleId,
        children: title
      }) : null,
      /* @__PURE__ */ jsx13("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M12.992.748a.7.7 0 0 0-.684-.609L5.692.145 5.59.153A.708.708 0 0 0 5 .859v2.143H1C0 3 0 4 0 4s0 1 1 1h1.077v12.282l.005.172c.096 1.534 1.457 2.685 3.024 2.685h7.788l.174-.004c1.55-.085 2.855-1.292 2.855-2.853V5L17 4.994c1 .006 1-1.997 0-1.997h-4V.854l-.008-.106ZM14 5H4v11.7c0 .647.544 1.228 1.333 1.294l.151.006h7.032c.819 0 1.41-.542 1.478-1.18L14 16.7V5Zm-2.009 2.635c-.145-.887-2.01-.844-1.99.126v7.478l.007.104c.062.374.49.662 1.002.657.557-.008.998-.347.99-.76V7.76l-.009-.126Zm-5.99.126c-.02-.97 1.845-1.013 1.99-.126L8 7.76v7.478c.007.414-.434.753-.991.761-.511.005-.94-.283-1.001-.657L6 15.24V7.761ZM7 2.001h4v1H7V2Z",
        fill: "#0054A4"
      })
    ]
  }));
};
var IconTrash_default = SvgIconTrash;

// src/icons/IconUpload.tsx
import { jsx as jsx14, jsxs as jsxs14 } from "react/jsx-runtime";
var SvgIconUpload = (_a) => {
  var _b = _a, {
    title,
    titleId
  } = _b, props = __objRest(_b, [
    "title",
    "titleId"
  ]);
  return /* @__PURE__ */ jsxs14("svg", __spreadProps(__spreadValues({
    viewBox: "0 0 40 36",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId
  }, props), {
    children: [
      title ? /* @__PURE__ */ jsx14("title", {
        id: titleId,
        children: title
      }) : null,
      /* @__PURE__ */ jsx14("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M22.26 7.918v18.91h-4V7.4l-4.846 4.843-2.828-2.829L20 0l9.414 9.414-2.828 2.828-4.326-4.324ZM4 32v-8H0v12h40V24h-4v8H4Z",
        fill: "#0054A4"
      })
    ]
  }));
};
var IconUpload_default = SvgIconUpload;

// src/icons/IconWarning.tsx
import { jsx as jsx15, jsxs as jsxs15 } from "react/jsx-runtime";
var SvgIconWarning = (_a) => {
  var _b = _a, {
    title,
    titleId
  } = _b, props = __objRest(_b, [
    "title",
    "titleId"
  ]);
  return /* @__PURE__ */ jsxs15("svg", __spreadProps(__spreadValues({
    viewBox: "0 0 66 57",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId
  }, props), {
    children: [
      title ? /* @__PURE__ */ jsx15("title", {
        id: titleId,
        children: title
      }) : null,
      /* @__PURE__ */ jsx15("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M64.266 57a1 1 0 0 0 .865-1.501L33.865 1.495a1 1 0 0 0-1.73 0L.869 55.499A1 1 0 0 0 1.734 57h62.532ZM33.02 51.127c1.372 0 2.506-.44 3.402-1.32.896-.88 1.344-1.954 1.344-3.223 0-1.298-.441-2.365-1.323-3.202-.882-.836-2.023-1.255-3.423-1.255s-2.548.419-3.444 1.255c-.896.837-1.344 1.904-1.344 3.202 0 1.27.448 2.344 1.344 3.224.896.88 2.044 1.32 3.444 1.32Zm4.746-29.969-1.596 17.996h-6.342l-1.554-17.996h9.492Z",
        fill: "#C4132A"
      })
    ]
  }));
};
var IconWarning_default = SvgIconWarning;

// src/icons/PlusIcon.tsx
import { jsx as jsx16, jsxs as jsxs16 } from "react/jsx-runtime";
var SvgPlusIcon = (_a) => {
  var _b = _a, {
    title,
    titleId
  } = _b, props = __objRest(_b, [
    "title",
    "titleId"
  ]);
  return /* @__PURE__ */ jsxs16("svg", __spreadProps(__spreadValues({
    viewBox: "0 0 14 14",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId
  }, props), {
    children: [
      title ? /* @__PURE__ */ jsx16("title", {
        id: titleId,
        children: title
      }) : null,
      /* @__PURE__ */ jsx16("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M14 8H8v6H6V8H0V6h6V0h2v6h6v2Z",
        fill: "#0054A4"
      })
    ]
  }));
};
var PlusIcon_default = SvgPlusIcon;

// src/icons/TrashIcon.tsx
import { jsx as jsx17, jsxs as jsxs17 } from "react/jsx-runtime";
var SvgTrashIcon = (_a) => {
  var _b = _a, {
    title,
    titleId
  } = _b, props = __objRest(_b, [
    "title",
    "titleId"
  ]);
  return /* @__PURE__ */ jsxs17("svg", __spreadProps(__spreadValues({
    viewBox: "0 0 18 21",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    "aria-labelledby": titleId
  }, props), {
    children: [
      title ? /* @__PURE__ */ jsx17("title", {
        id: titleId,
        children: title
      }) : null,
      /* @__PURE__ */ jsx17("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M12.992.748a.7.7 0 0 0-.684-.609L5.692.145 5.59.153A.708.708 0 0 0 5 .859v2.143H1C0 3 0 4 0 4s0 1 1 1h1.077v12.282l.005.172c.096 1.534 1.457 2.685 3.024 2.685h7.788l.174-.004c1.55-.085 2.855-1.292 2.855-2.853V5L17 4.994c1 .006 1-1.997 0-1.997h-4V.854l-.008-.106ZM14 5H4v11.7c0 .647.544 1.228 1.333 1.294l.151.006h7.032c.819 0 1.41-.542 1.478-1.18L14 16.7V5Zm-2.009 2.635c-.145-.887-2.01-.844-1.99.126v7.478l.007.104c.062.374.49.662 1.002.657.557-.008.998-.347.99-.76V7.76l-.009-.126Zm-5.99.126c-.02-.97 1.845-1.013 1.99-.126L8 7.76v7.478c.007.414-.434.753-.991.761-.511.005-.94-.283-1.001-.657L6 15.24V7.761ZM7 2.001h4v1H7V2Z",
        fill: "#0054A4"
      })
    ]
  }));
};
var TrashIcon_default = SvgTrashIcon;

export {
  __spreadValues,
  __spreadProps,
  __objRest,
  CheckmarkIcon_default,
  CalendarIcon_default,
  CloseIcon_default,
  CrossIcon_default,
  DownloadIcon_default,
  EditIcon_default,
  ExpandedDownIcon_default,
  ExpandedUpIcon_default,
  IconEye_default,
  IconInformationCircleFilled_default,
  IconPdf_default,
  IconPersonOutline_default,
  IconTrash_default,
  IconUpload_default,
  IconWarning_default,
  PlusIcon_default,
  TrashIcon_default
};
