import {EventObject} from "xstate"
import {AccountType, Options} from "../constants"
import {LOCATION} from "../constants/select-location"
import {PetIntend, PetAllowed, HomeImprovementStore} from "./../constants/accounts/veterinary"
import {NonProfitEnquiry} from "./../constants/accounts/non-profit"
import {ValidateMilitary} from "./../constants/accounts/military"
import {ValidateLaw} from "./../constants/accounts/law-enforcement"
import {ValidateVetTech} from "./../constants/accounts/vet-tech"
import {ValidateSpeciality} from "./../constants/validate-specialty"
import {TransactionType} from "../constants/select-transaction"
import {ShipToQualifier} from "../constants/shipto/qualifier"
import {
  BusinessInformantion,
  FinacialInformation,
  PaymentInformation,
  AddShipTo,
  AdditionalShipTo,
  DateSign,
  Review,
  CustomerNotes,
} from "../types"
import {ShipToLocation} from "../constants/shipto/how-many-ship-to"
import {UpdateAccountOptions} from "../constants/update-account-options"
export interface NACMachineContext {
  location: LOCATION | undefined
  accountType: AccountType
  validatePet: PetIntend | undefined
  validatePetAllow: PetAllowed | undefined
  validateHomeImprovementStore: HomeImprovementStore | undefined
  validateNonProfit: NonProfitEnquiry | undefined
  validateMilitary: ValidateMilitary | undefined
  validateLaw: ValidateLaw | undefined
  validateUniversity: ValidateVetTech | undefined
  validateSpeciality: ValidateSpeciality | undefined
  selectedTransaction: TransactionType
  shipToQualifier: ShipToQualifier | undefined
  addShipTo: AddShipTo[]
  additionalShipTo: AdditionalShipTo | undefined
  businessInformation: BusinessInformantion | undefined
  financialInformation: FinacialInformation | undefined
  paymentInformation: PaymentInformation | undefined
  contact: Record<string, never> | undefined
  howManyShipTo: ShipToLocation | undefined
  dateSign: DateSign | undefined
  updateAccount: UpdateAccountOptions
  review: Review
  additionalShiptoTransfer: Options
  custNotes: CustomerNotes | undefined
}

export enum NACStates {
  init = "init",
  selectLocation = "selectLocation",
  selectAccountType = "selectAccountType",
  selectTranscationType = "selectTranscationType",
  enterBuisnessInformation = "enterBuisnessInformation",
  validatePetFoodRetailer = "validatePetFoodRetailer",
  validatePetAllow = "validatePetAllow",
  validateHomeImprovementStore = "validateHomeImprovementStore",
  validateNonProfitSpeciality = "validateNonProfitSpeciality",
  validateMilitaryAccount = "validateMilitaryAccount",
  validateLawEnforcement = "validateLawEnforcement",
  validateUniversity = "validateUniversity",
  validateSpeciality = "validateSpeciality",
  financeInfo = "financeInfo",
  shipToQualifier = "shipToQualifier",
  paymentInformation = "paymentInformation",
  addShipTo = "addShipTo",
  additionalShipTo = "additionalShipTo",
  contact = "contact",
  howManyShipTo = "howManyShipTo",
  shipToNumber = "shipToNumber",
  dateSign = "dateSign",
  review = "review",
  transferShipToLocation = "transferShipToLocation",
  updateAccount = "updateAccount",
  pdfSign = "pdfSign",
  thankYou = "thankYou",
}

export type NACMachineStates =
  | {
      value: NACStates.init
      context: NACMachineContext
    }
  | {
      value: NACStates.selectLocation
      context: NACMachineContext
    }
  | {
      value: NACStates.selectAccountType
      context: NACMachineContext
    }
  | {
      value: NACStates.selectTranscationType
      context: NACMachineContext
    }
  | {
      value: NACStates.enterBuisnessInformation
      context: NACMachineContext
    }
  | {
      value: NACStates.validatePetFoodRetailer
      context: NACMachineContext
    }
  | {
      value: NACStates.validatePetAllow
      context: NACMachineContext
    }
  | {
      value: NACStates.validateHomeImprovementStore
      context: NACMachineContext
    }
  | {
      value: NACStates.validateNonProfitSpeciality
      context: NACMachineContext
    }
  | {
      value: NACStates.validateMilitaryAccount
      context: NACMachineContext
    }
  | {
      value: NACStates.validateUniversity
      context: NACMachineContext
    }
  | {
      value: NACStates.validateLawEnforcement
      context: NACMachineContext
    }
  | {
      value: NACStates.validateSpeciality
      context: NACMachineContext
    }
  | {
      value: NACStates.shipToQualifier
      context: NACMachineContext
    }
  | {
      value: NACStates.paymentInformation
      context: NACMachineContext
    }
  | {
      value: NACStates.addShipTo
      context: NACMachineContext
    }
  | {
      value: NACStates.additionalShipTo
      context: NACMachineContext
    }
  | {
      value: NACStates.contact
      context: NACMachineContext
    }
  | {
      value: NACStates.howManyShipTo
      context: NACMachineContext
    }
  | {
      value: NACStates.dateSign
      context: NACMachineContext
    }
  | {
      value: NACStates.review
      context: NACMachineContext
    }
  | {
      value: NACStates.shipToNumber
      context: NACMachineContext
    }
  | {
      value: NACStates.transferShipToLocation
      context: NACMachineContext
    }
  | {
      value: NACStates.updateAccount
      context: NACMachineContext
    }
  | {
      value: NACStates.pdfSign
      context: NACMachineContext
    }
  | {
      value: NACStates.thankYou
      context: NACMachineContext
    }

export enum NACEvents {
  INIT = "INIT",
  BACK = "BACK",
  NEXT = "NEXT",
  EDIT = "EDIT",
  CLICK = "CLICK"
}

export type NACEventsSchema =
  | NACEvents.INIT
  | NACEvents.BACK
  | NACEvents.NEXT
  | NACEvents.EDIT
  | NACEvents.CLICK

export interface NACMachineEvents extends EventObject {
  type: NACEventsSchema
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
}
